import React from 'react';
import { Tabs, Tab } from "react-bootstrap";
import PurchaseMainBlock from '../components/PurchaseMainBlock';
import PurchaseProvider from '../providers/PurchaseProvider';
import AlreadyPurchasedTabContent from '../components/AlreadyPurchasedTabContent';
import BuyOptions from './BuyOptions.js';

const Purchase = (props) => (
  <>
    <PurchaseMainBlock>
      <PurchaseProvider>
        <Tabs defaultActiveKey="shop">
          <Tab eventKey="shop" id="shop-tab" title="Shop Now">
            <BuyOptions props={props}/>
          </Tab>
          <Tab eventKey="shopped" id="shopped-tab" title="Already Bought">
            <AlreadyPurchasedTabContent />
          </Tab>
        </Tabs>
      </PurchaseProvider>
    </PurchaseMainBlock>
  </>
);

export default Purchase;
