/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const CareerSimpleModal = ({ isOpen, setClose, data, handleConfirm }) => {
  const {
    title,
    dispTxt: content,
    confImg: image,
    buttonTxt,
    items: [itemToDisplay],
  } = data;
  const [confirmBtn, cancelBtn] = buttonTxt.split("|");

  return (
    <div
      className={`modal fade career-simple-tab-content ${isOpen ? "show" : ""}`}
      id="swithjob-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none", textAlign: "left" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content light-purple">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={setClose}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            <h5 className="head-title-name">{title}</h5>
            <div className="redeem-wrapper">
              <div className="left-side-image float-left">
                <img src={`/images/${image}`} alt={image} />
              </div>
              <div className="right-side-redeem m-right-side-content float-right">
                <div className="redeem-form">
                  <form>
                    <p dangerouslySetInnerHTML={{ __html: content }} />
                    <div className="button-footer">
                      <button
                        type="button"
                        className="btn btn-primary mobile-wide"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#career-confirm"
                        onClick={() => handleConfirm(itemToDisplay)}
                      >
                        {confirmBtn}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={setClose}
                      >
                        {cancelBtn}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerSimpleModal;
