import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import InsuranceList from "./InsuranceList";
import InsuranceBuy from "./InsuranceBuy";

const InsuranceContent = () => (
  <>
    <Tabs defaultActiveKey="personal-career">
      <Tab eventKey="personal-career" id="personal-career" title="Current">
        <InsuranceList />
      </Tab>
      <Tab
        eventKey="personal-family"
        id="personal-family"
        title="Buy Insurance Policy"
      >
        <InsuranceBuy />
      </Tab>
    </Tabs>
  </>
);

export default InsuranceContent;
