/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Redirect, Link, useHistory } from 'react-router-dom';
import { isMobile } from "../helpers/common";
import { fetchSessionData, logout } from '../helpers/api';

const GameLost = () => {

  const history = useHistory();

  // If user is not logged in, send the user to "/start"
  const renderRedirect = () => {
    const { isLoggedIn, endStatus } = fetchSessionData()
    if (!isLoggedIn) return <Redirect to='/start' />
    else if (!endStatus) history.goBack();
    else logout();
  }

  return (
    <div className="game-wl height-fix game-lost">
      {renderRedirect()}
      <div className="container">
        <div className="row ">
          <div className="col-lg-12 game-wrapper">
            <div className="game-content">
              <div className="row align-middle">
                <div className="col-lg-3 idfc-logo-welcome">
                  <img src="images/idfc-logo-welcome.png" />
                </div>
              </div>
              <div className="row align-middle">
                <div className="col-lg-7 game-img-right img-width">
                  <img
                    style={{ maxWidth: isMobile() ? "100vw" : "25vw" }}
                    src="/images/game-lost.png"
                  />
                </div>
                <div className="col-lg-5 right-content">
                  <h2 className="heading-big">OOPS!</h2>
                  <p>
                    Ouch! You didn't manage your investments & expenses and
                    ran out of money!.
                    </p>
                   <p> 
                    Only the smartest 10% people manage to complete this game. Think you can do better next time?                    
                  </p>
                  <div className="align-middle play-again-btn for-lost">
                      <Link
                        to="/profile"
                        style={{ textDecoration: "none", color: "white" }}
                      className="btn btn-playagain"
                      >
                        Play again
                      </Link>
                  </div>
                  <p className="text-bottom">
                    To win the game in real life, start investing and saving!
                    Click
                    <a
                      href="https://www.mutualfundssahihai.com/" target="_blank" rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {" "}
                      <u>here</u>{" "}
                    </a>
                    to know more about Mutual Funds.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameLost;
