import React, { useContext, useState } from "react";
import LeftNavMenu from "./LeftNavMenu";
import { HomeContext } from "../providers/HomeProvider";
import MobileBottom from "./MobileBottom";
import MascotTipModal from "./modals/MascotTipModal";
import { MASCOT_TIP_API } from "../constants";
import { postData, getData } from "../helpers/api";

const LeftNavBlock = () => {

  //const { data, tipData } = useContext(HomeContext);
  const [activeModal, setActiveModal] = useState("none");
  const [modalData, setModalData] = useState(null);
  //const [mascotTip, setMascotTip] = useState(null);

  //suseEffect(() => {
   // setMascotTip(tipData);
  //}, [tipData]);

  const onMascotClk = () => {
	getData(MASCOT_TIP_API, "data.data").then((op) => {            
           setModalData(op[0]);
           setActiveModal("mascot");
            //console.log(op);
        
        });

 
  };

  // const handleMascotCloseClk = () => {
  //   if (mascotTip.length == 1) {
  //     setMascotTip([
  //       {
  //         title: "No Tip Available",
  //         tips: "You do not have any Tips",
  //       },
  //     ]);
  //   } else {
  //     mascotTip.shift();
  //   }
  // };

  return (
  <>
  <div className="col-lg-4 left-nav-block float-left d-flex">
    <div className="left-bar-block">
      <LeftNavMenu></LeftNavMenu>
      <MobileBottom clickHandlr={() => onMascotClk()} />
      <MascotTipModal
        isOpen={activeModal === "mascot"}
        closeMascotModal={setActiveModal}
        data={modalData}
        // handleMascotClose={handleMascotCloseClk}
      />
    </div>
  </div>
  </>
);
};

export default LeftNavBlock;
