import React from 'react';

const RedeemBtn = ({ amount, displayName, onClick }) => (
  <div
    className='inv-redeem'
    onClick={onClick}
    style={{ visibility: amount < 1 || displayName=== 'FD' ? 'hidden' : 'visible' }}
  >
    <span className='redeem-i'></span>
    <button
      type='button'
      className='redeem-btn'
      data-toggle='modal'
      data-target='#redeem-modal'
    >
      Redeem
    </button>
  </div>
);

export default RedeemBtn;
