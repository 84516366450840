import React from "react";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { HomeContext } from "../providers/HomeProvider";

const LeftNavMenu = () => {
  const { eventData: data, setEventData: setData } = useContext(HomeContext);

  return (
  <div className="left-nav-menu">
    <ul className="style-none">
      <li>
        <NavLink to="/" exact activeClassName="current">
          <span className="menu-icon life-i" />
          Life Events
          <span class="count-icon">{data && data.length>0?data.length:<img src="/images/true-icon.png" alt="" style={{width:"18px", marginTop:"-3px"}}/>}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/invest" activeClassName="current">
          <span className="menu-icon invest-i" />
          Investments
        </NavLink>
      </li>
      <li>
        <NavLink to="/loans" activeClassName="current">
          <span className="menu-icon loans-i" />
          Loans
        </NavLink>
      </li>     
      <li>
        <NavLink to="/insurance" activeClassName="current">
          <span className="menu-icon insurance-i" />
          Insurance
        </NavLink>
      </li>
       <li>
        <NavLink to="/shop" activeClassName="current">
          <span className="menu-icon purchase-i" />
          Shop
        </NavLink>
      </li>
       <li>
        <NavLink to="/personal" activeClassName="current">
          <span className="menu-icon personal-i" />
          Personal
        </NavLink>
      </li>
    </ul>
  </div>
)}

export default LeftNavMenu;
