import React, { useContext } from "react";
import BuyBlock from "./BuyBlock";
import { PurchaseContext } from "../providers/PurchaseProvider";

const PurchaseTabContent = () => {
  const { data, loading } = useContext(PurchaseContext);

  return (
      <div id="purchase" className="tab-pane fade in active show">
        <div className="purchase-buy-block" style={{ textAlign: "center" }}>
          {data &&
            data.map(
              (each, index) =>
                each.cat && (
                  <BuyBlock
                    key={`buyblock-${index}`}
                    item={each.cat}
                    goto={`/shop/${each.cat.toLowerCase()}`}
                  />
                )
            )}
          {loading && <img src={`/images/loader.svg`} alt="Loading..." />}
        </div>
      </div>
  );
};

export default PurchaseTabContent;
