/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { fetchSessionData } from '../helpers/api';
import Slider from "react-slick";

const settings = {
  dots: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};


const GameTour = () => {
  const [current, setCurrent] = useState(0);

  // If not logged in, send the user to "/profile"
  const renderRedirect = () => {
    if (!fetchSessionData().isLoggedIn) {
      return <Redirect to='/start' />;
    }
  };

  const handleNextClick = () => {
    setCurrent(current + 1);
  };

  return (
    <>
      {renderRedirect()}
      <div
        className='slide-inner custome-slide-inner-1'
        style={{
          display: current === 0 ? 'block' : 'none',
        }}
      >
        <div className='overlay-black'></div>
        <div className='man-bkg'>
          <img src='images/man-new.png' />
        </div>
        <div className='contextual-box pos1'>
          <h3 className='ct-heading'>Alright then! Let’s get to the game.</h3>
          <div className='d-flex ct-content-wrapper'>
            <div className='ct-left-img'>
              <img src='images/ten-stage.png' className='ten-stage' />
              <img src='images/image-man-old.png' className='dyn-man-old' />
            </div>
            <div className='content-context ct-right'>
              <h3>BASIC FUNDA:</h3>
              <p>
                There are 10 stages of life that you have to cross. In each
                stage, you will earn an income with which you have to manage expenses
                and most importantly your investments.
              </p>
              <p>
                You win if you cross all 10 stages with enough money to manage
                expenses after retirement.{' '}
              </p>
            </div>
          </div>
          <div className='button-footer d-flex ct-flex-btn'>
            <a href='/' className='btn skip-b'>
              Skip
            </a>
            <a className='btn btn-pink next-b' onClick={handleNextClick}>
              Next
            </a>
          </div>
        </div>
      </div>

      <div
        className='slide-inner custome-slide-inner-1'
        style={{
          display: current === 1 ? 'block' : 'none',
        }}
      >
        <div className='overlay-black'></div>

        <div className='contextual-box pos2'>
          <img src='images/face-tag.png' className='pos2-face' />
          <div className='d-flex ct-content-wrapper'>
            <div className='content-context'>
              <h3>LIfe events</h3>
              <p>
                In each stage, you come across life events or tasks. Some you
                can skip, some you can’t.{' '}
              </p>

              <p>
                You can move to the next stage only when ALL tasks are complete
              </p>
            </div>
          </div>
          <div className='button-footer d-flex ct-flex-btn'>
            <a className='btn btn-pink next-b' onClick={handleNextClick}>
              Next
            </a>
            <a href='/' className='btn skip-b'>
              Skip
            </a>
          </div>
        </div>
      </div>

      <div
        className='slide-inner custome-slide-inner-1'
        style={{
          display: current === 2 ? 'block' : 'none',
        }}
      >
        <div className='overlay-black'></div>
        <div className='contextual-box pos3'>
          <img src='images/face-tag3.png' className='pos3-face' />
          <div className='d-flex ct-content-wrapper'>
            <div className='content-context'>
              <h3>LIfe events</h3>
              <p>
                All the tasks for the stage show up in the life events tabs. You
                can see how many tasks are pending in the notification label.
              </p>
            </div>
          </div>
          <div className='button-footer d-flex ct-flex-btn'>
            <a className='btn btn-pink next-b' onClick={handleNextClick}>
              Next
            </a>
            <a href='/' className='btn skip-b'>
              Skip
            </a>
          </div>
        </div>
      </div>

      <div
        className='slide-inner custome-slide-inner-1'
        style={{
          display: current === 3 ? 'block' : 'none',
        }}
      >
        <div className='overlay-black'></div>

        <div className='contextual-box pos4'>
          <img src='images/face-tag3.png' className='pos4-face' />
          <div className='d-flex ct-content-wrapper'>
            <div className='content-context'>
              <h3>Action tabs</h3>
              <p>
                Some life events require you to invest in some fund or take a
                loan. You many even decide to purchase something for your family
                or take a holiday. These tabs are where you can take all these
                actions.
              </p>        

              <p>
                <b>Invest Tab :</b> Explore and invest in different investment
                options
              </p>

              <p>
                <b>Loans Tab:</b> If you need to take a loan, click this tab for
                options
              </p>
              
              <p>
                <b>Insurance Tab:</b> All options regarding insurance are here
              </p>

              <p>
                <b>Shop tab:</b> Purchase a new TV, car or house.{' '}
              </p>
              
              <p>
                <b>Personal tab:</b> Actions like taking a course, getting
                married or taking a vacation appear here.
              </p>
            </div>
          </div>
          <div className='button-footer d-flex ct-flex-btn'>
            <a className='btn btn-pink next-b' onClick={handleNextClick}>
              Next
            </a>
            <a href='/' className='btn skip-b'>
              Skip
            </a>
          </div>
        </div>
      </div>

      <div
        className='slide-inner custome-slide-inner-1'
        style={{
          display: current === 4 ? 'block' : 'none',
        }}
      >
        <div className='overlay-black'></div>

        <div className='contextual-box pos5'>
          <img src='images/face-tag5.png' className='pos5-face' />
          <div className='d-flex ct-content-wrapper'>
            <div className='content-context'>
              <h3>The Paisa wala tab</h3>
              <p>
                Get a quick over view of your financial status here. What you
                earned, where you spent, how much you invested etc.
              </p>

              <p>Click the tab to view details about your income & expenses</p>
            </div>
          </div>
          <div className='button-footer d-flex ct-flex-btn'>
            <a className='btn btn-pink next-b' onClick={handleNextClick}>
              Next
            </a>
            <a href='/' className='btn skip-b'>
              Skip
            </a>
          </div>
        </div>
      </div>

      <div
        className='slide-inner custome-slide-inner-1'
        style={{
          display: current === 5 ? 'block' : 'none',
        }}
      >
        <div className='overlay-black'></div>

        <div className='contextual-box pos6'>
          <img src='images/face-tag6.png' className='pos6-face' />
          <div className='d-flex ct-content-wrapper'>
            <div className='content-context'>
              <h3>The Stage View</h3>
              <p>
                Click here to see your age in the current stage of life, things
                you have purchased and more
              </p>
            </div>
          </div>
          <div className='button-footer d-flex ct-flex-btn'>
            <a className='btn btn-pink next-b' onClick={handleNextClick}>
              Next
            </a>
            <a href='/' className='btn skip-b'>
              Skip
            </a>
          </div>
        </div>
      </div>

      <div
        className='slide-inner custome-slide-inner-1'
        style={{
          display: current === 6 ? 'block' : 'none',
        }}
      >
        <div className='overlay-black'></div>

        <div className='contextual-box pos7'>
          <img src='images/face-tag7.png' className='pos7-face' />
          <div className='d-flex ct-content-wrapper'>
            <div className='content-context'>
              <h3>NEXT STAGE!</h3>
              <p>
                Once you have completed all your tasks,the Badge on your life
                events tab on the left turns into a tick mark.
              </p>
              <p>
                You will see that the Next Button down below gets activated.{' '}
              </p>
              <p>
                But remember, you will not be allowed to proceed until your Pay
                Later loans have been cleared.{' '}
              </p>
            </div>
          </div>
          <div className='button-footer d-flex ct-flex-btn'>
            <a className='btn btn-pink next-b' onClick={handleNextClick}>
              Next
            </a>
            <a href='/' className='btn skip-b'>
              Skip
            </a>
          </div>
        </div>
      </div>

      <div
        className='slide-inner custome-slide-inner-1'
        style={{
          display: current === 7 ? 'block' : 'none',
        }}
      >
        <div className='overlay-black'></div>

        <div className='contextual-box pos8'>
          <img src='images/face-tag8.png' className='pos8-face' />
          <div className='d-flex ct-content-wrapper'>
            <div className='content-context'>
              <h3>TALK TO ME!</h3>
              <p>
                And finally, remember I am always here to help you out. Just
                click here to hear my tips or if you want to see this tutorial
                again click on VIEW GUIDE in the top menu.
              </p>
            </div>
          </div>
          <div className='button-footer d-flex ct-flex-btn'>
            <Link to='/' className='btn btn-pink next-b'>
              START PLAYING
            </Link><a href='/game-tour' className='btn skip-b'>REPLAY</a> 
          </div>
        </div>
      </div>

      <header id='header' className="contextual-header">
        <div className='container'>
          <div className='header-corner d-flex align-items-center justify-content-between'>
            <div className='logo float-left'>
              <a href='index.html'>
                <img src='images/IDFC-logo.png' alt='' className='logo-img' />
              </a>
            </div>
            <div className='text-subtitle'>THE IDFC GAME OF LIFE</div>
            <div className='nav-right-icon float-right'>
              <div className='bar1'></div>
              <div className='bar2'></div>
              <div className='bar3'></div>
            </div>
            <div className='right-corner-bottom'></div>
          </div>
        </div>
      </header>

      <section className='section-wrapper contextual-section-wrapper'>
        <div className='container contextual-container'>
          <div className='row mobile-row'>
            <div
              className='for-mobile left-side'
              style={{ position: 'relative' }}
            >
              <div className={`${current===4?'tour5':''} ic-right-wrap`}>
                <ul className='style-none'>
                  <li className='ic-list cash-block'>
                    <div className='ic-left-icon cash-i'></div>
                    <div className='ic-right-text'>
                      <div className='ic-text'>
                        Cash <br />
                        in Bank
                      </div>
                      <div className='ic-price'>1.55L</div>
                    </div>
                  </li>
                  <li className='ic-list card-block'>
                    <div className='ic-left-icon card-i'></div>
                    <div className='ic-right-text'>
                      <div className='ic-text'>
                        Credit <br />
                        Card
                      </div>
                      <div className='ic-price'>45k</div>
                    </div>
                  </li>
                  <li className='ic-list invest-block'>
                    <div className='ic-left-icon investment-i'></div>
                    <div className='ic-right-text'>
                      <div className='ic-text'>
                        PAYLATER <br />
                        LOANS
                      </div>
                      <div className='ic-price'>1.45Cr.</div>
                    </div>
                    <span className='ic-right-icon'></span>
                  </li>
                </ul>
              </div>

              <div className={`${current===5?'tour6':''} profile-car mx-1`}>
                <span className='profile-img'>
                  <img src='images/profile-img.png' />
                </span>
                <span className='number-count'>
                  <b>02</b> / 8
                </span>
              </div>
            </div>

            <div className='col-lg-9 d-flex left-main-block'>
              <div className='col-lg-4 left-nav-block float-left d-flex'>
                <div className='left-bar-block'>
                  <div className={`${current===2?'tour3':''} ${current===3?'tour4':''} ${current===6?'tour7':''} left-nav-menu`}>
                    <ul className='style-none'>
                      <li>
                        <a href='leftevents.html' className='current'>
                          <span className='menu-icon life-i'></span>Life Events
                          <span className='count-icon'>
                            {current === 6 ? <img src="/images/true-icon.png" style={{width:"18px", marginTop:"-3px"}}/> : 5}
                          </span>
                        </a>
                      </li>
                     
                      <li>
                        <a href='invest.html'>
                          <span className='menu-icon invest-i'></span>Invest
                        </a>
                      </li>
                      <li>
                        <a href='loans.html'>
                          <span className='menu-icon loans-i'></span>Loans
                        </a>
                      </li>                       
                      <li>
                        <a href='insurance.html'>
                          <span className='menu-icon insurance-i'></span>
                          Insurance
                        </a>
                      </li>
                      <li>
                        <a href='purchase.html'>
                          <span className='menu-icon purchase-i'></span>SHOP
                        </a>
                      </li>
                      <li>
                        <a href='personal.html'>
                          <span className='menu-icon personal-i'></span>Personal
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className={`${current===7?'tour8':''} bottom-mobile for-mobile`}>
                    <img src='images/man-img-mobile.gif' width='100px' />
                  </div>
                </div>
              </div>

              <div className='col-lg-8 float-left main trans d-flex'>
                <div
                  className='main-wrapper'
                  style={{ background: 'transparent' }}
                >
                  <section
                    className='all-done-section'
                    style={{ display: 'none' }}
                  >
                    <div className='alldone-wrapper'>
                      <img src='images/alldone-banner.png' />
                      <div className='alldone-content'>
                        <h1>All Done</h1>
                        <p>
                          You've completed all tasks for this level. Click on
                          the button below to proceed to the next stage in the
                          IDFC Game of Life
                        </p>
                      </div>
                    </div>
                  </section>

                  <section>
                    <div>
                      <Slider
                        className={`${current===1?'tour2':''} regular contextual slider`}
                        {...settings}Slide
                      >
                        {[0,1,2].map((key) => (
                          <div key ={`tour-div-${key}`}>
                          <img
                            className='contextual-banner'
                            src='images/banner-1.png'
                          />
                          <div className='banner-content'>
                            <h1>DRAT!</h1>
                            <p>
                              Your car refuses to start! The mechanic thinks it will
                              take him a week His estimate is Rs.20,000/-
                            </p>
                            <div className='btn-div'>
                              <a className='btn paynow'>
                                Pay Now
                              </a>
                              <a className='btn skip'>
                                Skip
                              </a>
                            </div>
                          </div>
                        </div>
                        ))}
                      </Slider>
                    </div>
                  </section>

                  <div>
                    <a
                      className={`${current===6?'tour7':''} next-stage-btn ative contextual-next-btn`}
                    >
                      <span>
                        <img
                          src='images/icon-gotonextstage.png'
                          className={`icon-left ${
                            current === 6 ? '' : 'd-none'
                          }`}
                        />
                        {current === 6 ? 'GO TO NEXT STAGE' : 'NEXT STAGE'}
                        <img
                          src='/images/arrow-white.png'
                          className={`arrow-white ${
                            current === 6 ? 'd-none' : ''
                          }`}
                        />
                      </span>
                    </a>
                  </div>
                  <div
                    className='modal fade oops-popup'
                    id='oops-popup'
                    tabIndex='-1'
                    role='dialog'
                    aria-labelledby='exampleModalCenterTitle'
                    aria-hidden='true'
                  >
                    <div
                      className='modal-dialog modal-dialog-centered'
                      role='document'
                    >
                      <div className='modal-content'>
                        <div className='modal-body'>
                          <div className='redeem-wrapper buy-ins-block '>
                            <div className='left-side-image float-left'>
                              <img src='images/oops-left-img.png' />
                            </div>

                            <div className='right-side-redeem float-right'>
                              <div className='redeem-form'>
                                <h3 className='subtitle-name'>OOPS!</h3>
                                <p>
                                  You can move to the next stage only after you
                                  complete all tasks. Make sure you have
                                  completed all the tasks in the Life events
                                  section.
                                </p>
                                <div className='button-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-primary giveup'
                                    data-dismiss='modal'
                                  >
                                    Give Up
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-3 float-right right-side for-desktop'>
              <div className={`${current===5?'tour6':''} profile-car mx-1`}>
                <span className='number-count'>
                  <b>02</b> / 8
                </span>
                <span className='profile-img'>
                  <img src='images/profile-img.png' />
                </span>
                <span className='car-icon'>
                  <img src='images/car-icon.png' />
                </span>
              </div>
              <div className='in-exp'>
                <div className='incomme'>
                  <span>INCOME</span>
                  <span className='text-bigger'>30 L</span>
                </div>

                <div className='exp'>
                  <span>EXPENSES</span>
                  <span className='text-bigger'>25 L</span>
                </div>
              </div>

              <div className={`${current===4?'tour5':''} ic-right-wrap`}>
                <ul className='style-none'>
                  <li className='ic-list cash-block'>
                    <div className='ic-left-icon cash-i'></div>
                    <div className='ic-right-text'>
                      <div className='ic-text'>Cash in Bank</div>
                      <div className='ic-price'>7.55L</div>
                    </div>
                  </li>
                  <li className='ic-list card-block'>
                    <div className='ic-left-icon card-i'></div>
                    <div className='ic-right-text'>
                      <div className='ic-text'>Credit Card</div>
                      <div className='ic-price'>2.5L</div>
                    </div>
                  </li>
                  <li className='ic-list invest-block'>
                    <div className='icon-s deposits-i'></div>
                    <div className='ic-right-text'>
                      <div className='ic-text'>Paylater Loans</div>
                      <div className='ic-price'>50L</div>
                    </div>
                    <span className='ic-right-icon'></span>
                  </li>
                </ul>
              </div>

              <div className={`${current===7?'tour8':''} right-bottom-man`}>
                <img src='images/man-img.png' />
                <div className='tag-box'>BUGS HAS A PRO TIP FOR YOU!</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GameTour;
