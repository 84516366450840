import React, { useState, useEffect } from "react";
import { GOOG_URL,FB_URL } from "../constants";
import { GOOG_URL1,FB_URL1 } from "../constants";

const Social = (props) => {
  // console.log(props);
  const type = props.location.pathname;//rops.match.params.type;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [displayName, setDisplayName] = useState("");


  useEffect(() => {
   // firebase.initializeApp(firebaseConfig);
    var provider = "";
    switch(type) {
      case "/fb":
      //authType='Facebook';
      window.location.replace(FB_URL1);
      break;
      case "/goog":
      //authType='Google';
      window.location.replace(GOOG_URL);
      break;
      case "/facebook":
      //authType='Facebook';
      window.location.replace(FB_URL);
      break;
        //break;https://www.facebook.com/v9.0/dialog/oauth?client_id=163249945322626&state=2916f04f1c86bc0c80a0f278f8b758aa&response_type=code&sdk=php-sdk-5.7.0&redirect_uri=https%3A%2F%2Fwww.gameoflifelgn.5secrets.in%2Ffb%2Ffb-callback.php&scope=email

      case "/google":
      default:
      //authType='Google';
       window.location.replace(GOOG_URL1);
      //  provider = new firebase.auth.GoogleAuthProvider();
        break;
    }
   //console.log('login'); 


  }, []);
  
  return (
    <div style={{
      textAlign: "center",
      fontSize: 20,
    }}>
      {isLoggedIn ? 
        (<div>
          User logged in successfully via {type}: {displayName}
        </div>) : "Authenticating..."
      }
    </div>
  )
}

export default Social;