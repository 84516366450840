import React from "react";
import { formatAmount } from "../../helpers/common";

const FinanceModal = ({
  isOpen,
  toggleModal,
  data,
}) => {
  return (
    <div
      className={`modal fade fin-summary overlay ${isOpen ? 'show' : ''}`}
      id='fin-summary'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      style={{ display: isOpen ? 'block' : 'none', paddingRight: '12px' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <a
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={() =>toggleModal('none')}
          >
            <span aria-hidden='true'>×</span>
          </a>
          <div className='modal-body custom-style-valign'>
            <div className="table-smm fs-table-list">
              <h2 className="fins-heading">YOUR FINANCIAL SUMMARY</h2>
                <ul className="style-none">
                  <li className="space"></li>
                  {data && data.txnSum.map((each, index) =>
                    <li key={`finance-li-${index}`}>
                      <div className="left-col">{each.txtVal}{each.amount > 0 && 'label' in each && (<em className="red-text">{each.label}</em>)}</div>
                      <div className="right-col">Rs. {formatAmount(each.amount)}{each.amount > 0 && 'label' in each && (<span className="icon-red"></span>)}</div>
                  </li>
                )}
            </ul>
              <div className="button-footer">
                <a className="btn btn-primary btn-pd" onClick={() =>toggleModal('finance-details')}>VIEW DETAILS</a>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceModal;
