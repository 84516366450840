import React, { useContext } from "react";
import { PurchaseContext } from "../providers/PurchaseProvider";

const AlreadyPurchasedTabContent = () => {
  const { purchasedData, loading } = useContext(PurchaseContext);

  return (
      <div id="already-shopped" className="tab-pane fade in active show">
        <div className="shop-list">
          {purchasedData && purchasedData.length===0 &&
            <div className='inv-row'>
              <span className='text-medium text-inv'>
                Currently you do not have any assets.
              </span>
          </div>
          }
          <ul>
          {purchasedData && purchasedData.length>0 &&
            purchasedData.map(
              (each, index) =>
                <li key={`purchasedlist-${index}`}><span><img src={`/images/assets/shopped/${each.assetImg}`} alt="alread-shopped"/></span><p>{each.product}</p></li>
            )}
          </ul>
          {loading && <img src={`/images/loader.svg`} alt="Loading..." />}
        </div>
      </div>
  );
};

export default AlreadyPurchasedTabContent;
