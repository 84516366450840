import React from "react";

const InsuranceThankYou = ({
   data,
   isOpen,
   toggleModal,
   setApiData
}) => {

  const handleClose = () => {
    setApiData(null);
    toggleModal("none");
  };

  return (
    <div
      className={`modal fad buy-insurance-popupe ${isOpen ? "show" : ""}`}
      id="insurance-confirm"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            <div className="redeem-success">
              {data && (
                <div className="redeem-wrapper align-middle buy-ins-block buy-confirm">
                  <div className="left-side-image image-man">
                    <img src={`/images/${data.sucImg}`} alt={data.sucImg} />
                  </div>
                  <div className="right-side-redeem float-right">
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">SUCCESS!</h6>
                      <p>You have successfully purchased {data.product}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceThankYou;
