/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from "react";
import Slide from "./Slide";
import Slider from "react-slick";
import fetchData from "../helpers/fetchData";
import {
  EVENTS_API,
  EVENTS_BUY_API,
  STAGE_TRANSITION_POST_API,
  DASHBOARD_DETAILS_API,
  INVEST_BUY_API,
  ASSETS_LIST_API,
  MASCOT_TIP_API
} from "../constants";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import EventsMainModal from "./modals/Events/EventsMain";
import EventsConfirmModal from "./modals/Events/EventsConfirm";
import EventSuccessModal from "./modals/Events/EventSuccess";
import EventOopsModal from "./modals/Events/EventsOops";
import InvestSimpleModal from "./modals/InvestSimple";
import InvestConfirmModal from "./modals/InvestConfirm";
import InvestSuccessModal from "./modals/InvestThankYou";
import MascotTipModal from "./modals/MascotTipModal";
import { getData, postData, finishGame, redirectToErrorPage } from "../helpers/api";
import { HomeContext } from "../providers/HomeProvider";
import { InvestContext } from "../providers/InvestProvider";
import { getCcbankValues } from "../helpers/common";
import CubeLoader from "./CubeLoader";
// import LeftNavBlock from "../components/LeftNavBlock";

const settings = {
  dots: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};


const CarouselContainer = ({ history }) => {
  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);
  const { data: stage_data, setData: setStageData, setProfileData } = useContext(HomeContext);
  const {eventData: data, setEventData: setData, loading: loading, setLoading: setLoading } = useContext(HomeContext);
  const [activeModal, setActiveModal] = useState("none");
  const [modalData, setModalData] = useState(null);
  const [modalData1, setModalData1] = useState(null);
  const [apiResponse, setApiData] = useState(null);
   const [investData, setInvestmentData] = useState(null);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const { investmentOptions } = useContext(InvestContext);
  const [deactiveButton, setDeactiveButton] = useState(false);

  const stageBtnTxt = stage_data && stage_data.currentStage === stage_data.totStage ? "Finish The Game" : "Go To Next Stage";
  const stageBtnIcon = stage_data && stage_data.currentStage === stage_data.totStage ? "icon-trophy" : "icon-gotonextstage";
  
  /* stage_data && stage_data.currentStage === stage_data.totStage ? "alldone-banner2.png" : "alldone-banner.png";*/
  const bannerImg = stage_data && stage_data.allDImg!==""  ? stage_data.allDImg : "alldone-banner.png";

  const genTxt = stage_data && stage_data.currentStage === stage_data.totStage 
      ? "To finish the game Repay loans if any.</p><p> Click on FINISH THE GAME button."
:"Before you go to the next stage consider other options like Investing for your Future or Protecting your family through Insurance if not done already."
  const handleInvestClick = (invAmount) => {
    setInvestmentData({ ...investData, invAmount });
    setActiveModal("invest_confirm");
  };

  const handleInvestConfirm = () => {
    setConfirmLoader(true)
    postData(INVEST_BUY_API, getInvestApiData()).then((res) => {
      setApiData(res);
      setConfirmLoader(false);
      if (get(res, "status") === "success") {
        setActiveModal("invest_success");
      }
    })
    .catch((err) => {
      setApiData(err);
      // If error type is not object or message key is not there
      // we assume that the error is not generate from the backend
      if (typeof err !== 'object' || !('message' in err)) {
        redirectToErrorPage("Something went wrong while confirming your investment!", "TRY AGAIN");
      }
    });
  };

  const getInvestApiData = () => {
    const { productId, invAmount } = investData;

    return {
      amount: invAmount,
      productId: productId,
    };
  };


  const handleMainBtnClick = (eventId) => {
    const curr = get(
      data.filter((each) => each.eventId === eventId),
      "[0]"
    );
    setModalData(curr);
    if (curr.layerType === "Info") {
      // if (curr.popupType === "infoPopup") setActiveModal("success");
      handleSkipClick(eventId, get(curr, "items[0].productId"), "ok", get(curr, "popupType"));
    } else if (curr.pageLink.trim().length !== 0) {
      handleSkipClick(eventId, undefined, "ok", undefined);
      switch (curr.pageLink) {
        case "INVEST":
          history.push("/invest/makeaninvestment");
          break;
        case "INVEST-MUTUALFUND":
          history.push("/invest/makeaninvestment");
          break;
        case "INVEST-POPUP-mf_sip_sifi":
          setInvestmentData(investmentOptions[0].items.filter((i) => i.productId==='mf_sip_sifi')[0])
          setActiveModal("invest");
          break;
        case "INVEST-POPUP-mf_elss":
          setInvestmentData(investmentOptions[0].items.filter((i) => i.productId==='mf_elss')[0])
          setActiveModal("invest");
          break;
        case "BUGSLAYER" :
          getData(MASCOT_TIP_API, "data.data").then((op) => {
               //setMascotTip(op);
               setModalData(op[0]);
               setActiveModal("mascot");
             // setInsLoading(false);
            });
          //setModalData(tipData[0])
          setActiveModal("mascot");
          break;
        case "CURINVESTMENTS":
          history.push("/invest/investments");
          break;
        case "REPAYLOANS":
          history.push("/loans");
          break;
        default:
      }
    } else {
      setActiveModal("purchase");
    }
  };

  const handleSkipClick = (eventId, products=undefined, type='skip', popupType=undefined) => {

   // handleEventBuyApi(getApiData(eventId, "skip",'info',products));
  
    setData(uniqBy(data, eventId));
    const lastEvent = data.pop();
    data.push(lastEvent);
    const isLast = lastEvent.eventId === eventId ? true : false;
    handleEventBuyApi(getApiData(eventId, type, products), popupType);
    if(data.length > 1){
      if (isLast) {
        prevSlide();
      } else {
        nextSlide();
      }
    }
    setTimeout(() => {
      const newData = data.filter((each) => each.eventId !== eventId);
      newData.length ? setData(newData) : setData(null);
    }, 200);
  };

  const handleEventBuyApi = async (finalData, popupType) => {
    try {
      const res = await postData(EVENTS_BUY_API, finalData);
      if(get(res, "status")!== "failed"){
        if(popupType==="infoPopup"){
          setActiveModal("success");
          getData(ASSETS_LIST_API, "data").then((data) => {
            setProfileData(data);
          });
        }
        if(popupType==="infoPopupRefresh"){
          fetchData(DASHBOARD_DETAILS_API, setStageData);
        }
      }
return res;
     /* postData(EVENTS_BUY_API, finalData)
      .then((res) => {
        setApiData(res);
        if (get(res, "status") === "success") {
          setActiveModal("thank_you");
          fetchData(DASHBOARD_DETAILS_API, setData, setLoading);
          getData(USER_LOANS_API, "data.items").then((data) => {
            setLoanOptions(data);
            setloanOptionsLoading(false);
          });
        }
      })
      .catch((err) => {
        setApiData(err);
      });*/

    } catch (err) {
 if(popupType==="infoPopup"){
        setApiData(err);
        setActiveModal("success");
      }
      return err;
    }
  };
  
  const getApiData = (
    eventId,
    eventAction,
    productId = undefined,
    mode = undefined,
    emiYears = undefined,
    ccAmount = undefined,
    bankAmount = undefined
  ) => {
    const finalData = {
      eventId,
      eventAction,
    };

    if (mode) finalData.mode = mode;
    if (productId) finalData.productId = productId;
    if (mode==='emi') if (emiYears) finalData.emiYears = emiYears
    if (mode==='ccbank'){
      finalData.ccAmount = ccAmount
      finalData.bankAmount = bankAmount
    }
    return finalData;
  };

  const handleModalBuyClick = (
    selectedOption,
    selectedProduct,
    selectedEmi,
    selectedCcBank
  ) => {
    setModalData({
      ...modalData,
      selectedOption,
      selectedProduct,
      selectedEmi,
      selectedCcBank
    });    
    setActiveModal("confirm");
  };

  const handleModalConfirmClick = async () => {
    setConfirmLoader(true)
    const mode = modalData.selectedOption;
    const emiYears = modalData.selectedEmi;
    const productId = modalData.selectedProduct.productId;
    const ccAmount = getCcBankAmount(modalData.selectedCcBank, "cc");
    const bankAmount = getCcBankAmount(modalData.selectedCcBank, "bank");
    const { eventId } = modalData;
    const res = await handleEventBuyApi(
      getApiData(eventId, "buy", productId, mode, emiYears, ccAmount, bankAmount)
    );
    //setConfirmLoader(false);
     setApiData(res);
    if (get(res, "status") !== "failed") {
      setActiveModal("success");
      fetchData(DASHBOARD_DETAILS_API, setStageData);
      fetchData(EVENTS_API, setData, setLoading);
        getData(ASSETS_LIST_API, "data").then((data) => {
          setProfileData(data);
        });
	   }
    setConfirmLoader(false)
  };

  const getCcBankAmount = (selectedCcBank, type) => {
    return (
      modalData.selectedProduct.ccBank &&
      getCcbankValues(modalData.selectedProduct.ccBank, modalData.selectedProduct.price, type)[selectedCcBank]
    );
  };

  const handleNextStageBtnClick = async() => {
    
    let res_success = {};
   if (data) {
      res_success["status"] = "success"
      res_success["message"] = "You can move to the next stage only after you complete all tasks. Make sure you have completed all the tasks in the Life events section."
      res_success["page"] =  "STAY"
      res_success["buttonTxt"] = "Ok"
      setModalData1(res_success);
      setActiveModal("oops");
    } else {
      setDeactiveButton(true);
      const res = await postData(STAGE_TRANSITION_POST_API, {});
      if (res.status=== "failed") {
        setModalData1(res);
        //setData(res.message);
        setActiveModal("oops");
      }
      else{       
        if(stage_data.currentStage < stage_data.totStage){
          history.push("/stage-transition");
            }

        // Game Win/Lost condition for last stage
        else if(stage_data.currentStage === stage_data.totStage){
          if ((res.gameStatus==="GAMEWON")){
            finishGame('/game-won')
          }
          else if ((res.gameStatus==="GAMELOST")) {
            finishGame('/game-lost')
          }
            }
      //postData(STAGE_TRANSITION_POST_API, {}); // blank as there is only user id required which will be handled by api
        }
      }
  };

  // useEffect(() => {
  //   fetchData(EVENTS_API, setData, setLoading);
  // }, []);

  let slider;

  const nextSlide = () => {
    slider.slickNext();
  };

  const prevSlide = () => {
    slider.slickPrev();
  };

  return (
    <>
      <div className="col-lg-8 float-left main trans d-flex">
        <div className="main-wrapper" style={{ background: "transparent", transitionDelay: "2s" }}>
          {!data && loading && <CubeLoader />}
          {data && (
            <div className="carousel-wrapper">
              <Slider
                ref={(c) => (slider = c)}
                className="regular slider"
                {...settings}Slide
              >
                {Object.keys(data).map((key) => (
                  <Slide
                    data={data[key]}
                    key={`slider-${key}`}
                    handleBtnClick={handleMainBtnClick}
                    handleSkipClick={handleSkipClick}
                  />
                ))}
              </Slider>
            </div>
          )}
          {!data && !loading && (
            <section className="all-done-section">
              <div className="alldone-wrapper">
                <img src={"/images/Alldone/" + bannerImg} alt="all done" />
                <div className="alldone-content">
                  <h1>Life Events Completed </h1>
                  <p>
                    {/* <p
                      dangerouslySetInnerHTML={{ __html: genTxt }}
                    />  */}
                    {genTxt}
                  </p>
                </div>
              </div>
            </section>
          )}
          {!loading && (
            <a
              data-toggle="modal"
              onClick={handleNextStageBtnClick}
              data-target="#oops-popup"
              className={`next-stage-btn ${data ? "inative" : "ative"} ${deactiveButton ? "op-0":""}`}
              style={{ cursor: "pointer" }}
            >
              <span>
                {!data && <img src={`images/${data ? "icon-viewpendingtasks":stageBtnIcon}.png`} className="icon-left" />}
                {data ? "Next Stage": stageBtnTxt}
                {data && <img src="/images/arrow-white.png" className="arrow-white" />}
              </span>
            </a>
          )}
        </div>
      </div>
      <EventsMainModal
        isOpen={activeModal === "purchase"}
        data={modalData}
        toggleModal={setActiveModal}
        handleBuyClick={handleModalBuyClick}
      />
      <EventsConfirmModal
        isOpen={activeModal === "confirm"}
        data={modalData}
        toggleModal={setActiveModal}
        setApiData={setApiData}
        apiResponse={apiResponse}
        handleConfirm={handleModalConfirmClick}
        confirmLoader={confirmLoader}
        setConfirmLoader={setConfirmLoader}
      />
      <EventSuccessModal
        isOpen={activeModal === "success"}
        data={modalData}
        toggleModal={setActiveModal}
      />
      <EventOopsModal
        isOpen={activeModal === "oops"}
        toggleModal={setActiveModal}
        stageData={stage_data}
        data={modalData1}
      />
      <InvestSimpleModal
          isOpen={activeModal === "invest"}
          data={investData}
          toggleModal={setActiveModal}
          handleInvestClick={handleInvestClick}
        />
        <InvestConfirmModal
          isOpen={activeModal === "invest_confirm"}
        data={investData}
          toggleModal={setActiveModal}
          apiResponse={apiResponse}
          setApiData={setApiData}
        handleInvestConfirm={handleInvestConfirm}
        confirmLoader={confirmLoader}
        setConfirmLoader={setConfirmLoader}
        />
        <InvestSuccessModal
          isOpen={activeModal === "invest_success"}
        data={investData}
          toggleModal={setActiveModal}
          apiResponse={apiResponse}
          setApiData={setApiData}
        />
      <MascotTipModal
        isOpen={activeModal === "mascot"}
        closeMascotModal={setActiveModal}
        data={modalData}
      />
    </>
  );
};

export default CarouselContainer;
