import React, { useState, useEffect } from "react";
import fetchData from "../helpers/fetchData";
import { getData } from "../helpers/api";
import { DASHBOARD_DETAILS_API, MASCOT_TIP_API, EVENTS_API, ASSETS_LIST_API, PURCHASED_LIST_API } from "../constants";

export const HomeContext = React.createContext();

const HomeProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tipData, setTipData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    fetchData(DASHBOARD_DETAILS_API, setData, setLoading);
    fetchData(MASCOT_TIP_API, setTipData);
    fetchData(EVENTS_API, setEventData);
    getData(ASSETS_LIST_API, "data").then((data) => {
      setProfileData(data);
    });
  }, []);

  return (
    <HomeContext.Provider
      value={{ data, loading, setData, setLoading, tipData, eventData, setEventData, profileData, setProfileData }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default HomeProvider;
