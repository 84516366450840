// Home API's
//https://zvwtbotmdi.execute-api.ap-south-1.amazonaws.com/development
//https://nc74lvjzml.execute-api.ap-south-1.amazonaws.com/development
var env='dev';
//if(env=='dev'){
  export const baseUri = 'https://zvwtbotmdi.execute-api.ap-south-1.amazonaws.com/development';
  //const baseUri = 'https://golbck.5secrets.in';
  //const baseUri = 'https://zvwtbotmdi.execute-api.ap-south-1.amazonaws.com/v1';
  //const baseUri1 = 'https://gameoflife.idfc.com';
  export const FAIL_URL = 'https://idfcgameoflife.idfc.com/index.html';
  export const baseUri1 = 'https://www.gameoflifelgn.5secrets.in';
  export const FB_URL1 = 'https://www.facebook.com/v9.0/dialog/oauth?client_id=163249945322626&state=fbx2916f04f1c86bc0c80a0f278f8b758aa&response_type=code&sdk=php-sdk-5.7.0&redirect_uri=https%3A%2F%2Fgameoflife.idfc.com%2Fstart&scope=email';
  export const GOOG_URL1 = 'https://accounts.google.com/o/oauth2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&redirect_uri=https%3A%2F%2Fgameoflife.idfc.com%2Fstart&response_type=code&client_id=642621246350-en17d6k2tboplos9nu49esnvqnof4jha.apps.googleusercontent.com&access_type=offline';
 
 //local 5secret
  export const FB_URL = 'https://www.facebook.com/v9.0/dialog/oauth?client_id=419244575943582&state=fbx2916f04f1c86bc0c80a0f278f8b758aa&response_type=code&sdk=php-sdk-5.7.0&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fstart&scope=email';
  export const GOOG_URL = 'https://accounts.google.com/o/oauth2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fstart&response_type=code&client_id=216092372984-8kovva7n76q3gedev2jru7f4mukc6ghn.apps.googleusercontent.com&access_type=offline';

//live5secret
 // export const FB_URL = 'https://www.facebook.com/v9.0/dialog/oauth?client_id=419244575943582&state=fbx2916f04f1c86bc0c80a0f278f8b758aa&response_type=code&sdk=php-sdk-5.7.0&redirect_uri=https%3A%2F%2Fgameoflife.idfc.com%2Fstart&scope=email';
 // export const GOOG_URL = 'https://accounts.google.com/o/oauth2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&redirect_uri=https%3A%2F%2Fgameoflife.idfc.com%2Fstart&response_type=code&client_id=216092372984-8kovva7n76q3gedev2jru7f4mukc6ghn.apps.googleusercontent.com&access_type=offline';

/*
}
else{
  export const baseUri = 'https://zvwtbotmdi.execute-api.ap-south-1.amazonaws.com/v1';
  //const baseUri = 'https://golbck.5secrets.in';
  //const baseUri = 'https://zvwtbotmdi.execute-api.ap-south-1.amazonaws.com/v1';
  //const baseUri1 = 'https://gameoflife.idfc.com';
  export const baseUri1 = 'https://www.gameoflifelgn.5secrets.in';
  export const FAIL_URL = 'https://idfcgameoflife.idfc.com/index.html';
  export const FB_URL = 'https://www.facebook.com/v9.0/dialog/oauth?client_id=163249945322626&state=fbx2916f04f1c86bc0c80a0f278f8b758aa&response_type=code&sdk=php-sdk-5.7.0&redirect_uri=https%3A%2F%2Fgameoflife.idfc.com%2Fstart&scope=email';
  export const GOOG_URL = 'https://accounts.google.com/o/oauth2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&redirect_uri=https%3A%2F%2Fgameoflife.idfc.com%2Fstart&response_type=code&client_id=642621246350-en17d6k2tboplos9nu49esnvqnof4jha.apps.googleusercontent.com&access_type=offline';
  export const FB_URL1 = 'https://www.facebook.com/v9.0/dialog/oauth?client_id=419244575943582&state=fbx2916f04f1c86bc0c80a0f278f8b758aa&response_type=code&sdk=php-sdk-5.7.0&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fstart&scope=email';
  export const GOOG_URL1 = 'https://accounts.google.com/o/oauth2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fstart&response_type=code&client_id=216092372984-8kovva7n76q3gedev2jru7f4mukc6ghn.apps.googleusercontent.com&access_type=offline';

}*/
export const AUTHENTICATION_API =
  baseUri+"/user/checkGame";
export const AUTHENTICATION_API1 =
  baseUri1+"/data-send.php";
export const AUTHENTICATION_API_SOC =
  baseUri1+"/google/gapi.php";
export const AUTHENTICATION_API_SOC1 =
  baseUri1+"/fb/fapi.php";
//fapi-local.php
export const DASHBOARD_DETAILS_API =
  baseUri+"/dashboard/details";
export const MASCOT_TIP_API =
  baseUri+"/master/tip";

// Purchase API's
export const PURCHASE_LIST_API =
  baseUri+"/product/list";
export const BUY_PRODUCT_API =
  baseUri+"/product/buy";
export const PURCHASED_LIST_API =
  baseUri+"/user/getPurchaseDetails";
export const ASSETS_LIST_API =
  baseUri+"/user/getPurchaseDetails?type=asset";

// Insurance API's
export const INSURANCE_LIST_API =
  baseUri+"/user/insurance?type=mylist";
export const INSURANCE_BUY_LIST_API =
  baseUri+"/user/insurance?type=list";
export const INSURANCE_BUY_API =
  baseUri+"/user/insurance?type=buy";

// Invest API's
export const CURR_INVEST_API =
  baseUri+"/user/invest";
export const INVEST_LIST_API =
  baseUri+"/product/invlist";
export const INVEST_BUY_API =
  baseUri+"/product/invBuy";
export const INVEST_REDEEM_API =
  baseUri+"/user/invRedeem";
export const INVEST_DETAIL_API =
  baseUri+"/user/stageSummeryNew?type=cashsummary"

// Personal API's
export const PERSONAL_FAMILY_API =
  baseUri+"/product/personalList?type=Family";
export const PERSONAL_CAREER_API =
  baseUri+"/product/personalList?type=Career";

// Carousel API's
export const EVENTS_API =
  baseUri+"/game/event";
export const EVENTS_BUY_API =
  baseUri+"/product/buy";

// Loans API's
export const USER_LOANS_API =
  baseUri+"/user/loansList";
export const LOAN_REQUEST_API =
  baseUri+"/user/loansList?type=takeloan";
export const REPAY_CC_PL_API =
  baseUri+"/user/loanRepay";
export const TAKE_LOAN_API =
  baseUri+"/product/buy";

// Profile Page API's
export const PROFILE_FORM_API =
  baseUri+"/master/all";
export const PROFILE_FORM_MAPPING_API =
  baseUri+"/master/emiOption";
//export const SUBMIT_PROFILE_DATA_API =
//  baseUri+"/user/register";
export const SUBMIT_PROFILE_DATA_API =
  baseUri+"/user/create";
  

// Stage Movement API's
export const STAGE_TRANSITION_API =
  // baseUri+"/user/stageSummery";
  baseUri+"/user/stageSummeryNew";

export const STAGE_TRANSITION_POST_API =
  baseUri+"/user/stageChange";

export const MODES_LABEL = {
  cc: "CREDIT CARD",
  paylater: "PAY LATER",
  bank: "CASH IN BANK",
  emi: "EYI",
  ccbank: "BANK + CREDIT CARD",
};
