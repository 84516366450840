import React from 'react';
import get from 'lodash/get';
import { formatAmount } from '../../helpers/common';

const LoanConfirmModal = ({
  data,
  isOpen,
  toggleModal,
  handleConfirm,
  apiResponse,
  setApiData,
  confirmLoader,
  setConfirmLoader,
}) => {
  const handleClose = () => {
    toggleModal('none');
    setApiData(null);
    setConfirmLoader(false);
  };

  return (
    <div
      className={`modal fade take-loans-modal ${isOpen ? 'show' : ''}`}
      id='loan-confirm'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      aria-hidden='true'
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <a
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={handleClose}
          >
            <span aria-hidden='true'>×</span>
          </a>
          <div className='modal-body'>
            {data && (
              <div className='invest-confirmation'>
                <div className='redeem-wrapper align-middle'>
                  <div className='left-side-image float-left'>
                    <img src='images/repay-confirm_loanrequest.png' alt=""/>
                  </div>
                  <div className='right-side-redeem float-right'>
                    <div className='redeem-form'>
                      <h6 className='subtitle-redeem border-bottom'>
                        CONFIRM NEW LOAN REQUISITION
                      </h6>
                      <p>
                        Value: {formatAmount(data.loanAmount, ' ')} specified
                        for duration {data.emiYears} years
                      </p>
                      <p className='monthly-emi-value border-top'>
                        Yearly EYI: {data.emiAmount}
                      </p>
                      <div className='button-footer'>
                        <button
                          type='button'
                          className={`btn btn-primary ${
                            apiResponse && apiResponse.status === 'failed'
                              ? 'op-0'
                              : ''
                          }`}
                          data-dismiss='modal'
                          data-toggle='modal'
                          data-target='#repay-approved'
                          onClick={handleConfirm}
                        >
                          CONFIRM
                        </button>
                        <button
                          type='button'
                          className='btn btn-secondary'
                          data-dismiss='modal'
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                      {get(apiResponse, 'status') === 'failed' && (
                        <em className='error-text' style={{ width: '290px' }}>
                          {apiResponse.message}
                        </em>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div align='middle'>
            <img             
              className={confirmLoader ? '' : 'hide'}
              src={`/images/loaderbg.gif`}
              alt='Loading...'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanConfirmModal;
