/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const RedeemSuccess = ({ data, isOpen, toggleModal, selectedValue }) => {
  return (
    <div
      className={`modal fade current-investments-tab-content ${
        isOpen ? "show" : ""
      }`}
      id="redeem-thankyou"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => toggleModal("none")}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            <div className="redeem-success">
              <div className="redeem-wrapper align-middle">
                <div className="left-side-image float-left">
                  <img src="/images/redeem-success.png" />
                </div>
                {data && (
                  <div className="right-side-redeem float-right">
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">SUCCESS!</h6>
                      <h3 className="subheading-name">
                        You have successfully redeemed {data.displayName}
                      </h3>
                      <p>Value: {selectedValue}</p>
                    </div>
                  </div>
                )}
                {!data && (
                  <h5 className="title-redeem">Something Went Wrong!!</h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemSuccess;
