/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../providers/ProfileProvider";
import ProfileForm from "./ProfileForm";
import { useForm } from "react-hook-form";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import axios from "axios";
import { SUBMIT_PROFILE_DATA_API } from "../constants";
import { fetchSessionData,login, config } from "../helpers/api";
import { getProfileImg } from "../helpers/common";

const useStyles = makeStyles({
  root: {
    color: "#f1666b",
    position: "relative",
    bottom: "18px",
    padding: "13px 0",
  }
});

const ProfileContent = () => {
  const [nickName, setNickName] = useState("");
  // const [ageChecker, setAgeChecker] = useState(true);

  const handleNickNameChange = (e) => {
    var value = e.target.value;
    // Do not allow starting the input with a white space
    if (value === " ") {
      value = "";
    }
    else {
      // Replace multiple consecutive spaces with a single space
      value = value.replace(/\s\s+/g, ' ');
      setNickName(value);
    }
  };

  const classes = useStyles();

  const { register, handleSubmit, errors } = useForm();

  const { data, userSelection, step, setuserSelection, setStep } = useContext(
    ProfileContext
  );

  useEffect(() => {
    setuserSelection({ enteredAge: 25, kids: 0 });
  }, [setuserSelection]);

  function round5(x) {
    return Math.ceil(x / 5) * 5 - 5;
  }

  const getAgeImg = () => {
    const ageRange = round5(userSelection["enteredAge"]);
    //console.log(userSelection["enteredAge"], ageRange)
    switch (userSelection["gender"]) {
      case "female":
        switch (ageRange) {
          case 20:
          case 25:
            return "age_female_25.png";
          case 30:
            return "age_female_30.png";
          case 35:
            return "age_female_35.png";
          case 40:
            return "age_female_35.png";
          case 45:
          default:
            return "age_female_45.png";
        }
      case "male":
      default:
        switch (ageRange) {
          case 20:
          case 25:
            return "age_male_25.png";
          case 30:
            return "age_male_30.png";
          case 35:
            return "age_male_35.png";
          case 40:
            return "age_male_45.png";
          case 45:
          default:
            return "age_male_45.png";
        }
    }
  };

  const getKidsImg = () => {
    switch (userSelection["kids"]) {
      case 1:
        return "/couple-kid.png";
      case 2:
        return "/couple-kids.png";
      case 0:
      default:
        return "/married2.png";
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <>
          <div className="profile-content profile-welcome">
            <h2 className="heading-name">Welcome to the IDFC Game of Life!</h2>
            <label className="title-label">
              This game is best experienced if you enter basic information close to your real life. Let's get started and create your profile!
            </label>
            <div className="label-pink">
              Give yourself a nickname!
            </div>
            <div className="input-field-block si-input">
              <div className="input-block">
                <input
                  type="input"
                  placeholder="Enter nickname here"
                  className="input-redeem"
                  name="nickName"
                  value={nickName}
                  ref={register({ required: true })}
                  onChange={handleNickNameChange}
                />
                <em className="error-text red">
                  {errors.nickName?.type === "required" && (
                    <span>Please enter your nickname</span>
                  )}
                </em>
              </div>
            </div>
            </div>
          </>
        );
      case 1:
        return (
          <div className="gender-block">
            <div className="gender-image">
              <img src="/images/man.png" />
              <img src="/images/woman.png" />
            </div>
            <div className="form-content profile-gender">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline1"
                  name="gender"
                  value="male"
                  className="custom-control-input"
                  ref={register({ required: true })}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline1"
                >
                  Man
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline part-of-inv">
                <input
                  type="radio"
                  id="customRadioInline2"
                  name="gender"
                  value="female"
                  className="custom-control-input"
                  ref={register({ required: true })}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline2"
                >
                  Woman
                </label>
              </div>
            </div>
            <em className="error-text red">
              {errors.gender?.type === "required" && (
                <span>Please select a gender</span>
              )}
            </em>
          </div>
        );
      case 2:
        return (
          <div className="name-prof-block">
            <div className="age-block">
              <div className="age-image">
                <img id="age1" src={`/images/profile/${getAgeImg()}`} />
              </div>
              <img src="images/below-shadow.png" className="full-img" />
            </div>

            <div className="input-field-block">
              <div className="form-group select-group">
                <i className="arrow-select down" />
                <select
                  name="ageId"
                  className="form-control"
                  id="ageId"
                  key="ageSelect"
                  // defaultValue="0"
                  // value={get(userSelection, "ageId")}
                  onChange={(e) => { handleAgeChange(e.target.value) }}
                  ref={register}
                >
                  {get(data, "[0].age") &&
                    get(data, "[0].age").map((age) => (
                      <option value={age.id} key={`age${age.id}`}>
                        {age.disp}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* <div className="age-slide" style={{ marginBottom: "0px" }}>
              <div className="slider" />
              <Slider
                defaultValue={20}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                className={classes.root}
                onChangeCommitted={handleAgeChange}
                min={20}
                max={80}
                marks={ageMarks}
              /> */}
              {/* <em className="error-text text-center red">
              {!ageChecker && (
                <span>Please select an age using the slider</span>
              )}
              </em> */}
            {/* </div> */}
            </div>
        );
      case 3:
        return (
          <div className="name-prof-block">
            <div className="age-block">
              <div className="age-image">
                <img id="age1" src={`/images/profile/${getAgeImg()}`} />
              </div>
              <img src="images/below-shadow.png" className="full-img" />
            </div>

            <div className="input-field-block">
              <div className="form-group select-group">
                <i className="arrow-select down" />
                <select
                  name="profId"
                  className="form-control"
                  id="profId"
                  key="profSelect"
                  // defaultValue="0"
                  // value={get(userSelection, "profId")}
                  // onChange={(e) => { handleProfChange(e.target.value) }}
                  ref={register}
                >
                  {get(data, "[0].profession") &&
                    get(data, "[0].profession").map((prof) => (
                      <option value={prof.id} key={`prof${prof.id}`}>
                        {prof.text}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="gender-block">
            <div className="gender-image">
              <img
                src={getProfileImg(
                  get(data, "[0].maritalStatusImg.single"),
                  userSelection["gender"]
                )}
              />
              <img
                src={getProfileImg(
                  get(data, "[0].maritalStatusImg.married"),
                  userSelection["gender"]
                )}
              />
            </div>
            <div className="form-content profile-gender pro-married">
              <div className="custom-control custom-radio custom-control-inline radio-sigle">
                <input
                  type="radio"
                  id="customRadioInline1"
                  name="married"
                  value="N"
                  className="custom-control-input"
                  ref={register({ required: true })}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline1"
                >
                  No
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline radio-married">
                <input
                  type="radio"
                  id="customRadioInline2"
                  name="married"
                  value="Y"
                  className="custom-control-input"
                  ref={register({ required: true })}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline2"
                >
                  Yes
                </label>
              </div>
            </div>
            <em className="error-text red">
              {errors.married?.type === "required" && (
                <span>Please select an option</span>
              )}
            </em>
          </div>
        );
      case 5:
        return (
          <>
            <div className="age-block">
              <div className="age-image children">
                <img
                  id="children1"
                  src={`images${getKidsImg()}`}
                  className="couple-married"
                />
              </div>
              <img src="images/below-shadow.png" className="full-img" />
            </div>
            <div className="age-slide children-slide-block">
              <div className="slider" />
              <Slider
                defaultValue={0}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                className={classes.root}
                onChangeCommitted={handleKidsChange}
                step={1}
                min={0}
                max={2}
                marks={kidsMarks}
              />
            </div>
          </>
        );
      case 6:
        return <ProfileForm />;
      default:
        return <p> Something Went Wrong. Please try again!!</p>;
    }
  };

  const renderTitle = () => {
    switch (step) {
      case 1:
        return titleWrapper("1. Choose your gender");
      case 2:
        return titleWrapper("2. Choose your age");
      case 3:
        return titleWrapper("3. Choose your profession");
      case 4:
        return titleWrapper("4. Choose your marital status");
      case 5:
        return titleWrapper("5. You have");
      case 0:
      case 6:
      default:
        return;
    }
  };

  const titleWrapper = (title) => (
    <label className="title-label">{title}</label>
  );

  const onSubmit = (selectedData) => {
    // Go to Next Step
    if (step < 6) {
      // if (step==2 && userSelection.enteredAge==0) setAgeChecker(false)
      // else {
      //   setStep((prev) => {
      //     if (get(data, "married") && data.married === "N") {
      //       setuserSelection((prev) => {
      //         return { ...prev, kids: 0 };
      //       });
      //       return prev + 2;
      //     } else {
      //       return prev + 1;
      //     }
      //   });
  
      //   // If empty data, then skip
      //   if (Object.getOwnPropertyNames(data).length === 0) return;
  
      //   // Update selected values
      //   const [key, value] = Object.entries(data)[0];
      //   setuserSelection((prev) => {
      //     return { ...prev, [key]: value };
      //   });
      // }

      setStep((prev) => {
        if (get(selectedData, "married") && selectedData.married === "N") {
          setuserSelection((prev) => {
            return { ...prev, kids: 0 };
          });
          return prev + 2;
        } else {
          return prev + 1;
        }
      });

      // If empty selectedData, then skip
      if (Object.getOwnPropertyNames(selectedData).length === 0) return;

      // Update selected values
      const [key, value] = Object.entries(selectedData)[0];
      setuserSelection((prev) => {
        return { ...prev, [key]: value };
      });

    } else {
      const userData = userSelection;
      userData["locationId"] = "127.0.0.1";
      userData["id"] = "127.0.0.1";
      const { id } = fetchSessionData();
      userData["uniqueId"] =id;
      //console.log(userData);
      // using this as this is login api //else need to use only postData from api
      axios
        .post(SUBMIT_PROFILE_DATA_API, userData, config)
        .then((res) => res.data)
        .then((res) => {
          login(res);
          window.location.replace("/onboard");
        })
        .catch((res) => {
          alert("Something went wrong!!");
        });
    }
  };

  const handleAgeChange = (newAge) => {
    // setAgeChecker(true);
    setuserSelection((prev) => {
      return { ...prev, enteredAge: get(data, `[0].age[${newAge-1}].rangeto`) };
    });
  };

  // const handleProfChange = (newProf) => {
  //   setuserSelection((prev) => {
  //     return { ...prev, profId: newProf };
  //   });
  // };

  const handleKidsChange = (e, newVal) => {
    setuserSelection((prev) => {
      return { ...prev, kids: newVal };
    });
  };

  const kidsMarks = [
    {
      value: 0,
      label: "No Kids",
    },
    {
      value: 1,
      label: "1 Kid",
    },
    {
      value: 2,
      label: "2 Kids",
    },
  ];

  const ageMarks = [
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 40,
      label: "40",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 60,
      label: "60",
    },
    {
      value: 70,
      label: "70",
    },
    {
      value: 80,
      label: "80",
    },
  ];

  return (
    <div className="profile-content profile-welcome">
      {step > 0 && step < 6 && (
        <>
          <h2 className="heading-name">
            Hi {userSelection["nickName"]}, let's generate your profile
          </h2>
          <div className="step-block line-step">
            {Array(5)
              .fill(1)
              .map((v, i) => (
                <span className={`step ${i < step ? "current" : ""}`} key={i}>
                  {i + 1}
                </span>
              ))}
          </div>
        </>
      )}
      {renderTitle()}
      <div className="block-main">
        <form onSubmit={handleSubmit(onSubmit)}>
          {renderStep()}
          {step !== 0 && (
            <hr
              style={{
                borderColor: "#f1666b",
              }}
            />
          )}
          <div className="button-footer">
            <button
              type="submit"
              className={`btn btn-primary next-btn ${
                step === 0 ? "left-align" : ""
              }`}
            >
              Next
            </button>
          </div>
        </form>
      </div>
      {step === 0 && (
        <div className="couple-img">
          <img src="/images/couple.png" />
        </div>
      )}
    </div>
  );
};

export default ProfileContent;
