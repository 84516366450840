import React from 'react';
import CustomNextBtn from '../components/CustomNextBtn';

const PurchaseMainBlock = props => (
  <div className='col-lg-8 float-left main purchse-main-block d-flex'>
    <div className='main-wrapper tab-block custom-style-height purchase-inner-menu-block'>
      {props.children}
      <CustomNextBtn />
    </div>
  </div>
);

export default PurchaseMainBlock;