import React from "react";
import CarouselContainer from "./CarouselContainer";
import { Route } from "react-router-dom";
import Loans from "../pages/Loans";
import Invest from "../pages/Invest";
import Purchase from "../pages/Purchase";
// import BuyOptions from "../pages/BuyOptions";
import Buy from "../pages/Buy";
import Personal from "../pages/Personal";
import Insurance from "../pages/Insurance";

const Main = () => (
  <>
    <Route path="/" exact component={CarouselContainer} />
    <Route path="/shop" exact component={Purchase} />
    <Route path="/shop/:item" exact component={Buy} />
    <Route path="/invest" exact component={Invest} />
    <Route path="/invest/:tabName" exact component={Invest} />
    <Route path="/loans" exact component={Loans} />
    <Route path="/personal" exact component={Personal} />
    <Route path="/insurance" exact component={Insurance} />
  </>
);

export default Main;
