import React, { useEffect, useState } from "react";
import BuyButton from "./BuyButton";
import fetchData from "../helpers/fetchData";
import { formatAmount } from "../helpers/common";
import { PURCHASE_LIST_API } from "../constants";
import get from "lodash/get";

const BuyList = ({ item, onBuyClick }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    fetchData(
      PURCHASE_LIST_API + "?parentCat=" + item,
      setData,
      setLoading
    );
  }, [item]);

  const itemoptions = get(data, "[0].items");

  return (
    <div className="tab-content">
      <div id="purchase-buy-inner" item={item} itemoptions={itemoptions}>
        <h2 className="buy-car-title">
          BUY A {item.toUpperCase()}
          <a href="/shop">
          <span>
              <p>BACK</p>
          </span>
          </a>
        </h2>
        <div className="accordion-wrapper">
          <div className="panel-group" id="accordion">
            <div className="panel panel-default">
              <div
                id="collapseOne"
                className="panel-collapse collapse"
                style={{
                  display: "block !important",
                }}
              >
                <div className="panel-body">
                  <div className="table-invesment">
                    <ul className="style-none" style={{ textAlign: "center" }}>
                      {itemoptions &&
                        itemoptions.map(
                          (each, index) =>
                            each.productId && (
                              <li key={`buylist-${index}`}>
                                <div className="inv-title">
                                  {each.displayName}
                                </div>
                                <div className="inv-price">
                                  {formatAmount(each.price, " ")}
                                </div>
                                <BuyButton
                                  productId={each.productId}
                                  handleClick={() =>
                                    onBuyClick(each, get(data, "[0].button"))
                                  }
                                />
                              </li>
                            )
                        )}
                      {loading && (
                        <img src={`/images/loader.svg`} alt="Loading..." />
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyList;
