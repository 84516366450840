/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from "react";

const InvestSimple = ({ isOpen, toggleModal, data, handleInvestClick }) => {
  const [validationError, setValidationError] = useState(false);
  const [displayGraph, setDisplayGraph] = useState(false);
  const [amount, setAmount] = useState("");

  const handleSubmission = () => {
    if (!amount || amount <= 0) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
    }
    handleInvestClick(amount);
    resetForm();
  };

  const handleClose = () => {
    toggleModal("none");
    resetForm();
  };

  const resetForm = () => {
    // reset to initial stage at time of close
    setAmount("");
    setValidationError(false);
  }

  const handleGraph = () => {
    setDisplayGraph(false);
  }

  return (
    <div
      className={`modal fade make-aninvest-tab-content ${isOpen ? "show" : ""}`}
      id="invest-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={displayGraph === true ?  handleGraph : handleClose}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <Fragment>
                {!displayGraph && (
                  <div className="invest-now-better-part1">
                    <h5 className="title-redeem">
                      INVEST NOW FOR A BETTER TOMORROW
                    </h5>
                    <div className="redeem-wrapper">
                      <div className="left-side-image float-left left-man-blue with-meter">
                        <img
                          src={`/images/${data.confImg}`}
                          alt="Investment_image"
                        />
                        <div className="with-riskometer">
                          <div className="text-riskometer">
                            RISK: {data.risk}
                          </div>
                          <img
                            src={`/images/${data.riskImg}`}
                            alt="image_risk"
                          />
                        </div>
                      </div>
                      <div className="right-side-redeem float-right equity-large-text">
                        <h3
                          className="subheading-name"
                          style={{ border: "none" }}
                        >
                          {data.displayName}
                        </h3>
                        <div className="in-content">
                          <div className="box-content">
                            <p
                              dangerouslySetInnerHTML={{ __html: data.info }}
                            />
                            <p>
                              {data.lockinYears > 0 && (
                                <Fragment>
                                  Lock in:{" "}
                                  <strong>{data.lockinYears} Years</strong>{" "}
                                  <br />
                                </Fragment>
                              )}
                            </p>
                            <p style={{ marginBottom: 0 }}>
                              Current NAV:
                              <br />₹{data.unitValue}/unit
                              {data.graphImg && (
                                <button
                                  type="button"
                                  className="btn btn-primary view-history"
                                  onClick={() => setDisplayGraph(true)}
                                >
                                  View History
                                </button>
                              )}
                            </p>
                          </div>
                        </div>
                        <hr style={{ borderColor: "#ffffff" }} />
                        <div className="redeem-form">
                          <h6 className="subtitle-redeem">
                            AMOUNT YOU'D <br />
                            LIKE TO INVEST
                          </h6>
                          <form>
                            <div className="form-content">
                              <div className="input-field-block">
                                <input
                                  type="input"
                                  placeholder="Enter amount here"
                                  className="input-redeem"
                                  value={amount}
                                  onChange={(e) => {
                                    setAmount(isNaN(Number(e.target.value)) ? ((amount === null) ? "" : amount) : e.target.value);
                                    setValidationError(false);
                                  }}
                                />
                                {validationError && (
                                  <em className="error-text">
                                    Please enter correct amount.
                                  </em>
                                )}
                              </div>
                            </div>
                            <div className="button-footer">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#equity-cap-confirm"
                                onClick={handleSubmission}
                              >
                                Invest
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={handleClose}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {data && data.parentCat === "Mutual Fund" && (
                      <div className="bottom-text-popup">
                        To know more about Mutual Funds, please{" "}
                        <a
                          href="https://www.mutualfundssahihai.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-line"
                        >
                          click here
                        </a>
                        .
                      </div>
                    )}
                  </div>
                )}
                {/* invest-now-better-part1 */}
                {displayGraph && (
                  <div className="invest-now-better-part2">
                    <h4 className="title-heading-name">
                      NAV Fluctuations & Volatility : {data.risk}
                    </h4>
                    <div className="graph img-width">
                      <img
                        src={`/images/graphs/${data.graphImg}`}
                        alt="Investment_image"
                      />
                    </div>
                    <div className="button-footer">
                      <button
                        type="button"
                        className="btn btn-primary back-button"
                        onClick={handleGraph}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                )}
                {/* invest-now-better-part2 */}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestSimple;
