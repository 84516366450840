import React from "react";

const ViewRepayBtn = ({ clickHandlr }) => (
  <div onClick={clickHandlr}>
    <span data-toggle="modal" data-target="#view-modal">
      <label className="invest-round view-btn-right">
        <span className="view-icon"></span>
        <span className="text-in text-medium">View</span>
      </label>
    </span>
  </div>
);

export default ViewRepayBtn;
