import React, {useState} from 'react';
import RedeemBtn from './RedeemBtn';
import { Accordion, Card } from 'react-bootstrap';
import { formatAmount } from '../helpers/common';

const CurrentInvestments = ({
  currInvestments,
  currInvloading,
  onRedeemBtnClick,
}) => {
  const [activeId, setActiveId] = useState(null);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <div id='investments' className='tab-pane fade in active show'>
      {currInvestments && currInvestments['totInv'] ? (
        <div className='inv-row'>
          <span className='text-medium text-inv'>
            Your investment and asset value is:
          </span>
          <span className='price'>
            {formatAmount(currInvestments['totInv'], ' ')}
          </span>
        </div>
      ) : (
        <div className='inv-row'>
          <span className='text-medium text-inv'>
            Currently you do not have any investments.
          </span>
          <span className='price'></span>
        </div>
      )}
      <Accordion>
        <div className='accordion-wrapper'>
          <div
            className='panel-group'
            style={{ textAlign: 'center' }}
          >
            {currInvestments &&
              Object.keys(currInvestments).map(
                key =>
                  Number.isInteger(parseInt(key)) && (
                    <div className='panel panel-default' key={`currinvest-div-${key}`}>
                      <Accordion.Toggle as={Card.Header} eventKey={key} onClick={() => toggleActive(key)}>
                        <div
                          className={`panel-heading accordion-toggle ${activeId === key ? '' : 'collapsed'}`}
                          data-toggle='collapse'
                        >
                          <div className='p-title'>
                            <span className='icon-s mf-i'></span>
                            <h4 className='panel-title'>
                              {currInvestments[key]['title']}
                            </h4>
                          </div>
                          <div className='price inv-price text-medium'>
                            {formatAmount(currInvestments[key]['totInv'], ' ')}
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <div>
                        <Accordion.Collapse eventKey={key}>
                          <div className='panel-body'>
                            <div className='table-invesment'>
                              <ul className='style-none'>
                                {currInvestments[key].items &&
                                  currInvestments[key].items.map(each => (
                                    <li key={each.productId}>
                                      <div
                                        className='inv-title'
                                        style={{ textAlign: 'left' }}
                                      >
                                        {each.displayName}
                                      </div>
                                      <div className='inv-price'>
                                        {formatAmount(each.curInvValue, ' ')}
                                      </div>
                                      {/* {each.displayName !== 'FD' && (  #137 */}
                                        <RedeemBtn
                                          amount={each.curInvValue}
                                          productId={each.productId}
                                          displayName={each.displayName}
                                          onClick={() => onRedeemBtnClick(each)}
                                        />
                                      {/* )} */}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    </div>
                  )
              )}
            {currInvloading && (
              <img src={`/images/loader.svg`} alt='Loading...' />
            )}
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default CurrentInvestments;
