import React, { useState, useEffect } from "react";
import { PERSONAL_FAMILY_API, PERSONAL_CAREER_API } from "../constants";
import { getData } from "../helpers/api";

export const PersonalContext = React.createContext();

const PersonalProvider = ({ children }) => {
  const [familyOpts, setfamilyOpts] = useState(null);
  const [familyOptsloading, setfamilyOptsloading] = useState(true);
  const [careerOpts, setcareerOpts] = useState(null);
  const [careerOptsloading, setcareerOptsloading] = useState(true);

  useEffect(() => {
    getData(PERSONAL_FAMILY_API)
      .then((data) => {
        setfamilyOpts(data);
        setfamilyOptsloading(false);
      });
    getData(PERSONAL_CAREER_API)
      .then((data) => {
        setcareerOpts(data);
        setcareerOptsloading(false);
      });
  }, []);

  return (
    <PersonalContext.Provider
      value={{
        familyOptsloading,
        familyOpts,
        careerOptsloading,
        careerOpts,
      }}
    >
      {children}
    </PersonalContext.Provider>
  );
};

export default PersonalProvider;
