import React, { useState, useEffect } from "react";
import fetchData from "../helpers/fetchData";
import {getData} from "../helpers/api";
import { PURCHASE_LIST_API, PURCHASED_LIST_API } from "../constants";

export const PurchaseContext = React.createContext();

const PurchaseProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [purchasedData, setPurchasedData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData(PURCHASE_LIST_API + "?parentCat=all", setData, setLoading);

    getData(PURCHASED_LIST_API, "data.assets").then((data) => {
      setPurchasedData(data);
      setLoading(false);
    });

  }, []);

  return (
    <PurchaseContext.Provider value={{ data, loading, purchasedData, setPurchasedData  }}>
      {children}
    </PurchaseContext.Provider>
  );
};

export default PurchaseProvider;
