/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { getEventsImg, isMobile } from "../helpers/common";

const Slide = ({
  data: { img, title, dispTxt, layerType, eventId, products, type, buttonTxt },
  handleBtnClick,
  handleSkipClick,
}) => {
  const imgSrc = getEventsImg(img);

  return (
    <div>
      <img src={imgSrc} alt="Banner" />
      <div
        className="banner-content"
        style={{ width: isMobile() ? "auto" : "", left: "5%" }}
      >
        <h1>{title}</h1>
        <p>{dispTxt}</p>
        <a
          onClick={() => handleBtnClick(eventId)}
          style={
            isMobile()
              ? { marginBottom: "10px" }
              : { marginLeft: "10px" }
          }
          className="btn"
        >
          {buttonTxt}
        </a>
        {type === "OPTION" && (
          <a
            onClick={() => handleSkipClick(eventId)}
            style={
              isMobile()
                ? {
                    marginBottom: "10px",
                    background: "#ffffff",
                    color: "#404041",
                  }
                : {
                    marginLeft: "10px",
                    background: "#ffffff",
                    color: "#404041",
                  }
            }
            className="btn"
          >
            Skip
          </a>
        )}
      </div>
    </div>
  );
};

export default Slide;
