/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from "react";
// import get from "lodash/get";

const InvestDepositsModal = ({
  isOpen,
  toggleModal,
  data,
  handleInvestClick,
}) => {
  const [validationError, setValidationError] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [amount, setAmount] = useState("");

  const handleSubmission = () => {
    if (!amount || amount <= 0) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
    }

    handleInvestClick({
      ...data.items[selectedOption],
      invAmount: amount,
    });
    resetForm();
  };

  const resetForm = () => {
    // reset to initial stage at time of close
    setSelectedOption(0);
    setAmount("");
    setValidationError(false);
  }

  const handleClose = () => {
    toggleModal("none");
    resetForm();
  };

  return (
    <div
      className={`modal fade make-aninvest-tab-content ${isOpen ? "show" : ""}`}
      id="invest-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <Fragment>
                <h5 className="title-redeem">
                  INVEST NOW FOR A BETTER TOMORROW
                </h5>
                <div className="redeem-wrapper">
                  <div className="left-side-image float-left left-man-blue">
                    <img
                      src={`/images/${data.items[selectedOption].confImg}`}
                      alt="Investment_image"
                    />
                  </div>
                  <div className="right-side-redeem float-right equity-large-text">
                    <h3 className="subheading-name" style={{ border: "none" }}>
                      {data.displayName}
                    </h3>
                    <div className="in-content">
                      <div className="box-content">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data.items[selectedOption].info,
                          }}
                        />

                        <p>
                          {data.items[selectedOption].lockinYears > 0 && (
                            <Fragment>
                              Lock in Years :{" "}
                              <strong>
                                {data.items[selectedOption].lockinYears}
                              </strong>{" "}
                              <br />
                            </Fragment>
                          )}
                        </p>
                      </div>
                    </div>
                    <hr style={{ borderColor: "#ffffff" }} />
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">ENTER DETAILS</h6>
                      <form>
                        <div className="form-content">
                          {data.items.length > 1 && (
                            <div className="form-group select-group">
                              <i className="arrow-select down" />
                              <select
                                className="form-control"
                                id="exampleSelect1"
                                value={selectedOption}
                                onChange={(e) => {
                                  setSelectedOption(e.target.value);
                                }}
                              >
                                {data.items.map((item, id) => (
                                  <option
                                    value={id}
                                  >{`${item.interestRate}% @ ${item.invYears} Years`}</option>
                                ))}
                              </select>
                            </div>
                          )}
                          <div className="input-field-block">
                            <input
                              type="input"
                              placeholder="Enter amount here"
                              className="input-redeem"
                              value={amount}
                              onChange={(e) => {
                                setAmount(isNaN(Number(e.target.value)) ? ((amount === null) ? "" : amount) : e.target.value);
                                setValidationError(false);
                              }}
                            />
                            {validationError && (
                              <em className="error-text">
                                Please enter correct amount.
                              </em>
                            )}
                          </div>
                        </div>
                        <div className="button-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#fd-confirm"
                            onClick={handleSubmission}
                          >
                            Invest
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestDepositsModal;
