/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useHistory, Redirect } from 'react-router-dom';
import { fetchSessionData, clearErrorStatus } from '../helpers/api';

const ErrorPage = () => {
  const history = useHistory();

  document.body.className = "welcome sign-in height-fix";

  const { errorMsg, nickName, errorBtnTxt } = fetchSessionData()

  const btnClick = (type) => {
    if(type === 'TRY AGAIN') history.goBack();
    else if (type === 'HOME') window.location.replace("/")
    else if (type === 'LOGIN') window.location.replace("https://idfcgameoflife.idfc.com/")
    else window.location.replace("https://idfcgameoflife.idfc.com/")
      //window.location.replace("/start");
  }

  // If user not coming from a valid redirect, send him to the previous location
  const renderRedirect = () => {
    const { isLoggedIn, errorStatus } = fetchSessionData()
    //if (!isLoggedIn) window.location.replace("/start");
    if (!isLoggedIn) window.location.replace("https://idfcgameoflife.idfc.com/");
    else if (!errorStatus) history.goBack();
    else clearErrorStatus();
  }

  return (
    <>
    {renderRedirect()}
    <div className="container">
        <div className="row">
          <div  className="col-lg-12 welcome-wrapper">
            <div className="welcome-content">
              <div className="logo-idfc"><a href="/start"><img src="images/idfc-logo-welcome.png"/></a></div>
              <div className="wrapper-box">
                <div className="image-full signin-bkg">
                  <img src="images/signin-bkg.png"  className="for-desktop"/>
                  <img src="images/signin-bkg2.png" className="for-mobile"/>
                </div>
                <div className="sign-in-content">
                  <h2 className="heading-name">Hello {nickName},</h2>
                  <label className="title-label">
                    {errorMsg} <br/>
                  </label>
                  <hr style={{borderColor: "#f1666b"}}/>
                  <div className="input-field-block si-input button-flex d-flex bt-border">
                  <button type="button" className="btn btn-primary next-btn mg-right" style={{ cursor: "pointer" }} onClick={() => btnClick(errorBtnTxt)}>{errorBtnTxt}</button>

                  {/* Show an option to go back to home if TRY AGAIN button is there */}
                  {(errorBtnTxt === "TRY AGAIN") &&
                    <button type="button" className="btn btn-primary next-btn mg-right" style={{ cursor: "pointer" }} onClick={() => btnClick("HOME")}>HOME</button>
                  }
                  </div>
                  <hr style={{borderColor: "#f1666b"}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </>
  );
};

export default ErrorPage;