import React from "react";

const BuyButton = ({ handleClick }) => {
  return (
    <div className="invest-right" onClick={handleClick}>
      <span data-toggle="modal" data-target="#purchase-modal">
        <label className="invest-round">
          <span className="invest-i-right"></span>
          <span className="text-in">BUY</span>
        </label>
      </span>
    </div>
  );
};

export default BuyButton;
