import React from "react";
import Main from "../components/Main";
import LeftNavBlock from "../components/LeftNavBlock";
import Profile from "../components/Profile";
import Header from "../components/Header";
import Container from "react-bootstrap/Container";
import HomeProvider from "../providers/HomeProvider";
import InvestProvider from "../providers/InvestProvider";
import { isMobile } from "../helpers/common";

const Home = () => (
  <>
    <Header />
    <div className="home">
      <section className="section-wrapper">
        <Container>
          <HomeProvider>
          <InvestProvider>
            <div
              className="mobile-row"
              style={{ margin: !isMobile() ? "0 -15px" : "" }}
            >
              <Profile />
              <div className="col-lg-9 d-flex left-main-block">
                <LeftNavBlock></LeftNavBlock>
                <Main />
              </div>
            </div>
            </InvestProvider>
          </HomeProvider>
        </Container>
      </section>
    </div>
  </>
);

export default Home;
