import React, {useState}from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { fetchSessionData, logout } from "../helpers/api";

const Header = () => {
  const { nickName } = fetchSessionData();
  const [clicked, setClicked] = useState(false);

  const navClick = (type) => {
    if(type==="open") setClicked(true);
    else setClicked(false);
  }
  return (
  <header id="header">
    <Container>
      <div className="header-corner d-flex align-items-center justify-content-between">
        <div className="logo float-left">
          <Link to="/">
            <img
              src="/images/IDFC-logo.png"
              alt="IDFC Logo"
              className="logo-img"
            />
          </Link>
        </div>
        <div className="text-subtitle">THE IDFC GAME OF LIFE</div>
        <div className="nav-right-icon float-right" onClick={() => navClick('open')}>
          <div className="bar1" />
          <div className="bar2" />
          <div className="bar3" />
        </div>
        <div className="right-corner-bottom" />
      </div>

      <div className={clicked === true ? "flyout-menu" : "flyout-menu d-none"}>
        <div className="d-table flyout-height">
        <span className="close-icon" onClick={() => navClick('close')}>
          <img src="/images/close-icon.png" width="30px" alt=""/>
        </span>
        <div className="flyout-wrapper">
          <h3>Hi {nickName},</h3>
          <div className="signout">
            <a href='/game-tour'>View Guide</a>
            <br/>
            <br/>
            <a href='https://idfcgameoflife.idfc.com' onClick={logout}>Sign Out</a>
          </div>
          <p><em>
						To know more about Mutual Funds, please click <a href="https://www.mutualfundssahihai.com/"
                          target="_blank" rel="noopener noreferrer" className="text-link">here</a>
					</em></p>
          <div className="share-icons align-middle">
            <label>Share :</label>
            <div className="d-flex social-icons">
              <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.game-of-life.com%2F&amp;src=sdkpreparse" className="mail" target="_blank" rel="noopener noreferrer">Mail</a>
              <a href="https://api.whatsapp.com/send?phone=91XXXXXXXXXX&text=Play%20this%20amazing%20game%20%20on%20https://idfcgameoflife.idfc.com/" className="fb" target="_blank" rel="noopener noreferrer">Mail</a>
               <a href="https://twitter.com/intent/tweet?text=Play%20this%20amazing%20game%20%20on%20https://idfcgameoflife.idfc.com/" className="tw" target="_blank" rel="noopener noreferrer">Mail</a>
              <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://idfcgameoflife.idfc.com&title=CheckouttheIDFCGameOf Life&summary=idfcgameoflife.idfc.com&source=idfcgameoflife.idfc.com" className="in" target="_blank" rel="noopener noreferrer">Mail</a>
             
            </div>
          </div>
				</div>
				<div className="flyout-bottom">
          <em>5Secrets Simulation <br />
            Software is Licensed to <br />
            IDFC AMC LTD by <br />
            POANCHO Solutions</em>
				</div>
        </div>
	    </div>
    </Container>
  </header>
)};

export default Header;
