/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const DesktopPersonContainer = ({ clickHandlr }) => (
  <div className="right-bottom-man">
    <img src="/images/man-img.png" />
    <div
      className="tag-box"
      style={{ cursor: "pointer" }}
      onClick={clickHandlr}
    >
      CLICK HERE FOR A PRO TIP FROM BUGS!
    </div>
  </div>
);

export default DesktopPersonContainer;
