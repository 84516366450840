import React from 'react';
import InvestMainBlock from '../components/InvestMainBlock';

const Invest = props => (
  <>
  <InvestMainBlock tabName={props.match.params.tabName} />
  </>
);

export default Invest;
