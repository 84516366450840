import React, { useContext, useEffect, useState } from "react";
import { formatAmount } from "../helpers/common";
import get from "lodash/get";
import fetchData from "../helpers/fetchData";
import { postData, getData, redirectToErrorPage } from "../helpers/api";

import { LoansContext } from "../providers/LoansProvider";
import { HomeContext } from "../providers/HomeProvider";
import LoanConfirmModal from "../components/modals/LoanConfirmModal";
import {
  TAKE_LOAN_API,
  DASHBOARD_DETAILS_API,
  USER_LOANS_API,
} from "../constants";
import LoanSactionedModal from "./modals/LoanSanctionedModal";

const TakeLoan = () => {
  const { loanFormData, isLoanFormDataLoading } = useContext(LoansContext);
  const { setData, setLoading } = useContext(HomeContext);
  const [emiYears, setEmiYears] = useState();
  const [loanAmount, setLoanAmount] = useState();
  const [emiAmount, setEmiAmount] = useState(0);
  const [validationError, setValidationError] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [productId, setProductId] = useState();
  const [modalData, setModalData] = useState(null);
  const [apiResponse, setApiData] = useState(null);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const { setLoanOptions, setloanOptionsLoading } = useContext(LoansContext);

  useEffect(() => {
    calculateEMi();
  }, [loanAmount, emiYears]);

  const calculateEMi = () => {
    if (loanFormData) {
      if (emiYears && loanAmount) {
        const { emiFactor, loans } = loanFormData;
        setProductId(loans.productId);
        const multFactor = emiFactor.filter((e) => e.years === emiYears);
        if (multFactor.length > 0) {
          // Round off emi upto two decimals
          const emi = Number((multFactor[0]["multFactor"] * loanAmount).toFixed(2));
          setEmiAmount(formatAmount(emi, " "));
        }
      } else {
        setEmiAmount(0);
      }
    }
  };

  const handleSubmit = () => {
    if (emiYears && loanAmount) {
      setValidationError(false);
      setActiveModal("confirm");
      setModalData({
        ...modalData,
        emiYears,
        emiAmount,
        loanAmount,
        productId,
      });
    } else {
      setValidationError(true);
      return;
    }
  };

  const clearAll = () => {
    setEmiYears("");
    setLoanAmount("");
    setEmiAmount(0);
  }

  const handleModalConfirm = () => {
    setConfirmLoader(true);
    const { emiYears, productId, loanAmount } = modalData;
    const finalData = {
      mode: "emi",
      amount: loanAmount,
      emiYears,
      productId,
    };
    postData(TAKE_LOAN_API, finalData)
      .then((res) => {
        setApiData(res);
        if (get(res, "status") === "success") {
          setActiveModal("sactioned");
          fetchData(DASHBOARD_DETAILS_API, setData, setLoading);

          getData(USER_LOANS_API, "data.items").then((data) => {
              setLoanOptions(data);
              setloanOptionsLoading(false);
            });
        }
        setConfirmLoader(false);
        clearAll();
      })
      .catch((err) => {
        setApiData(err);
        setConfirmLoader(false);
        // If error type is not object or message key is not there
        // we assume that the error is not generate from the backend
        if (typeof err !== 'object' || !('message' in err)) {
          redirectToErrorPage("Something went wrong while taking a loan!", "TRY AGAIN");
        }
      });
    
  };

  return (
    <>
      <div id="take-loan" className="tab-pane">
        <div
          className="bkg-gray repay-wrap"
          style={{ textAlign: isLoanFormDataLoading ? "center" : "" }}
        >
          {loanFormData && (
            <div className="form-repay">
              <h6 className="title-repay">ENTER DETAILS FOR LOAN REQUEST</h6>
              <form>
                <div className="repay-form">
                  <div className="input-field-block">
                    <input
                      type="input"
                      placeholder="Please enter amount"
                      className="input-redeem"
                      name="amt"
                      value={loanAmount?loanAmount:""}
                      onChange={(e) => {
                        setLoanAmount(isNaN(Number(e.target.value)) ? ((loanAmount == null) ? "" : loanAmount) : e.target.value);
                        setValidationError(false);
                      }}
                    />
                    <div className="form-group select-group">
                      <i className="arrow-select down"></i>
                      {loanFormData.loans && (
                        <select
                          className="form-control"
                          id="exampleSelect1"
                          onChange={(e) => {
                            setEmiYears(e.target.value);
                            setValidationError(false);
                          }}
                          value={emiYears}
                        >
                          <option value="">Select Years</option>
                          {loanFormData.loans.emiYears.split("|").map((val) => (
                            <option value={val} key={val}>{val} years</option>
                          ))}
                        </select>
                      )}
                    </div>
                    {validationError && (
                      <em className="error-text">
                        Please enter loan amount and select EYI years.
                      </em>
                    )}
                    <div className="emi-block">
                      <h6 className="head-bold">Yearly Installments (EYI):</h6>
                      <span>{emiAmount}</span>
                    </div>
                  </div>
                </div>
                <div className="button-footer">
                  <button
                    type="button"
                    className="btn btn-primary yes-redeem"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#loan-confirm"
                    onClick={handleSubmit}
                  >
                    REQUEST LOAN
                  </button>
                </div>
              </form>
            </div>
          )}
          {isLoanFormDataLoading && (
            <img src={`/images/loader-grey.svg`} alt="Loading..." />
          )}
        </div>
      </div>
      <LoanConfirmModal
        isOpen={activeModal === "confirm"}
        data={modalData}
        toggleModal={setActiveModal}
        handleConfirm={handleModalConfirm}
        apiResponse={apiResponse}
        setApiData={setApiData}
        confirmLoader={confirmLoader}
        setConfirmLoader={setConfirmLoader}
      />
      <LoanSactionedModal
        isOpen={activeModal === "sactioned"}
        toggleModal={setActiveModal}
        data={modalData}
      />
    </>
  );
};

export default TakeLoan;
