/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from "react";
import { authUser, logout } from "../helpers/api";
import CubeLoader from "../components/CubeLoader";

const Start = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  document.body.className = "welcome sign-in height-fix";

  useEffect(() => {
    authUser(setLoading, setData, location);
  }, []);

  const gameClick = (type) => {
    if(type==='CONTINUE') window.location.replace("/");
    else window.location.replace("/profile");
  }

  return (
    <div className="container">
      {!loading && <CubeLoader />}
      {data && loading &&
        <div className="row">
          <div  className="col-lg-12 welcome-wrapper">
            <div className="welcome-content">
              <div className="logo-idfc"><a href="/start"><img src="images/idfc-logo-welcome.png"/></a></div>
              <div className="wrapper-box">
                <div className="image-full signin-bkg">
                  <img src="images/signin-bkg.png"  className="for-desktop"/>
                  <img src="images/signin-bkg2.png" className="for-mobile"/>
                </div>
                <div className="sign-in-content">
                  <h2 className="heading-name">Hello {data.nickName}</h2>
                  <label className="title-label">
                    {data.txt}
                  </label>
                  <label className="title-label">{data.txt1}</label>
                  <hr style={{borderColor: "#f1666b"}}/>
                  <div className="input-field-block si-input button-flex d-flex bt-border">
                    <button type="button" className="btn btn-primary next-btn mg-right" style={{ cursor: "pointer" }} onClick={() => gameClick('CONTINUE')}>Continue</button>
                    <button type="button" className="btn btn-primary next-btn" style={{ cursor: "pointer" }} onClick={() => gameClick('RESTART')}>Restart</button>
                  </div>
                  <hr style={{borderColor: "#f1666b"}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Start;