/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import get from 'lodash/get';
import { formatAmount } from "../../helpers/common";

const RedeemConfirm = ({
  data,
  isOpen,
  toggleModal,
  handleConfirmClick,
  apiResponse,
  setApiData,
  selectedValue,
  confirmLoader,
  setConfirmLoader,
}) => {
  const handleClose = () => {
    toggleModal('none');
    setApiData(null);
    setConfirmLoader(false);
  };

  return (
    <div
      className={`modal fade current-investments-tab-content ${
        isOpen ? 'show' : ''
      }`}
      id='redeem-confirm'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      aria-hidden='true'
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <a
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={handleClose}
          >
            <span aria-hidden='true'>×</span>
          </a>
          <div className='modal-body'>
            <div className='redeem-confirmation'>
              <div className='redeem-wrapper align-middle'>
                <div className='left-side-image float-left'>
                  <img src='images/redeem-img2.png' alt='redeem' />
                </div>
                <div className='right-side-redeem float-right'>
                  <div className='redeem-form'>
                    <h6 className='subtitle-redeem'>CONFIRM REDEMPTION</h6>
                    <h3 className='subheading-name'>
                      {data && data.displayName}
                    </h3>
                    <p>{String(formatAmount(selectedValue))}</p>
                    <p>
                      {data &&
                        apiResponse &&
                        get(apiResponse, 'status') !== 'success' && (
                          <em style={{ color: 'black' }}>
                            {apiResponse.message}
                          </em>
                      )}
                    </p>
                    <div className='button-footer'>
                      <button
                        type='button'
                        className={`btn btn-primary ${
                          apiResponse && apiResponse.status === 'failed'
                            ? 'op-0'
                            : ''
                        }`}
                        data-dismiss='modal'
                        data-toggle='modal'
                        data-target='#redeem-thankyou'
                        onClick={handleConfirmClick}
                      >
                        CONFIRM
                      </button>
                      <button
                        type='button'
                        className='btn btn-secondary'
                        data-dismiss='modal'
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align='middle'>
            <img             
              className={confirmLoader ? '' : 'hide'}
              src={`/images/loaderbg.gif`}
              alt='Loading...'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemConfirm;
