/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const MobileBottom = ({ clickHandlr }) => (
  <div className='bottom-mobile for-mobile'>
    <img
      src='/images/man-img-mobile.gif'
      width='100px'
      style={{ cursor: 'pointer' }}
      onClick={clickHandlr}
    />
  </div>
);

export default MobileBottom;
