import React from "react";

import { formatAmount } from "../../helpers/common";

const RepayThankYouModal = ({ data, isOpen, toggleModal }) => {
	let amtPaid="";
	if(data){
		if(data.emiAmount)  amtPaid =data.emiAmount;
		if(data.entireAmount===true)  amtPaid =data.balance;
	}
  	return (
    <div
      className={`modal fade repay-your-loans-modal-content ${
        isOpen ? "show" : ""
      }`}
      id="redeem-thankyou"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <div className="redeem-success">
                <div className="redeem-wrapper align-middle">
                  <div className="left-side-image float-left">
                    <img src="images/repay-success.png" alt="repay-success" />
                  </div>
                  <div className="right-side-redeem float-right">
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">SUCCESS!</h6>
                      <p>
                        You have successfully repaid {data.displayName} Loans amounting to {" "}
                        {formatAmount(amtPaid, " ")}
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!data && <h5 className="title-redeem">Something Went Wrong!!</h5>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepayThankYouModal;
