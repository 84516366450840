/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  formatAmount,
  getEmiValues,
  getCcbankValues,
} from "../../helpers/common";
import { MODES_LABEL } from "../../constants";

const PurchaseModal = ({
  data,
  buttonText,
  isOpen,
  toggleModal,
  handleBuyClick,
}) => {
  const [selectedOption, setselectedOption] = useState(null);
  const [selectedEmi, setselectedEmi] = useState(5);
  const [emiYears, setEmiYears] = useState();

  const [selectedCcBank, setselectedCcBank] = useState("25:75");
  const [validationError, setValidationError] = useState(false);

  const modes = data && data.modes.split("|");

  const handleSubmission = () => {
    if (!selectedOption) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
    }
    handleBuyClick(selectedOption, selectedEmi, selectedCcBank);
    resetForm();
  };

  const resetForm = () => {
    // reset to initial stage at time of close
    setselectedOption(null);
    setselectedEmi(5)
    setValidationError(false);
  }

  const handleClose = () => {
    toggleModal("none");
    resetForm();
  };
  return (
    <div
      className={`modal fade purchase-tab-content ${isOpen ? "show" : ""}`}
      id="purchase-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      role="dialog"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <>
                <h5 className="title-redeem">It'S TIME TO SHOP!</h5>
                <div className="redeem-wrapper">
                  <div className="left-side-image float-left">
                    <img src="/images/buy-img.png" alt="buy-img" />
                  </div>
                  <div className="right-side-redeem float-right">
                    <h3 className="subheading-name name-obj">
                      {`${data.parentCat} - ${data.displayName}`}
                    </h3>
                    <h3 className="subheading-name name-value-obj">
                      Value: {formatAmount(data.price, " ")}
                    </h3>
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">BUY USING</h6>
                      <form>
                        <div className="redeem-radio form-content buy-using">
                          {Object.keys(modes).map((key) => (
                            <div
                              className="custom-control custom-radio custom-control-inline part-of-inv"
                              key={key}
                            >
                              <input
                                type="radio"
                                id={modes[key]}
                                name="buyuse"
                                value={modes[key]}
                                className="custom-control-input"
                                checked={selectedOption === modes[key]}
                                onChange={(e) => {
                                  setselectedOption(e.target.value);
                                  setValidationError(false);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={modes[key]}
                              >
                                <span>{MODES_LABEL[modes[key]]}</span>
                              </label>
                              {selectedOption === "paylater" &&
                                modes[key] === "paylater" && (
                                  <div className="paylater">
                                    <p className="note-text">
                                      Please note:
                                      <br />
                                      You will have to clear your Pay Later
                                      balance at the end of the stage.
                                    </p>
                                  </div>
                                )}
                              {selectedOption === "ccbank" &&
                                modes[key] === "ccbank" && (
                                  <div className="creditcard">
                                    <div className="form-group select-group">
                                      <i className="arrow-select down" />
                                      <select
                                        className="form-control"
                                        id="exampleSelect1"
                                        value={selectedCcBank}
                                        onChange={(e) =>
                                          setselectedCcBank(e.target.value)
                                        }
                                      >
                                        {data.ccBank.split("|").map((perc) => (
                                          <option value={perc} key={perc}>
                                            Bank {perc.split(":")[0]}% + C.Card{" "}
                                            {perc.split(":")[1]}%
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <p className="text-monthly-emi">
                                      Bank:{" "}
                                      {
                                        formatAmount(getCcbankValues(
                                          data.ccBank,
                                          data.price,
                                          "bank"
                                        )[selectedCcBank])
                                      }{" "}
                                      pa <br />
                                      Credit card:{" "}
                                      {
                                        formatAmount(getCcbankValues(
                                          data.ccBank,
                                          data.price,
                                          "cc"
                                        )[selectedCcBank])
                                      }{" "}
                                      pa
                                    </p>
                                  </div>
                                )}
                              {selectedOption === "emi" &&
                                modes[key] === "emi" && (
                                  <div className="emivalue">
                                    <div className="form-group select-group">
                                      <i className="arrow-select down" />
                                      <select
                                        className="form-control"
                                        id="exampleSelect2"
                                        value={selectedEmi}
                                        onChange={(e) =>
                                          setselectedEmi(e.target.value)
                                        }
                                      >
                                        {data.emiYears
                                          .split("|")
                                          .map((yearValue) => (
                                            <option value={yearValue} key={yearValue}>
                                              {yearValue} years
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <p className="text-monthly-emi">
                                      Yearly EYI:{" "}
                                      {formatAmount(getEmiValues(data.emiValue)[selectedEmi])}
                                    </p>
                                  </div>
                                )}
                            </div>
                          ))}
                          {validationError && (
                            <em className="error-text">
                              Please select a Payment Mode.
                            </em>
                          )}
                        </div>
                        <div className="button-footer">
                          <button
                            type="button"
                            className="btn btn-primary yes-redeem"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#redeem-confirm"
                            onClick={handleSubmission}
                          >
                            <span style={{ textTransform: "capitalize" }}>
                              {buttonText}
                            </span>
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!data && <h5 className="title-redeem">Something Went Wrong!!</h5>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseModal;
