/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import ProfileContent from "../components/ProfileContent";
import ProfileProvider from "../providers/ProfileProvider";

const Profile = () => (
  <div className="profile-page">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 display-table">
          <div className="profile-wrapper">
            <div className="logo-idfc">
              <a href="/">
                <img src="/images/idfc-logo-welcome.png" />
              </a>
            </div>
            <div className="profile-main">
              <ProfileProvider>
                <ProfileContent />
              </ProfileProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Profile;
