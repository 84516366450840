import axios from "axios";
import get from "lodash/get";
import { AUTHENTICATION_API,AUTHENTICATION_API1,FAIL_URL } from "../constants";
import { AUTHENTICATION_API_SOC,AUTHENTICATION_API_SOC1 } from "../constants";
// Initial session data
const initialSessionData = {
  isLoggedIn: false,
  userId: null,
  token: null,
  nickName: null,
  uid:null,
endStatus: false,
  errorStatus: false,
  errorMsg: null,
  errorBtnTxt: null,
  termsCondition: false,
};


const defaultError = {
  status: "failed",
  message: "Something Went Wrong",
};

const authUser = (setLoading, setData, location) => {
  const search = location.search;
  const params = new URLSearchParams(search);
// console.log('dddd');
//console.log(params.get('code'));
//console.log(params.get('state'));
var api=AUTHENTICATION_API_SOC;
var failUrl = FAIL_URL;
  if(params.get('state')=='fbx2916f04f1c86bc0c80a0f278f8b758aa'){
    api=AUTHENTICATION_API_SOC1;
    if(params.get('error')){
     // window.location.replace(failUrl+'?msg=Invalid Login. Please try again.');
     return;
    }
  }
  if(params.get('uid')){
    api=AUTHENTICATION_API1;
    if(params.get('error')){
      window.location.replace(failUrl+'?msg=Invalid Login. Please try again.');
    }
  }
//console.log(auth_params);
var config1 ='';
var auth_params;
if(params.get('code')){//for soc
if(params.get('state'))
    var state=params.get('state');
else state='ghdjhs76hgsb';

   auth_params = {
    headers: { 'Authorization':  params.get('code'),uid:state} ,
    params : { code:params.get('code')}
  };
  //,state:params.get('state') 
}
else{//for directlogins
  auth_params = {
    headers: { 'Authorization':  params.get('ky'), 'uid':params.get('uid')}      
    };     
}
 axios
  .get(api, auth_params,config1)
  .then((res) => res.data)
  .then((res) => {
    //console.log('resdata');
    //console.log(res);
    if(get(res, "status") === "success"){
      /*login(res);
      if(res.btnTxt==="START") window.location.replace("/profile");
      else {
        setLoading(true);
        setData(res);
      }*/
    //----------------------------------
         const auth_params1 = {
      params : { id: res.uid , ky: res.ky, nm: res.nm,code:res.code }
    };

     axios
    .get(AUTHENTICATION_API, auth_params1)
    .then((res) => res.data)
    .then((res) => {
      //console.log(res);
      if(get(res, "status") === "success"){
        login(res);
       
        if(res.btnTxt==="START") window.location.replace("/profile");
        else {
          setLoading(true);
          setData(res);
        }
      }
      else {
        window.location.replace(failUrl+'?msg=Invalid Login');
      }
    })
    .catch((res) => {
     //console.log(res);
     window.location.replace(failUrl+'?msg=Please try again.');
     /// window.location.replace("https://idfcgameoflife.idfc.com/");
    });
    //---------------------------------------------
    }
    else{ 
      //console.log('failedxx');
      //window.location.replace(res.failUrl);
    //  window.location.replace(failUrl+'?msg=Invalid Login');
    }
  })
  .catch((res) => {
   //console.log(res);
  // window.location.replace(failUrl+'?msg=Invalid Login');
   /// window.location.replace("https://idfcgameoflife.idfc.com/");
  });

/*else{
    const auth_params = {
      params : { id: params.get('uid') , ky: params.get('ky') }
    };
  
        var config1 = {
  headers: { 'Authorization':  params.get('ky'),
   'uid':params.get('uid')}};
      
      console.log('here===');

   axios
   .get(AUTHENTICATION_API, config1)
  //.get(AUTHENTICATION_API, auth_params,config1)
  .then((res) => res.data)
  .then((res) => {
    console.log(res);
    if(get(res, "status") === "success"){
      login(res);
      if(res.btnTxt==="START") window.location.replace("/profile");
      else {
        setLoading(true);
        setData(res);
      }
    }
    else{
     // window.location.replace(failUrl+'?msg=Invalid Login');
    }
    // window.location.replace(res.failUrl);
  })
  .catch((res) => {
   console.log(res);
  // window.location.replace(failUrl+'?msg=Invalid Login');
   /// window.location.replace("https://idfcgameoflife.idfc.com/");
  });*/
//}
 

}

const getApiUrl = (api, userId) => {
  return api.includes("?")
    ? `${api}&userId=${userId}`
    : `${api}?userId=${userId}`;
};

const getData = (api, responseIndex = "data.data") =>
  new Promise((resolve, reject) => {
    const { isLoggedIn, userId, token, termsCondition } = fetchSessionData();
    //console.log(localStorage.getItem("sessionData"));
    // When 'session is not set' or it is set but user is logged out
    if (!localStorage.getItem("sessionData") || (localStorage.getItem("sessionData") && !isLoggedIn)) {
      window.location.replace("/start");
    }

    // If user is logged-in but terms are not accepted
    else if (isLoggedIn && !termsCondition) window.location.replace("/onboard");

    // If user is logged-in but 'token' or 'userId' are set to null
    else if (token == null || userId == null) 
      redirectToErrorPage("Please login to play the game!", "LOGIN");
    else {
      
      

      axios
        .get(getApiUrl(api, userId), config)
        .then((result) => {
          const data = get(result, responseIndex);
          //console.log('here'+data);
          //console.log(data);
          resolve(data);
          })
        .catch((error) => {
          reject({
            ...defaultError,
            error,
          });
          
        // redirectToErrorPage("", "TRY AGAIN");
        }
        );
    }
  });

const postData = (api, data) =>
  new Promise((resolve, reject) => {
    const { isLoggedIn, userId } = fetchSessionData();
    //console.log('isLoggedIn'+isLoggedIn);
    if (!isLoggedIn){

      window.location.replace("https://idfcgameoflife.idfc.com/");
      //redirectToErrorPage("Your session has expired!", "LOGIN");
    } //
    else {
      const finalData = {
        ...data,
        userId
      };
      // handling explicitly for buy insurance as there is an issue from service
      if (api.includes("insurance?type=buy")) api = getApiUrl(api, userId);

      axios
        .post(api, finalData, config)
        .then((res) => resolve(res.data))
        .catch((res) => reject(get(res, "response.data")));
    }
  });

const saveSessionData = (data) => {
  localStorage.setItem("sessionData", JSON.stringify(data));
};

const fetchSessionData = () => {
  const sessionData = localStorage.getItem("sessionData");
  //console.log(sessionData+'sessionData');
  return sessionData ? JSON.parse(sessionData) : initialSessionData;
};

const login = (data) => {
  const { userId, nickName, token,id,termsCondition } = data;
  saveSessionData({
    userId,
    nickName,
    token,
    id,
    isLoggedIn: true,
    endStatus: false,
    errorStatus: false,
    termsCondition
  });
};

const logout = () => {
  saveSessionData(initialSessionData);
};

// Set endStatus to true and redirect user to the final page
const finishGame = (url) => {
  const { isLoggedIn, userId, nickName, token,id } = fetchSessionData();
  saveSessionData({
    userId,
    nickName,
    token,
	id,
    isLoggedIn,
    endStatus: true,
    errorStatus: false,
    termsCondition: false,
  });
  window.location.replace(url);
}

// Set errorMsg for error page and set status to true
const redirectToErrorPage = (errorMsg, errorBtnTxt) => {
  const { isLoggedIn, userId, nickName, token,id, endStatus,  termsCondition} = fetchSessionData();
//console.log('isloggedin'+isLoggedIn);
  if (errorMsg === "") {
    window.location.replace("https://idfcgameoflife.idfc.com/");
   //  return;
    //window.location.replace('https://idfcgameoflife.idfc.com');
    //errorMsg  = "Oops! Something went wrong, please check your network connection or try again."
  }
  //console.log('isLoggedIn'+isLoggedIn);
  if (errorBtnTxt === null || errorBtnTxt === "") {
    errorBtnTxt  = "TRY AGAIN"
  }

  saveSessionData({
    userId,
    nickName,
    token,
	id,
    isLoggedIn,
    endStatus,
    termsCondition,
    errorStatus: true,
    errorMsg: errorMsg,
    errorBtnTxt: errorBtnTxt,
  });

  window.location.replace('/oops');
}

const clearErrorStatus = () => {
  const { isLoggedIn, userId, nickName, token, id, endStatus, termsCondition } = fetchSessionData();
  saveSessionData({
    userId,
    nickName,
    token,
	id,
    isLoggedIn,
    endStatus,
    termsCondition,
    errorStatus: false,
  })};

const AcceptTerms = () => {
  const { isLoggedIn, userId, nickName, token, id, endStatus, errorStatus } = fetchSessionData();
  saveSessionData({
    userId,
    nickName,
    token,
	id,
    isLoggedIn,
    endStatus,
    errorStatus,
    termsCondition: true,
  })};

const config = {
  headers: { 'Authorization': `Bearer ${fetchSessionData().token}`,
   'uid': `${fetchSessionData().userId}`}
};

export { postData, getData, fetchSessionData, finishGame, login, logout, authUser, config, redirectToErrorPage, saveSessionData, clearErrorStatus, AcceptTerms };