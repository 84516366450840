/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from "react";
import { STAGE_TRANSITION_POST_API, EVENTS_API } from "../constants";
import { postData, finishGame } from "../helpers/api";
// import fetchData from "../helpers/fetchData";
import EventOopsModal from "./modals/Events/EventsOops";
import { HomeContext } from "../providers/HomeProvider";
import { useHistory } from 'react-router-dom';

const CustomNextBtn = () => {
    // const [data, setData] = useState(null);
    // const [loading, setLoading] = useState(true);
    const { data: stage_data } = useContext(HomeContext);
    const {eventData: data, setEventData: setData, loading: loading, setLoading: setLoading } = useContext(HomeContext);
    const [activeModal, setActiveModal] = useState("none");
    const [modalData1, setModalData1] = useState(null);
    const [deactiveButton, setDeactiveButton] = useState(false);

    const stageBtnTxt = stage_data && stage_data.currentStage === stage_data.totStage ? "Finish The Game" : "Go To Next Stage";
    const stageBtnIcon = stage_data && stage_data.currentStage === stage_data.totStage ? "icon-trophy" : "icon-gotonextstage";

    // useEffect(() => {
    //     fetchData(EVENTS_API, setData, setLoading);
    // }, []);
	const history = useHistory();
    const handleNextStageBtnClick = async() => {
    if (data) history.push("/");
    else {
            setDeactiveButton(true);
            const res = await postData(STAGE_TRANSITION_POST_API, {});
            if (res.status=== "failed") {
            setModalData1(res);
                setActiveModal("oops");
            }
            else{
        if(stage_data.currentStage < stage_data.totStage) history.push("/stage-transition");

                // Game Win/Lost condition for last stage
                else if(stage_data.currentStage === stage_data.totStage){
          if (res.gameStatus==="GAMEWON") finishGame('/game-won');
          else if (res.gameStatus==="GAMELOST") finishGame('/game-lost');
                }
            } 
        }
    };

    return (
        <>
        <div>
            <a
                href
                data-toggle="modal"
                onClick={handleNextStageBtnClick}
                data-target="#oops-popup"
                className={`next-stage-btn custom-style-btn ${deactiveButton ? "op-0":""} ${data ? "bkg-lightpurple":""}`}
                style={{ cursor: "pointer" }}
            >
                <span>
                <img src={`/images/${data ? "icon-viewpendingtasks" : stageBtnIcon}.png`} class={`icon-left ${data ? "icon-view-star" : ""}`} />
                {data ? "View Pending Tasks": stageBtnTxt}
                </span>
            </a>
        </div>
        <EventOopsModal
        isOpen={activeModal === "oops"}
        toggleModal={setActiveModal}
        stageData={stage_data}
        data={modalData1}
        />
        </>
    );
}

export default CustomNextBtn;
