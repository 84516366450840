import React from 'react';

const MascotTipModal = ({
  isOpen,
  closeMascotModal,
  data,
  // handleMascotClose,
}) => {
  const closeModal = () => {
    // handleMascotClose();
    closeMascotModal('none');
  };

  return (
    <div
      className={`modal fade bugsoverlay-popup ${isOpen ? 'show' : ''}`}
      id='bugsoverlay-popup'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      style={{ display: isOpen ? 'block' : 'none', paddingRight: '12px' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <a
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={closeModal}
          >
            <span aria-hidden='true'>×</span>
          </a>
          {data && (
            <div className='modal-body custom-style-valign'>
              <h2 className='title-heading-name'>{data.title}</h2>
              <div className='bugs-content'>
                <p>{data.tips}</p>
                <div className='button-footer'>
                  <button
                    type='button'
                    className='btn btn-primary yes-redeem'
                    data-dismiss='modal'
                    data-toggle='modal'
                    data-target='#redeem-confirm'
                    onClick={closeModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}

          <img src='images/man-stand-bugs.png' className='bugs-man-stand' />
        </div>
      </div>
    </div>
  );
};

export default MascotTipModal;
