/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Redirect, Link, useHistory } from 'react-router-dom';
import { isMobile } from "../helpers/common";
import Confetti from "react-confetti";
import { fetchSessionData, logout } from '../helpers/api';

const GameWon = () => {

  const history = useHistory();

  // If not logged in, send the user to "/start"
  const renderRedirect = () => {
    const { isLoggedIn, endStatus } = fetchSessionData()
    if (!isLoggedIn) return <Redirect to='/start' />
    else if (!endStatus) history.goBack();
    else logout();
  }

  return (
    <>
    {renderRedirect()}
      <div className="game-wl height-fix game-won">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 game-wrapper">
              <div className="game-content">
                <div className="row align-middle">
                  <div className="col-lg-3 idfc-logo-welcome">
                    <img src="images/idfc-logo-welcome.png" />
                  </div>
                </div>
                <div className="row align-middle">
                  <div className="col-lg-7 game-img-right img-width">
                    <img
                      style={{ maxWidth: isMobile() ? "100vw" : "25vw" }}
                      src="/images/game-won.png"
                    />
                  </div>
                  <div className="col-lg-5 right-content">
                    <h2 className="heading-big">WOOHOO!</h2>
                    <p>
                      You won! You retired with enough of savings to take care
                      of your expenses. Looks like you know how to play the Game
                      of Life.
                    </p>
                    <p>
                      To win the game in real life, start investing and saving!
                      Click
                      <a
                        href="https://www.mutualfundssahihai.com/"  target="_blank" rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {" "}
                        <u>here</u>{" "}
                      </a>
                      to know more about Mutual Funds.{" "}
                    </p>
                    <div className="align-middle play-again-btn">
                        <Link
                          to="/profile"
                          style={{ textDecoration: "none", color: "white" }}
                        className="btn btn-playagain"
                        >
                          Play again
                        </Link>
                    </div>
                    <div className="d-flex share-block align-middle">
                      <label>SHARE THIS</label>
                      <div className="d-flex social-icons">
                        <a href="#" className="mail">
                          Mail
                        </a>
                        <a href="#" className="fb">
                          Mail
                        </a>
                        <a href="#" className="in">
                          Mail
                        </a>
                        <a href="#" className="tw">
                          Mail
                        </a>
                      </div>
                    </div>                    
                  </div>
                </div>
                <div className='text-by'><br/>
                  5Secrets Simulation Software is Licensed to IDFC AMC LTD by
                  POANCHO Solutions
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
      <Confetti width={window.screen.width} height={window.screen.height} />
    </>
  );
};

export default GameWon;
