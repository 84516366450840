import React from 'react';
import PurchaseMainBlock from '../components/PurchaseMainBlock';
import PurchaseTab from '../components/PurchaseTab';
import PurchaseProvider from '../providers/PurchaseProvider';

const Purchase = () => (
  <>
  <PurchaseMainBlock>
    <PurchaseProvider>
        <PurchaseTab />
    </PurchaseProvider>
  </PurchaseMainBlock>
  </>
);

export default Purchase;
