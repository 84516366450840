import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { fetchSessionData, AcceptTerms } from '../helpers/api';

const Onboard = () => {
  const [current, setCurrent] = useState(0);
  const [accept, setAccept] = useState(false);
  const { nickName } = fetchSessionData();
//console.log(''+current);
let imgBug='man-onboard.png';
if(current===1){
  imgBug='man-onboard1.png';
}
if(current===0){
  imgBug='man-onboard2.png';
}
if(current===2){
  imgBug='man-onboard3.png';
}
if(current===5){
  imgBug='man-onboard1.png';
}
if(current===6){
  imgBug='man-onboard4.png';
}
  // If user is not logged in, send the user to "/profile"
  const renderRedirect = () => {
    if (!fetchSessionData().isLoggedIn) {
      return <Redirect to='/start' />;
    }
  };

  return (
    <>
      <Header />
      {renderRedirect()}
      <section className='home section-wrapper'>
        <div className='container'>
          <div className='row mobile-row'>
            <div className='col-lg-8 override-center float-left main trans d-flex onboard-web-main onboard-web2'>
              <div className='main-wrapper custom-style-bg'>
                <div className='stand-man' >
                  <img src={"images/"+imgBug} alt=""/><div ><a
                          href='https://www.youtube.com/watch?v=vU1l1TB7GzI&t=955s'
                          target='_blank' rel='noopener noreferrer' className='text-linex'
                        ><u>One Idiot</u>
                        </a></div>            
                </div>

                <section className='regular slider howtoplay-slide box-slide slick-slider'>
                  <div
                    className='slide-inner custome-slide-inner-1'
                    style={{
                      display: current === 0 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content box-minheight custom-onboard-height-2'>
                      <p className='light-weight-text'>Hi {nickName},</p>                     
                      <br />
                      <br />
                      <p className='light-weight-text'>
                        You are all set to play the IDFC Game of Life. I am Bugs
                        a.k.a{' '}
                        <a
                          href='https://www.youtube.com/watch?v=vU1l1TB7GzI&t=955s'
                          target='_blank' rel='noopener noreferrer'
                        ><u>One Idiot</u>
                        </a>{' '}
                        and am here to help you with the game and some
                        investment tips.
                      </p>                     
                       <p className='light-weight-text'>
                        Before we proceed, please see the following information
                        regarding KYC.
                      </p>
                    </div>
                  </div>
                  <div
                    className='slide-inner big-content custom-big-content'
                    style={{
                      display: current === 1 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content custom-onboard-height-2'>
                      <p>What is KYC?</p>
                      <p className='light-weight-text'>
                        KYC is an acronym for <q>Know your Client</q>, a term
                        commonly used for Client Identification Process. SEBI
                        has prescribed certain requirements relating to KYC
                        norms for Financial Institutions and Financial
                        Intermediaries including Mutual Funds to &#39;know&#39;
                        their Clients. This would be in the form of verification
                        of identity and address, providing information of
                        financial status, occupation and such other demographic
                        information. Applicant must be KYC compliant while
                        investing with any SEBI registered Mutual Fund.
                      </p>
                      <br/>
                      <p>Why do we need KYC?</p>
                      <p className='light-weight-text'>
                        Currently it is mandatory to be KYC compliant for making
                        an investment across all Financial Intermediaries like
                        Mutual Funds/Insurance companies/Banks etc. This is a
                        One Time Process and is free of cost. The KYC
                        Identification Number (KIN) generated, once registration
                        is complete, does not have any expiry date and hence
                        does not require to be renewed
                      </p>
                    </div>
                  </div>
                  <div
                    className='slide-inner .custome-slide-inner-2'
                    style={{
                      display: current === 2 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content custom-onboard-height-3'>
                      <p>
                        What documents are required for completion of KYC
                        registration?
                      </p>
                      <p className='light-weight-text'>
                        The documentation required, varies with each TAX
                        identity. For a resident individual of India the
                        documents are as stated below: <br />
                        <br />
                        A Passport size photograph <br />
                        <br />
                        Self Attested PAN card Photocopy, as an Identity Proof{' '}
                        <br />
                        <br />
                        An Address Proof, in the form of &#8211; AADHAR Card,
                        Passport, Voter ID, Driving License, or any other
                        government issued proof <br />
                        <br />
                        **Originals must be produced for verification purpose
                        along with the copy of these documents.
                      </p>
                    </div>
                  </div>
                  <div
                    className='slide-inner custome-slide-inner-3'
                    style={{
                      display: current === 3 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content custom-onboard-height-4'>
                      <p>How to register for KYC ?</p>
                      <p className='light-weight-text'>
                        One can become KYC compliant by following the below
                        given steps: <br />
                        <br />
                        Download the CKYC Form from the website: <br />
                        <a
                          href='https://www.idfcmf-new.com/download-centre/form-center'
                          target='_blank' rel="noopener noreferrer"
                        >
                          https://www.idfcmf-new.com/download-centre/form-center
                        </a>{' '}
                        and download the form labelled as{' '}
                        <q>CKYC &#8211; Individual</q> Form <br />
                        <br />
                        Take a print out of this form, fill the form as
                        instructed and enclose the required proofs. Carry the
                        originals of these proof for &#39;In-Person
                        Verification&#39; (IPV) by the KYC registration
                        personnel <br />
                        <br />
                        Submit the form alongside the required proofs to any KYC
                        Point of Service(PoS) such as
                        AMCs/Registrars/Distribution Houses/Registered
                        Individual Financial Advisors.
                      </p>
                    </div>
                  </div>
                  <div
                    className='slide-inner custome-slide-inner-4'
                    style={{
                      display: current === 4 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content custom-onboard-height-5'>
                      <p className='light-weight-text'>
                        Procedure to register / change bank details <br/>
                      </p>
                      <p>
                        Valid Proofs: <br/>
                        <span className='light-weight-text'>
                          � Cheque Copy with name of investor printed <br/>
                          � Bank Statement duly authorized (not older than 3
                          months for New Bank <br />
                          � Attested Bank Pass Book Copy (Not older than 3
                          months for New Bank) <br />                           
                          <br />
                        </span>
                      </p>
                      <p
                        className='light-weight-text'
                        style={{ color: '#555555' }}
                      >
                        Change of Bank Mandate
                      </p>

                      <a title='Image1'>
                        <img
                          className='thumbnail img-responsive image-one'
                          src='images/mandate.jpg'
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    className='slide-inner custome-slide-inner-5'
                    style={{
                      display: current === 5 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content box-minheight custom-onboard-height-6'>
                      <p className='light-weight-text'>
                        Investors are cautioned to deal only with the Mutual
                        Funds registered with Securities and Exchange Board of
                        India (SEBI)
                      </p>
                      <p>
                        Details of Registered Mutual Funds can be verified on
                      </p>
                      <p className='light-weight-text'>
                        &#8594; SEBI&#39;s website <br/>
                        &#8594; Intermediaries / Market Infrastructure
                        Institutions <br />
                        &#8594; Recognised Intermediaries <br/>
                        &#8594; Registered Mutual Funds
                      </p>
                    </div>
                  </div>
                  <div
                    className='slide-inner custome-slide-inner-6'
                    style={{
                      display: current === 6 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content box-minheight big-box-onboarding custom-onboard-height-7'>
                      <p className='smallerText'>
                        Redressal of Investor Complaints
                      </p>
                      <p className='light-weight-text smallerText'>
                        The Securities Exchange Board of India (SEBI) lays down
                        a TWO spoke Grievance Redressal system <br />
                        <br />
                        Investors are required to file their complaints with the
                        registered mutual fund through their designated investor
                        service contact points
                      </p>
                      <a title='Image1 smallerText'>
                        <img
                          className='thumbnail img-responsive image-two'
                          src='images/complaint.jpg'
                        />
                      </a>
                      <p
                        className='light-weight-text smallerText'
                        style={{ textAlign: 'center' }}
                      >
                        <br />
                        If you are satisfied with the resolution received from
                        the AMC close the complaint
                      </p>
                      <p className='smallerText'>
                        Incase investor is not satisfied
                        <span className='light-weight-text'>
                          {' '}
                          with the response he may choose to lodge a complaint
                          with SEBI on their website{' '}
                          <a
                            href='https://scores.gov.in/admin/Welcome.html'
                            target='_blank' rel="noopener noreferrer"
                          >
                            https://scores.gov.in/admin/Welcome.html
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className='slide-inner custome-slide-inner-7'
                    style={{
                      display: current === 7 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content box-minheight custom-onboard-height-8'>
                      <h1>HOW TO PLAY</h1>
                      <p>
                        Alright then! Let&#39;s get to the game now. <br />
                        <br />
                        There are 10 stages of life that you go through in which
                        you will earn and manage your expenses. <br />
                        <br />
                        Now, remember, you also need to invest smartly. <br />
                        <br />
                        Life events occur as you progress, some that you can
                        skip and others that you cannot. Make sure your
                        investments and savings can help you tide over.
                      </p>
                    </div>
                  </div>

                  <div
                    className='slide-inner custome-slide-inner-8'
                    style={{
                      display: current === 8 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content box-minheight custom-onboard-height-9'>
                      <h1>HOW TO PLAY</h1>
                      <p>
                        Not all decisions have a positive financial impact. For
                        eg.: taking the family on holiday. But remember the Game
                        of Life is never only about savings. Enjoy life and save
                        smart.
                      </p>{' '}
                      <br />
                      <br />
                      <h1>HOW TO WIN!</h1>
                      <p>
                        To win the game, you have to make sure that you have
                        enough savings to manage your expenses even after you
                        retire. Ready to get started with the Game of Life?
                        Let&#39;s get cracking!
                      </p>
                    </div>
                  </div>

                  <div
                    className='slide-inner custome-slide-inner-9'
                    style={{
                      display: current === 9 ? 'block' : 'none',
                    }}
                  >
                    <div className='hp-content box-minheight custom-onboard-height-10'>
                      <h1>PLEASE NOTE:</h1>
                      <p>
                        This game is a simulation of the financial decisions you
                        make in real life but cannot be taken as indicative of
                        any real life events. You will be able to invest in
                        simulated mutual funds &amp; stocks within the game.
                        These have no connection with any Indian or Foreign
                        organizations, benchmarks or markets. The NAVs are
                        algorithmically generated only for use within the game.
                      </p>
                      <p>
                        When investing in real life please note that most
                        investments are subject to risks and you should do your
                        due research before investing.
                      </p>
                      <div className='btm-div'>
                        <p className='error-text'>
                          Please check the 'I understand' box to confirm and
                          proceed.
                        </p>
                        <div className='form-check check-block'>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            id='exampleCheck1'
                            onClick={() => {
                              setAccept(!accept);
                            }}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='exampleCheck1'
                          >
                            I understand
                          </label>
                        </div>
                        <Link
                          className='btn-button start-playing'
                          to='/game-tour'
                          style={{
                            display: accept ? 'block' : 'none',
                          }}
                          onClick={AcceptTerms}
                        >
                          START PLAYING
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className='slick-next slick-arrow'
                    style={{ display: current === 9 ? 'none' : 'block' }}
                    aria-disabled='false'
                    onClick={() => {
                      setCurrent(current + 1);
                    }}
                  >Next
                  </div>
                  <div className='text-toknow'>
                    To know more about Mutual Funds, please click{' '}
                  <a
                      href='https://www.mutualfundssahihai.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-line'
                  >
                    here
                  </a>
                </div>
                  <div className='text-by'>
                  5Secrets Simulation Software is Licensed to IDFC AMC LTD by
                  POANCHO Solutions
                </div>
                </section>
              </div>
            </div>
            {/* main */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Onboard;
