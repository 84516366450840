import React from "react";

const LoanSactionedModal = ({ isOpen, toggleModal, data }) => {
  const handleClose = () => {
    toggleModal("none");
  };
  return (
    <div
      className={`modal fade take-loans-modal ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            <div className="redeem-success">
              <div className="redeem-wrapper align-middle">
                <div className="left-side-image float-left">
                  <img src="images/repay-approved-_loan.png" alt="repay-approved-loan"/>
                </div>
                <div className="right-side-redeem float-right">
                  <div className="redeem-form">
                    <h6 className="subtitle-redeem">SANCTIONED!</h6>
                    <p>
                      Your loan request is approved! Check your cash in bank
                      balance. Don't forget, your yearly EYI is{" "}
                      {data && data.emiAmount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanSactionedModal;
