import React, { useContext, useState } from "react";
import get from "lodash/get";
import fetchData from "../helpers/fetchData";
import { postData, getData, redirectToErrorPage } from "../helpers/api";

import RepayBtn from "./RepayBtn";
import { formatAmount } from "../helpers/common";
import { LoansContext } from "../providers/LoansProvider";
import LoanRepayModal from "../components/modals/LoanRepayModal";
import RepayConfirmModal from "../components/modals/RepayConfirm";
import RepayThankYouModal from "../components/modals/RepayThankYou";
import {
  REPAY_CC_PL_API,
  DASHBOARD_DETAILS_API,
  USER_LOANS_API,
} from "../constants";
import { HomeContext } from "../providers/HomeProvider";
import ViewRepayBtn from "./ViewRepayBtn";
import RepayViewModal from "./RepayViewModal";

const RepayLoan = ({ props }) => {
  const { loanOptions, isloanOptionsLoading } = useContext(LoansContext);
  const [activeModal, setActiveModal] = useState("none");
  const [modalData, setModalData] = useState(null);
  const [apiResponse, setApiData] = useState(null);
  const { setData, setLoading } = useContext(HomeContext);
  const { setLoanOptions, setloanOptionsLoading } = useContext(LoansContext);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [selOption, setselOption] = useState("entire");
  const [partAmount, setPartAmount] = useState("");

  const onRepayClk = (loanItem) => {
    setActiveModal("repay");
    setModalData(loanItem);
  };

  const onViewClk = (loanItem) => {
    setActiveModal("view");
    setModalData(loanItem);
  };

  const closeModal = () => {
    setselOption("entire");
    setPartAmount("");
    setActiveModal("none");
  };

  const handleModalRepayClick = (entireAmount, emiAmount) => {
    setActiveModal("confirm");
    setModalData({
      ...modalData,
      entireAmount,
      emiAmount,
    });
  };

  const handleModalConfirmClick = () => {
    setConfirmLoader(true);
    const finalData = {
      productId: modalData.productId,
      amount: modalData.entireAmount ? modalData.balance : modalData.emiAmount,
      entireAmount: modalData.entireAmount,
    };

    postData(REPAY_CC_PL_API, finalData)
      .then((res) => {
        setApiData(res);
        if (get(res, "status") === "success") {
          setActiveModal("thank_you");
          fetchData(DASHBOARD_DETAILS_API, setData, setLoading);
          getData(USER_LOANS_API, "data.items").then((data) => {
            setLoanOptions(data);
            setloanOptionsLoading(false);
          });
        }
        setConfirmLoader(false);
      })
      .catch((err) => {
        setApiData(err);
        setConfirmLoader(false);
        // If error type is not object or message key is not there
        // we assume that the error is not generate from the backend
        if (typeof err !== 'object' || !('message' in err)) {
          redirectToErrorPage("Something went wrong while making a payment!", "TRY AGAIN");
        }
      });
  };

  const getProductIcon = (productId) => {
    switch (productId) {
      case "repay_cc":
        return "mf-i";
        //break;
      case "repay_pl":
        return "deposits-i";
        //break;
      default:
        return "stock-i";
    }
  };

  return (
    <>
      <div
        id="repay-your-loans"
        className="tab-pane fade in active show"
        style={{ textAlign: "center" }}
      >
        <div className="contain-wrapper repay-block">
          <div className="panel-group">
          {/* <img className="paylater-tooltip" src="images/tooltip.png" alt="tooltip"/> */}
            {loanOptions &&
              Object.keys(loanOptions).map((key) => (
                <div className="panel panel-default" key={key}>
                  <div className="panel-heading">
                    <div className="p-title">
                      <span
                        className={`icon-s ${getProductIcon(
                          loanOptions[key]["productId"]
                        )}`}
                      ></span>
                      <h4 className="panel-title">
                        {loanOptions[key]["displayName"]}
                      </h4>
                      <img title={loanOptions[key]["displayName"] !== "Credit Card" && loanOptions[key]["balance"] > 0 ? loanOptions[key]["toolTip"] : ""} style={{ visibility: loanOptions[key]["displayName"] !== "Credit Card" && loanOptions[key]["balance"] > 0 ? "visible" : "hidden" }} className="paylater-tooltip" src="images/tooltip.png" alt="tooltip"/>
                    </div>
                    <div className="mid-col price">
                      {formatAmount(loanOptions[key]["balance"], " ")}
                    </div>
                    <div className="right-col">
                      {loanOptions[key]["displayName"] !== "Other Loans" &&
                        loanOptions[key]["balance"] !== 0 && (
                          <RepayBtn
                            clickHandlr={() => onRepayClk(loanOptions[key])}
                          />
                        )}
                      {loanOptions[key]["displayName"] === "Other Loans" &&
                        loanOptions[key]["items"].length > 0 && (
                          <ViewRepayBtn
                            clickHandlr={() => onViewClk(loanOptions[key])}
                          />
                        )}
                    </div>
                  </div>
                </div>
              ))}
            {isloanOptionsLoading && (
              <img src={`/images/loader.svg`} alt="Loading..." />
            )}
            </div>
        </div>
      </div>
      <div>
        <LoanRepayModal
          isOpen={activeModal === "repay"}
          data={modalData}
          selOption={selOption}
          setselOption={setselOption}
          partAmount={partAmount}
          setPartAmount={setPartAmount}
          toggleModal={closeModal}
          handleRepayClick={handleModalRepayClick}
        />
        <RepayConfirmModal
          isOpen={activeModal === "confirm"}
          data={modalData}
          handleConfirm={handleModalConfirmClick}
          apiResponse={apiResponse}
          setApiData={setApiData}
          confirmLoader={confirmLoader}
          setConfirmLoader={setConfirmLoader}
          toggleModal={closeModal}
        />
        <RepayThankYouModal
          isOpen={activeModal === "thank_you"}
          data={modalData}
          toggleModal={closeModal}
        />
        <RepayViewModal
          isOpen={activeModal === "view"}
          toggleModal={setActiveModal}
          data={modalData}
        />
      </div>
    </>
  );
};

export default RepayLoan;
