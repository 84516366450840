import React from "react";

const InvestBtn = ({ onClick }) => (
  <span data-toggle="modal" data-target="#invest-modal" onClick={onClick}>
    <label className="invest-round">
      <span className="invest-i-right"></span>
      <span className="text-in invest-text">Invest</span>
    </label>
  </span>
);

export default InvestBtn;
