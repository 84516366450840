import React from "react";

const RepayViewModal = ({ isOpen, toggleModal, data }) => {
  return (
    <div
      className={`modal fade view-loans-modal ${isOpen ? "show" : ""}`}
      id="view-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content ">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => toggleModal("none")}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            <h5 className="title-redeem">OTHER LOANS</h5>
            <div className="view-wrapper">
              <div className="panel-body">
                <div className="table-block">
                  <ul className="style-none">
                    <li className="head-label">
                      <div className="left-col li-label">Loan</div>
                      <div className="mid-col li-price">Yearly Installment</div>
                      <div className="right-col li-r-text">Installments Pending</div>
                    </li>
                    {data &&
                      data.items &&
                      data.items.map((item, index) => (
                        <li key={`repay-li-${index}`}>
                          <div className="left-col li-label">
                            {item.product}
                          </div>
                          <div className="mid-col li-price">{item.emiVal}</div>
                          <div className="right-col li-r-text">{`${item.totNoEmi-item.totNoEmiPaid}`}</div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepayViewModal;
