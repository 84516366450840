/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const RedeemThankYouModal = ({ data, isOpen, toggleModal }) => (
  <div
    className={`modal fade current-investments-tab-content wooho ${
      isOpen ? "show" : ""
    }`}
    id="redeem-thankyou"
    tabIndex="{-1}"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    style={{ display: isOpen ? "block" : "none" }}
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <a
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => toggleModal("none")}
        >
          <span aria-hidden="true">×</span>
        </a>
        <div className="modal-body">
          {data && (
            <div className="redeem-success">
              <div className="redeem-wrapper align-middle buy-bg-block">
                <div className="left-side-image float-left buy-bag">
                  <img src="/images/bag-approved.png" alt="bag approved" />
                </div>
                <div className="right-side-redeem float-right">
                  <div className="redeem-form">
                    <h6 className="subtitle-redeem">WOOHOO!</h6>
                    <h3 className="subheading-name border-none">
                      You are a proud owner of a{" "}
                      {`${data.parentCat} - ${data.displayName}`}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!data && <h5 className="title-redeem">Something Went Wrong!!</h5>}
        </div>
      </div>
    </div>
  </div>
);

export default RedeemThankYouModal;
