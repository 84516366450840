import React, { useState, useContext } from "react";
import { Tabs, Tab } from "react-bootstrap";
import RedeemModal from "./modals/RedeemModal";
import InvestSimpleModal from "./modals/InvestSimple";
import InvestDepositsModal from "./modals/InvestDepositsModal";
import RedeemConfirmModal from "./modals/RedeemConfirm";
import InvestConfirmModal from "./modals/InvestConfirm";
import RedeemSuccessModal from "./modals/RedeemSuccess";
import InvestSuccessModal from "./modals/InvestThankYou";
import CurrentInvestments from "./CurrentInvestments";
import MakeInvestment from "./MakeInvestment";
import { INVEST_BUY_API, INVEST_REDEEM_API } from "../constants";
import get from "lodash/get";
import { postData, redirectToErrorPage } from "../helpers/api";
import fetchData from "../helpers/fetchData";
import { DASHBOARD_DETAILS_API } from "../constants";
import { HomeContext } from "../providers/HomeProvider";
import { InvestContext } from "../providers/InvestProvider";

const InvestTab = ({ tabName }) => {
  const [activeModal, setActiveModal] = useState("none");
  const [modalData, setModalData] = useState(null);
  const [apiResponse, setApiData] = useState(null);
  const [selectedOption, setselectedOption] = useState(null);
  const [partialAmount, setpartialAmount] = useState(null);
  const { setData, setLoading } = useContext(HomeContext);
  const { currInvestments, currInvloading } = useContext(InvestContext);
  const { investmentOptions, invOptsloading, refreshInvestment } = useContext(InvestContext);
  const [confirmLoader, setConfirmLoader] = useState(false);

  const refreshInvestmentTab = () => {
    fetchData(DASHBOARD_DETAILS_API, setData, setLoading);
    refreshInvestment();
  }

  const onRedeemBtnClick = (data) => {
    setModalData(data);
    setActiveModal("redeem");
  };

  const onInvestBtnClick = (data, title) => {
    setModalData(data);
    if (title === "Deposits") {
      setActiveModal("invest_deposits");
    } else {
      setActiveModal("invest");
    }
  };

  const handleRedeemClick = (selectedOption, partialAmount) => {
    setselectedOption(selectedOption);
    setpartialAmount(partialAmount);
    setModalData({ ...modalData, selectedOption, partialAmount });
    setActiveModal("redeem_confirm");
  };

  const handleInvestClick = (invAmount) => {
    setModalData({ ...modalData, invAmount });
    setActiveModal("invest_confirm");
  };

  const handleInvestDepositsClick = (data) => {
    setModalData(data);
    setActiveModal("invest_confirm");
  };

  const handleInvestConfirm = () => {
    setConfirmLoader(true)
    postData(INVEST_BUY_API, getInvestApiData()).then((res) => {
      setApiData(res);
      setConfirmLoader(false);
      if (get(res, "status") === "success") {
        setActiveModal("invest_success");
        refreshInvestmentTab();
      }
    })
    .catch((err) => {
      setApiData(err);

      // If error type is not object or message key is not there
      // we assume that the error is not generate from the backend
      if (typeof err !== 'object' || !('message' in err)) {
        redirectToErrorPage("Something went wrong while making an investment!", "TRY AGAIN");
      }
    });
  };

  const getInvestApiData = () => {
    const { productId, invAmount } = modalData;

    return {
      amount: invAmount,
      productId: productId,
    };
  };

  const handleConfirmClick = (selectedOption, partialAmount) => {
    setModalData({ ...modalData, selectedOption, partialAmount });
    setActiveModal("redeem_confirm");
    setConfirmLoader(true)
    const finalData = getApiData();
    postData(INVEST_REDEEM_API, finalData)
      .then((res) => {
        setApiData(res);
        setConfirmLoader(false)
        if (get(res, "status") === "success") {
          setActiveModal("redeem_success");
          refreshInvestmentTab();
        }
      })
      .catch((err) => {
        setConfirmLoader(false)
        setApiData(err);

        // If error type is not object or message key is not there
        // we assume that the error is not generate from the backend
        if (typeof err !== 'object' || !('message' in err)) {
          redirectToErrorPage("Something went wrong while redeeming your investment!", "TRY AGAIN");
        }
      });
  };

  const getApiData = () => {
    const { productId, curInvValue, curInvUnLockValue } = modalData;

    return {
      entireAmount: selectedOption === "entire",
      productId: productId,
      amount: selectedOption === "partial" ? partialAmount : curInvValue,
    };
  };

  return (
    <>
      <Tabs defaultActiveKey={tabName ? tabName : "investments"}>
        <Tab
          eventKey="investments"
          id="current-inv"
          title="Current Investments"
        >
          <CurrentInvestments
            currInvestments={currInvestments}
            currInvloading={currInvloading}
            onRedeemBtnClick={onRedeemBtnClick}
          />
        </Tab>
        <Tab
          eventKey="makeaninvestment"
          id="make-an-inv"
          title="Make an Investment"
        >
          <MakeInvestment
            investmentOptions={investmentOptions}
            invOptsloading={invOptsloading}
            onInvestBtnClick={onInvestBtnClick}
          />
        </Tab>
      </Tabs>
      <div
        className={activeModal !== "none" ? "modal-open" : ""}
        style={{ opacity: activeModal !== "none" ? 1 : 0 }}
      >
        <RedeemModal
          isOpen={activeModal === "redeem"}
          data={modalData}
          toggleModal={setActiveModal}
          handleRedeemClick={handleRedeemClick}
        />
        <RedeemConfirmModal
          isOpen={activeModal === "redeem_confirm"}
          data={modalData}
          selectedValue={
            modalData &&
            (selectedOption === "partial"
              ? partialAmount
              : modalData.curInvValue)
          }
          toggleModal={setActiveModal}
          apiResponse={apiResponse}
          handleConfirmClick={handleConfirmClick}
          setApiData={setApiData}
          confirmLoader={confirmLoader}
          setConfirmLoader={setConfirmLoader}
        />
        <RedeemSuccessModal
          isOpen={activeModal === "redeem_success"}
          data={modalData}
          selectedValue={
            modalData &&
            (selectedOption === "partial"
              ? partialAmount
              : modalData.curInvValue)
          }
          toggleModal={setActiveModal}
          apiResponse={apiResponse}
          setApiData={setApiData}
        />
        <InvestSimpleModal
          isOpen={activeModal === "invest"}
          data={modalData}
          toggleModal={setActiveModal}
          handleInvestClick={handleInvestClick}
        />
        <InvestDepositsModal
          isOpen={activeModal === "invest_deposits"}
          data={activeModal === "invest_deposits" && modalData}
          toggleModal={setActiveModal}
          handleInvestClick={handleInvestDepositsClick}
        />
        <InvestConfirmModal
          isOpen={activeModal === "invest_confirm"}
          data={modalData}
          toggleModal={setActiveModal}
          apiResponse={apiResponse}
          setApiData={setApiData}
          handleInvestConfirm={handleInvestConfirm}
          confirmLoader={confirmLoader}
          setConfirmLoader={setConfirmLoader}
        />
        <InvestSuccessModal
          isOpen={activeModal === "invest_success"}
          data={modalData}
          toggleModal={setActiveModal}
          apiResponse={apiResponse}
          setApiData={setApiData}
        />
      </div>
    </>
  );
};

export default InvestTab;
