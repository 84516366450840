import React from 'react';
import { formatAmount } from "../../helpers/common";

const DetailedFinanceModal = ({
  isOpen,
  toggleModal,
  data,
}) => {
  return (
    <div
      className={`modal fade fin-summary overlay ${isOpen ? 'show' : ''}`}
      id='fin-summary'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      style={{ display: isOpen ? 'block' : 'none', paddingRight: '12px' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <a
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={() => toggleModal('none')}
          >
            <span aria-hidden='true'>×</span>
          </a>
          <div className='modal-body custom-style-valign'>
            <div className="table-smm fs-table-list">
              <h2 className="fins-heading">YOUR FINANCIAL SUMMARY</h2>
                <ul className="style-none">
                  {data && Object.keys(data.incExpDetails).map((each, index1) =>
                  <span key={`finance-span-${index1}`}>
                    {data.incExpDetails[each].items.length > 0 && (
                      <li key={`finance-li1-${index1}`} className="fbs-heading">
                        <div className="left-col">{data.incExpDetails[each].title}</div>
                    </li>
                    )}
                    {data.incExpDetails[each].items && data.incExpDetails[each].items.map((item, index2) => 
                      <li key={`finance-li1-${index2}`}>
                        {item.amount > 0 && (<div className="left-col">{item.txtValCashSum}</div>)}
                        {item.amount > 0 && (<div className="right-col">Rs. {formatAmount(item.amount)}</div>)}
                      </li>  
                    )}
                    <li className="space" key="detailed-finance3"></li>
                  </span>
                  )}
                </ul>
                <div className="button-footer">
                  <a className="btn btn-primary btn-pd" onClick={() => toggleModal('finance')}>Back</a>
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedFinanceModal;
