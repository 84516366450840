import React from "react";
import { Link } from "react-router-dom";

const Welcome = () => {
  document.body.className = "welcome height-fix";

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 welcome-wrapper">
          <div className="welcome-content">
            <div className="row align-middle">
              <div className="col-lg-4 idfc-logo-welcome">
                <img src="images/idfc-logo-welcome.png" alt="idfc-logo-welcome" />
              </div>
              <div className="col-lg-8 right-welcom-content">
                <p>
                  How good are you at the Game of Life? You have to manage your
                  income, your family's expenses, fulfill your dreams and retire
                  early. That too, with life's twists and turns!
                </p>
                <div className="d-flex align-middle">
                  <Link className="btn lets-start" to="/start">
                    Let's get started
                  </Link>
                  <span className="already-link">
                    Already registered?&nbsp;
                    <Link to="/start">Click here</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="image-full">
              <img src="images/welcome-img.png" className="for-desktop" alt="welcome-img"/>
              <img src="images/welcome-img2.png" className="for-mobile" alt="welcome-img2"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
