import React from 'react';
import get from 'lodash/get';
import { formatAmount } from '../../helpers/common';

const InsuranceConfirmModal = ({
  data,
  isOpen,
  toggleModal,
  handleConfirmClick,
  apiResponse,
  setApiData,
  confirmLoader,
  setConfirmLoader,
}) => {
  const handleClose = () => {
    toggleModal('none');
    setApiData(null);
    setConfirmLoader(false);
  };
  let prod='';
  if(data) prod=data.product;
  if(data && data.product!=='Health insurance')prod=data.product+ ' Insurance'
  return (
    <div
      className={`modal fad buy-insurance-popupe ${isOpen ? 'show' : ''}`}
      id='insurance-confirm'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      aria-hidden='true'
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <a
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={handleClose}
          >
            <span aria-hidden='true'>×</span>
          </a>
          <div className='modal-body'>
            <div className='redeem-success'>
              {data && (
                <div className='redeem-wrapper align-middle buy-ins-block buy-confirm'>
                  <div className='left-side-image image-man'>
                    <div className='box-content right-box-aboveman'>
                      <p>
                        Future installments will be deducted automatically for every
                        year
                      </p>
                    </div>
                    <img
                      src={`/images/${data.confImg2}`}
                      alt={data.confImg2}
                      style={{ maxHeight: '50vh' }}
                    />
                  </div>
                  <div className='right-side-redeem float-right'>
                    <div className='redeem-form'>
                      <h6 className='subtitle-redeem'>
                        CONFIRM INSURANCE PURCHASE
                      </h6>
                      <p>
                        Yearly Installment Amount is{' '}
                        {formatAmount(data.emiVal, ' ')} for{' '}
                        {prod}. This will be deducted at the end of the stage.
                      </p>
                      <div className='button-footer'>
                        <button
                          type='button'
                          className={`btn btn-primary ${
                            apiResponse && !apiResponse.msgTitle && apiResponse.status === 'success'
                              ? 'op-0'
                              : ''
                          }`}
                          data-dismiss='modal'
                          onClick={handleConfirmClick}
                        >
                          CONFIRM
                        </button>
                        <button
                          type='button'
                          className='btn btn-secondary'
                          data-dismiss='modal'
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                      {get(apiResponse, 'status') === 'failed' && (
                        <em className='error-text' style={{ width: '290px' }}>
                          {apiResponse.message}
                        </em>
                      )}
                      {get(apiResponse, 'status') === 'success' && (
                        <em className='error-text' style={{ width: '290px' }}>
                          {apiResponse.message}
                        </em>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div align='middle'>
            <img             
              className={confirmLoader ? '' : 'hide'}
              src={`/images/loaderbg.gif`}
              alt='Loading...'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceConfirmModal;
