import React, { useState } from "react";
import { formatAmount } from "../../helpers/common";

const LoanRepayModal = ({ isOpen, data, selOption, setselOption, partAmount, setPartAmount, handleRepayClick, toggleModal }) => {
  const [validationError, setValidationError] = useState(false);
  const [partValidationError, setPartValidationError] = useState(false);

  const handleSubmit = () => {
    if (!selOption) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
    }
    if (selOption === "part" && partAmount === "") {
      setPartValidationError(true);
      return;
    }
    const entireAmount = selOption === "part" ? false : true;
    const amt = entireAmount === true ? "" : partAmount;
    handleRepayClick(entireAmount, amt);
  };

  const closeModal = () => {
    setValidationError(false);
    setPartValidationError(false);
    toggleModal();
  };

  return (
    <div
      className={`modal fade repay-your-loans-modal-content ${
        isOpen ? "show" : ""
      }`}
      id="repay-modal"
      tabIndex={-1}
      aria-labelledby="exampleModalCenterTitle"
      style={{ display: isOpen ? "block" : "none", paddingRight: "12px" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content ">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <>
                <h5 className="title-redeem">Repay {data.displayName} BALANCES</h5>
                <div className="redeem-wrapper">
                  <div className="left-side-image float-left">
                    <img src={`/images/${data.confImg}`} alt=""/>
                  </div>
                  <div className="rigt-side-redeem float-right">
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">TOTAL AMOUNT:{formatAmount(data.balance, " ")}</h6>
                      <form>
                        <div className="redeem-radio form-content">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              type="radio"
                              id="entire"
                              name="repayOpt"
                              value="entire"
                              checked={selOption === "entire"}
                              className="custom-control-input"
                              onChange={(e) => {
                                setselOption(e.target.value)
                                setPartAmount("")
                                setValidationError(false)
                              }}

                            />
                            <label
                              className="custom-control-label"
                              htmlFor="entire"
                            >
                              ENTIRE AMOUNT{" "}
                              <em>{formatAmount(data.balance, " ")}</em>
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline part-of-inv">
                            <input
                              type="radio"
                              id="part"
                              name="repayOpt"
                              value="part"
                              checked={selOption === "part"}
                              className="custom-control-input"
                              onChange={(e) => {
                                setselOption(e.target.value);
                                setValidationError(false);
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="part"
                            >
                              PART OF INVESTMENT
                            </label>
                            <div className="input-field-block">
                              <input
                                placeholder="Enter amount here"
                                className="input-redeem"
                                name="partAmount"
                                min="0"
                                value={partAmount}
                                onChange={(e) => {
                                  setPartAmount(isNaN(Number(e.target.value)) ? ((partAmount === null) ? "" : partAmount) : e.target.value);
                                  setPartValidationError(false);
                                }}
                                disabled={selOption === 'entire'}
                              />
                              {validationError && (
                                <em className="error-text">
                                  Please select a Amount Mode.
                                </em>
                              )}
                              {partValidationError && (
                                <em className="error-text">
                                  Please enter Amount.
                                </em>
                              )}
                            </div>
                            <div className="button-footer">
                              <button
                                type="button"
                                className="btn btn-primary yes-redeem"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#redeem-confirm"
                                onClick={handleSubmit}
                              >
                                Yes, Repay
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={closeModal}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!data && <h5 className="title-redeem">Something Went Wrong!!</h5>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanRepayModal;
