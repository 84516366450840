import React from 'react';
import InsuranceContent from '../components/InsuranceContent';
import InsuranceProvider from '../providers/InsuranceProvider';
import CustomNextBtn from '../components/CustomNextBtn';

const Insurance = () => (
  <>
    <InsuranceProvider>
      <div className='col-lg-8 float-left main d-flex'>
        <div className='main-wrapper tab-block custom-style-height'>
          <InsuranceContent />
        </div>
        <CustomNextBtn />
      </div>
    </InsuranceProvider>
  </>
);

export default Insurance;
