/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const SubmissionModal = ({ isOpen, setClose, image, data }) => {
  return (
    <div
      className={`modal fade personal-tab-confirm ${isOpen ? "show" : ""}`}
      id="family-success"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none", textAlign: "left" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content light-purple">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={setClose}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <div className="invest-confirmation">
                <div className="redeem-wrapper align-middle">
                  <div className="left-side-image float-left">
                    <img src={`/images/${image}`} alt={image} />
                  </div>
                  <div className="right-side-redeem float-right bt-padding">
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">
                        {data.status === "failed"
                          ? "STATUS!"
                          : "ALL DONE!"}
                      </h6>
                      <p>{data.msgTitle}</p>
                      <hr style={{ borderColor: "#ffffff" }} />
                      <p>{data.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionModal;
