import React from "react";
import CareerSimpleModal from "./CareerSimpleModal";
import CareerFormModal from "./CareerFormModal";
import FamilySimpleModal from "./FamilySimpleModal";
import FamilyFormModal from "./FamilyFormModal";
import ConfirmModal from "./ConfirmModal";
import SubmissionModal from "./SubmissionModal";

const CareerModal = (props) => {
  switch (props.data.cat) {
    case "TakeCourse":
      return <CareerFormModal {...props} />;

    case "SwitchJob":
    default:
      return <CareerSimpleModal {...props} />;
  }
};

const FamilyModal = (props) => {
  switch (props.data.cat) {
    case "Vacation":
      return <FamilyFormModal {...props} />;

    case "Have a Kid":
    default:
      return <FamilySimpleModal {...props} />;
  }
};

export {
  CareerModal,
  ConfirmModal,
  FamilyModal,
  CareerSimpleModal,
  FamilySimpleModal,
  SubmissionModal,
};
