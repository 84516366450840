/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PersonalProvider from '../providers/PersonalProvider';
import PersonalContent from '../components/PersonalContent';
import CustomNextBtn from '../components/CustomNextBtn';

const Personal = () => {
  return (
    <>
      <PersonalProvider>
        <div
          className='col-lg-8 float-left main d-flex'
          style={{ textAlign: 'center' }}
        >
          <PersonalContent />
          <CustomNextBtn />
        </div>
      </PersonalProvider>
    </>
  );
};

export default Personal;
