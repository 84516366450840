import React from 'react';
import InvestTab from './InvestTab';
import InvestProvider from '../providers/InvestProvider';
import CustomNextBtn from '../components/CustomNextBtn';

const InvestMainBlock = ({ tabName }) => (
  <>
    <InvestProvider>
      <div className='col-lg-8 float-left main d-flex'>
        <div className='main-wrapper tab-block custom-style-height'>
          <InvestTab tabName={tabName} />
        </div>
        <CustomNextBtn />
      </div>
    </InvestProvider>
  </>
);

export default InvestMainBlock;
