import React from "react";
import { formatAmount } from "../../helpers/common";

const InsuranceBuyModal = ({ data, isOpen, toggleModal, handleBuyClick }) => {
  //   const {
  //     parentCat,
  //     product,
  //     dispTxt: text,
  //     emiVal,
  //     payTill,
  //     maturityAmt,
  //     maturesAt,
  //   } = data;
  return (
    <div
      className={`modal fade buy-insurance-popup ${isOpen ? "show" : ""}`}
      id="insurance-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => toggleModal("none")}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            <h5 className="title-redeem">BUY INSURANCE</h5>
            {data && (
              <div className="redeem-wrapper buy-ins-block ">
                <div className="left-side-image image-man">
                  <img src={`/images/${data.confImg}`} alt={data.confImg} />
                </div>
                <div className="right-side-redeem float-right">
                  <h3 className="subheading-name no-border">
                    {data.parentCat}:
                    <br /> {data.product}
                  </h3>
                  <div className="in-content">
                    <div className="box-content">
                      <p dangerouslySetInnerHTML={{ __html: data.dispTxt }} />
                      {data.productId === "ins_endw_fxd" && (
                        <>
                          <hr style={{ borderColor: "#f1666b" }} />
                          <p>
                            Pay{" "}
                            <strong>
                              Rs. {formatAmount(data.emiVal, " ")}
                            </strong>{" "}
                            per year <br />
                            for <strong>{data.payTill} years</strong> <br />
                            You will get{" "}
                            <strong>
                              Rs. {formatAmount(+data.maturityAmt, " ")}
                            </strong>{" "}
                            in the
                            <strong> {data.maturesAt}th year</strong>
                          </p>
                        </>
                      )}
                      {data.productId === "ins_endw_yrly" && (
                        <>
                          <hr style={{ borderColor: "#f1666b" }} />
                          <p>
                            Pay{" "}
                            <strong>
                              Rs. {formatAmount(data.emiVal, " ")}
                            </strong>{" "}
                            per year <br />
                            for <strong>{data.payTill} years</strong> <br />
                            You will get{" "}
                            <strong>
                              Rs. {formatAmount(+data.maturityAmt, " ")}
                            </strong>{" "}
                            / year for the next
                            <strong> {data.maturesAt}th year</strong>
                          </p>
                        </>
                      )}
                    </div>
                    <p className="text-label">
                      Installment Amount:{" "}
                      <strong>{formatAmount(data.emiVal, " ")}</strong>
                    </p>
                    <hr style={{ borderColor: "#ffffff" }} />
                    <div className="button-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#insurance-payout"
                        onClick={() => handleBuyClick(data)}
                      >
                        Yes, BUY
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => toggleModal("none")}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceBuyModal;
