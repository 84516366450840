/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../providers/ProfileProvider";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { getProfileImg } from "../helpers/common";

const ProfileForm = () => {
  const { data, mappingData, userSelection, setuserSelection } = useContext(
    ProfileContext
  );

  const [uniqueEmis, setuniqueEmis] = useState(null);
  const [currentEmi, setcurrentEmi] = useState(null);

  // const [uniqueSavings, setuniqueSavings] = useState(null);
  // const [currSavings, setCurrSavings] = useState(null);

  const getIncomeID = () => {
    for (let i = 0; i < mappingData.length; i++) {
      if (mappingData[i]["ageId"] == userSelection["ageId"] && mappingData[i]["profId"] == userSelection["profId"]) {
        return mappingData[i]["incomeId"];
      }
    }
  }

  useEffect(() => {
    // const profId = get(userSelection, "profId");
    // const ageId = get(userSelection, "ageId");
    // // const incomeId = get(userSelection, "incomeId");

    const currentEmi = mappingData.filter((each) => {
       //if (each.profId != profId) return false;
      // if (each.ageId != ageId) return false;
      // // if (each.incomeId != incomeId) return false;
      return true;
    });

    setcurrentEmi(currentEmi);
  }, [userSelection, mappingData]);

  useEffect(() => {
    const incomeId = get(userSelection, "incomeId");
    const married = get(userSelection, "married");
    const kids = get(userSelection, "kids");

    const currSavings = get(data, "[0].savings").filter((each) => {
      if (each.incomeId != incomeId) return false;
      if (each.married != married) return false;
      if (each.kids != kids) return false;
      return true;
    });

    // setCurrSavings(currSavings);
  }, [userSelection]);

  useEffect(() => {
    const uniqueEmis = sortBy([...new Set(mappingData.map((item) => item.expEmi))]);
    
    //Remove "0" from the array if it is there
    if (0 in uniqueEmis) {
      delete uniqueEmis[0];
    };
    setuniqueEmis(uniqueEmis);
  }, []);

  // useEffect(() => {
  //   const uniqueSavings = sortBy([
  //     ...new Set(get(data, "[0].savings").map((item) => item.savings)),
  //   ]);
  //   setuniqueSavings(uniqueSavings);
  // }, []);

  useEffect(() => {
    setuserSelection((prev) => {
      const vehicleOptions = get(data, "[0].vehicle");
      const ageRange =
        get(userSelection, "enteredAge") &&
        get(data, "[0].age").filter(
          (each) =>
            each.rangefrom <= userSelection.enteredAge &&
            each.rangeto >= userSelection.enteredAge
        );

      return {
        ...prev,
        incomeId: getIncomeID(),
        vehicle: firstProp(vehicleOptions),
        vehicleEmi: 0,
        ageId: get(ageRange, "[0].id"),
        savings: get(data, "[0].savings[0].savings"),
      };
    });
  }, [setuserSelection, data]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "vehicleEmi" && get(currentEmi, "[0].expEmi")>=0) {
      currentEmi[0].expEmi = value;
    } else if (name == "vehicle" && get(currentEmi, "[0].expEmi")>=0) {
      currentEmi[0].vehicle = value;
    // } else if (name == "saving" && get(currSavings, "[0].savings")) {
    //   currSavings[0].savings = value;
    }

    // Set the number of child to 0 if status changes to unmarried
    if (name == "married" && get(userSelection, "married") == "Y") {
      setuserSelection((prev) => {
        return { ...prev, ["kids"]: 0 };
      });
    }

    // if (name == "ageId" || name == "profId") {
    //   setuserSelection((prev) => {
    //     return { ...prev, ["incomeId"]: getIncomeID() };
    //   });
    // }

    setuserSelection((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const firstProp = (obj) => {
    for (var key in obj) return obj[key];
  };

  return (
    <div className="profile-content">
      <div className="left-side-pro">
        <h2 className="heading-name">
          Hi{" "}
          <span style={{ textTransform: "capitalize" }}>
            {userSelection["nickName"]},
          </span>{" "}
          <br />
          Here's your profile.
        </h2>
        <label className="title-label">
            Verify your details. Feel free to edit  and click NEXT to finish.
        </label>
      </div>
      <div className="right-side-pro-img">
        <img
          src={getProfileImg(
            get(data, "[0].familyImgProfileVerify"),
            userSelection["gender"],
            userSelection["married"] == "Y" ? "married" : "single",
            userSelection["kids"]
          )}
        />
      </div>
      <div className="clear" />
      <div className="block-main">
        <div className="profile-form">
          <ul>
            <li>
              <div className="input-field-block">
                <label>Age</label>
                <div className="form-group select-group">
                  <i className="arrow-select down" />
                  <select
                    className="form-control"
                    id="exampleSelect1"
                    value={get(userSelection, "ageId")}
                    name="ageId"
                    onChange={handleChange}
                  >
                    {get(data, "[0].age") &&
                      get(data, "[0].age").map((each) => (
                        <option value={each.id} key={each.id}>
                          {each.disp}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </li>
            <li className="last">
              <div className="input-field-block">
                <label>Gender</label>
                <div className="form-group select-group">
                  <i className="arrow-select down" />
                  <select
                    name="gender"
                    onChange={handleChange}
                    defaultValue={get(userSelection, "gender")}
                    className="form-control"
                    id="exampleSelect1"
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
            </li>
            <li>
              <div className="input-field-block">
                <label>Income</label>
                <div className="form-group select-group">
                  <i className="arrow-select down" />
                  <select
                    className="form-control"
                    id="exampleSelect1"
                    value={get(userSelection, "incomeId")}
                    onChange={handleChange}
                    name="incomeId"
                  >
                    {get(data, "[0].salary") &&
                      get(data, "[0].salary").map((each) => (
                        <option value={each.id} key={each.id}>
                          {each.dispTxt}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </li>
            <li className="last">
              <div className="input-field-block">
                <label>Profession</label>
                <div className="form-group select-group">
                  <i className="arrow-select down" />
                  <select
                    onChange={handleChange}
                    name="profId"
                    defaultValue={get(userSelection, "profId")}
                    className="form-control"
                    id="exampleSelect1"
                  >
                    {get(data, "[0].profession") &&
                      get(data, "[0].profession").map((each) => (
                        <option value={each.id} key={each.id}>
                          {each.text}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </li>
            <li>
              <div className="input-field-block">
                <label>Married</label>
                <div className="form-group select-group">
                  <i className="arrow-select down" />
                  <select
                    name="married"
                    onChange={handleChange}
                    value={get(userSelection, "married")}
                    className="form-control"
                    id="exampleSelect1"
                  >
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </div>
            </li>
            <li className="last">
              <div className="input-field-block">
                <label>Children</label>
                <div className="form-group select-group">
                  <i className="arrow-select down" />
                  <select
                    className="form-control"
                    id="exampleSelect1"
                    onChange={handleChange}
                    // defaultValue={get(userSelection, "kids")}
                    value={get(userSelection, "married") == "N" ? 0 : get(userSelection, "kids")} // Set child=0 if the status is unmarried
                    name="kids"
                    disabled={get(userSelection, "married") == "N" ? true : false}
                  >
                    {get(data, "[0].child") &&
                      Object.keys(get(data, "[0].child")).map((i) => (
                        <option value={i} key={i}>
                          {data[0].child[i]}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </li>
            <li>
              <div className="input-field-block">
                <label>Vehicle</label>
                <div className="form-group select-group">
                  <i className="arrow-select down" />
                  <select
                    className="form-control"
                    id="exampleSelect1"
                    value={get(currentEmi, "[0].vehicle")}
                    onChange={handleChange}
                    name="vehicle"
                  >
                    {get(data, "[0].vehicle") &&
                      Object.keys(get(data, "[0].vehicle")).map((i) => (
                        <option value={i} key={i}>
                          {data[0].vehicle[i]}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </li>
            <li className="last">
              <div className="input-field-block">
                <label>Annual Vehicle Loan</label>
                <div className="form-group select-group">
                  <i className="arrow-select down" />
                  <select
                    className="form-control"
                    id="exampleSelect1"
                    value={get(userSelection, "vehicle") == "none" ? "0" : get(currentEmi, "[0].expEmi")}
                    onChange={handleChange}
                    name="vehicleEmi"
                    disabled={get(currentEmi, "[0].vehicle") == "none" ? true : false}
                  >
                    {/* Show "Select any" by default, if Vehicle is not set to none */}
                    {(get(currentEmi, "[0].vehicle") != "none") && 
                      <option value="0">Select any</option>
                    }
                    {uniqueEmis &&
                      uniqueEmis.map((each) => (
                        <option value={each} key={each}>
                          {get(currentEmi, "[0].vehicle") == "none" ? "None" :  each.toLocaleString()}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </li>
            {/* <li className="wide-width-input greybox">
              <div className="input-field-block">
                <label>Savings</label>
                <div className="form-group select-group">
                  <i className="arrow-select down" />
                  <select
                    className="form-control"
                    id="exampleSelect1"
                    value={get(currSavings, "[0].savings")}
                    onChange={handleChange}
                    name="saving"
                  >
                    {uniqueSavings &&
                      uniqueSavings.map((each) => (
                        <option value={each} key={each}>
                          {each.toLocaleString()}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
        <div className="clear" />
      </div>
    </div>
  );
};

export default ProfileForm;
