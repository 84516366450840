/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useContext, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { DASHBOARD_DETAILS_API } from '../constants';
import { PersonalContext } from '../providers/PersonalProvider';
import { HomeContext } from '../providers/HomeProvider';
import {
  CareerModal,
  ConfirmModal,
  FamilyModal,
  SubmissionModal,
} from './modals/PersonalTab';
import { BUY_PRODUCT_API, ASSETS_LIST_API } from '../constants';
import get from 'lodash/get';
import fetchData from '../helpers/fetchData';
import { postData, getData, redirectToErrorPage } from '../helpers/api';

const tabLinkStyle = {
  cursor: 'pointer',
};

const getApiData = modalData => {
  const { mode, productId, price } = modalData;

  const finalData = {
    mode: mode ? mode : 'info',
    productId: productId,
  };

  if (mode === 'ccbank') {
    finalData['bankAmount'] = modalData.bankAmount;
    finalData['ccAmount'] = modalData.ccAmount;
  } else if (mode === 'emi') {
    finalData['emiAmount'] = modalData.emiAmount;
    finalData['emiYears'] = modalData.emiYears;
  } else if (price > 0) {
    finalData[`${mode}Amount`] = price;
  }
  return finalData;
};

const CareerBlock = ({ data }) => {
  const { setData, setLoading } = useContext(HomeContext);

  const {
    items: [itemToDisplay],
    prodImg: image,
    sucImg,
  } = data;
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSubmissionOpen, setIsSubmissionOpen] = useState(false);
  const [confirmData, setConfirmData] = useState(null);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    status: 'success',
    message: 'See how this impacts your career in the next stage!',
  });

  

  const handleSubmit = (data, onSuccess, onFailure, setData, setLoading) => {
    setConfirmLoader(true);
    const finalData = getApiData(data);
    postData(BUY_PRODUCT_API, finalData)
      .then((res) => {
        setConfirmLoader(false);
        if (get(res, "status") === "success") {
          fetchData(DASHBOARD_DETAILS_API, setData, setLoading);
          onSuccess(res);
        }
        else onFailure(res);
      })
      .catch((err) => {
        setConfirmLoader(false);
        onFailure(err);
        // If error type is not object or message key is not there
        // we assume that the error is not generate from the backend
        if (typeof err !== 'object' || !('message' in err)) {
          redirectToErrorPage("Something went wrong while making an investment!", "TRY AGAIN");
        }
      });
  };

  const handleConfirm = selectedData => {
    setConfirmData(selectedData);
    setIsOpen(false);
    setIsConfirmOpen(true);
  };

  const showFinalModal = response => {
    setApiResponse(response);
    setIsConfirmOpen(false);
    setIsSubmissionOpen(true);
  };

  return (
    <Fragment>
      <div className='block-half'>
        <a onClick={() => setIsOpen(true)} style={tabLinkStyle}>
          <img src={`/images/${image}`} />
          <span className='buy-title'>{itemToDisplay.displayName}</span>
        </a>
      </div>
      <CareerModal
        isOpen={isOpen}
        data={data}
        handleConfirm={handleConfirm}
        setClose={() => setIsOpen(false)}
      />
      <ConfirmModal
        isOpen={isConfirmOpen}
        data={confirmData}
        title='CONFIRM CAREER MOVE'
        image={sucImg}
        confirmLoader={confirmLoader}
        setConfirmLoader={setConfirmLoader}
        handleSubmit={() =>
          handleSubmit(
            confirmData,
            showFinalModal,
            showFinalModal,
            setData,
            setLoading
          )
        }
        setClose={() => setIsConfirmOpen(false)}
      />
      <SubmissionModal
        isOpen={isSubmissionOpen}
        data={apiResponse}
        image={sucImg}
        setClose={() => setIsSubmissionOpen(false)}
      />
    </Fragment>
  );
};

const FamilyBlock = ({ data }) => {
  const { setData, setLoading, setProfileData } = useContext(HomeContext);

  const {
    items: [itemToDisplay],
    prodImg: image,
    sucImg,
  } = data;
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmData, setConfirmData] = useState(null);
  const [isSubmissionOpen, setIsSubmissionOpen] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    status: 'success',
    message: 'See how this impacts your career in the next stage!',
  });

  const handleSubmit = (data, onSuccess, onFailure, setData, setLoading) => {
    setConfirmLoader(true);
    const finalData = getApiData(data);
    postData(BUY_PRODUCT_API, finalData)
      .then((res) => {
        setConfirmLoader(false);
        if (get(res, "status") === "success") {
          fetchData(DASHBOARD_DETAILS_API, setData, setLoading);
          getData(ASSETS_LIST_API, "data").then((data) => {
            setProfileData(data);
          });
          onSuccess(res);
        }
        else onFailure(res);
      })
      .catch((err) => {
        setConfirmLoader(false);
        onFailure(err);
        // If error type is not object or message key is not there
        // we assume that the error is not generate from the backend
        if (typeof err !== 'object' || !('message' in err)) {
          redirectToErrorPage("Something went wrong while making a purchase!", "TRY AGAIN");
        }
      });
  };

  const handleConfirm = selectedData => {
    setConfirmData(selectedData);
    setIsOpen(false);
    setIsConfirmOpen(true);
  };

  const showFinalModal = response => {
    setApiResponse(response);
    setIsConfirmOpen(false);
    setIsSubmissionOpen(true);
  };
                  
  return (
    <Fragment>
      <div className='block-half'>
        <a onClick={() => setIsOpen(true)} style={tabLinkStyle}>
          <img src={`/images/${image}`} />
          <span className='buy-title'>{data.cat==='Vacation' ?'TAKE A BREAK':itemToDisplay.displayName}</span>
        </a>
      </div>
      <FamilyModal
        isOpen={isOpen}
        data={data}
        handleConfirm={handleConfirm}
        setClose={() => setIsOpen(false)}
      />
      <ConfirmModal
        isOpen={isConfirmOpen}
        data={confirmData}
        title='CONFIRM FAMILY PLAN'
        image={sucImg}
        confirmLoader={confirmLoader}
        setConfirmLoader={setConfirmLoader}
        handleSubmit={() =>
          handleSubmit(
            confirmData,
            showFinalModal,
            showFinalModal,
            setData,
            setLoading
          )
        }
        setClose={() => setIsConfirmOpen(false)}
      />
      <SubmissionModal
        isOpen={isSubmissionOpen}
        data={apiResponse}
        image={sucImg}
        setClose={() => setIsSubmissionOpen(false)}
      />
    </Fragment>
  );
};

const PersonalContent = () => {
  const {
    familyOpts,
    careerOpts,
    familyOptsloading,
    careerOptsloading,
  } = useContext(PersonalContext);

  return (
    <div className='main-wrapper tab-block custom-style-height'>
      <Tabs defaultActiveKey='personal-career'>
        <Tab eventKey='personal-career' id='personal-career' title='Career'>
          <div className='four-block'>
            {careerOpts &&
              careerOpts.map(row => (
                <CareerBlock key={row.items[0].productId} data={row} />
              ))}
            {careerOptsloading && (
              <img src={`/images/loader.svg`} alt='Loading...' />
            )}
          </div>
        </Tab>
        <Tab eventKey='personal-family' id='personal-family' title='Family'>
          <div className='four-block'>
            {familyOpts &&
              familyOpts.map(row => (
                <FamilyBlock key={row.items[0].productId} data={row} />
              ))}
            {familyOptsloading && (
              <img src={`/images/loader.svg`} alt='Loading...' />
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default PersonalContent;
