/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from "react";
import { MODES_LABEL } from "../../../constants";
import {
  formatAmount,
  getEmiValues,
  // getCcbankValues,
} from "../../../helpers/common";
import get from "lodash/get";

const FamilyFormModal = ({ isOpen, setClose, data, handleConfirm }) => {
  const {
    title,
    dispTxt: content,
    confImg: image,
    buttonTxt,
    // items: [itemToDisplay],
  } = data;
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedEmi, setSelectedEmi] = useState(5);
  const [selectedCountry, setSelectedCountry] = useState("none");
  const [selectedProduct, setSelectedProduct] = useState(
    get(data, "items[0].productId")
  );
  const [validationError, setValidationError] = useState(false);
  const [confirmBtn, cancelBtn] = buttonTxt.split("|");

  const closeAll = () =>{
    setClose();
    setSelectedOption(null);
    setSelectedEmi(5);
    setSelectedCountry("none");
    setSelectedProduct("");
    setValidationError(false);
  }

  const isModes = get(data, "modes");
  const modes = isModes && isModes.split("|");

  const handleSubmission = () => {
    if (!selectedOption) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
      handleConfirm({
        ...data.items[selectedCountry],
        mode: selectedOption,
        emiYears: selectedEmi,
        emiAmount:
          selectedOption === "emi"
            ? getEmiValues(data.emiValue)[selectedEmi]
            : 0,
      });
    }
    closeAll();
    // handleBuyClick(selectedOption, selectedEmi, selectedCcBank); //need_to_check
  };

  return (
    <div
      className={`modal fade purchase-tab-content ${isOpen ? "show" : ""}`}
      id="purchase-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      role="dialog"
      style={{ display: isOpen ? "block" : "none", textAlign: "left" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content" style={{ background: "#bc8cbf" }}>
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAll}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <Fragment>
                <h5 className="title-redeem">{title}</h5>
                <div className="redeem-wrapper">
                  <div className="left-side-image float-left">
                    <img src={`/images/${image}`} alt={image} />
                  </div>
                  <div className="right-side-redeem float-right">
                    <h3 className="subheading-name name-obj">{content}</h3>
                    <div className="form-group select-group">
                      <i className="arrow-select down" />
                      <select
                        className="form-control"
                        id="exampleSelect1"
                        value={selectedCountry}
                        onChange={(e) => {
                          setSelectedCountry(e.target.value);
                          setSelectedProduct(get(data, `items[${selectedCountry}].productId`));
                        }}
                      >
                        <option hidden value="none" name="none">Choose Any</option>
                        {data.items &&
                          data.items.map((each, id) => {
                            return (
                              <option key={each.productId} value={id}>
                                {each.displayName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <h3 className="subheading-name name-value-obj">
                      Cost:{" "}
                      {selectedCountry==='none' ? '0': formatAmount(
                        get(data, `items[${selectedCountry}].price`),
                        " "
                      )}
                    </h3>
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">PAY USING</h6>
                      <form>
                        <div className="redeem-radio form-content buy-using">
                          {modes.map((mode) => (
                            <div
                              className="custom-control custom-radio custom-control-inline part-of-inv"
                            key={`${mode}-1`}
                            >
                              <input
                                type="radio"
                              id={`${mode}-1`}
                                name="buyuse"
                                value={mode}
                                className="custom-control-input"
                                checked={selectedOption === mode}
                                onChange={(e) => {
                                  setSelectedOption(e.target.value);
                                  setValidationError(false);
                                }}
                              disabled={selectedCountry==="none" ? true : false}
                              />
                              <label
                                className="custom-control-label"
                              htmlFor={`${mode}-1`}
                              >
                                <span>{MODES_LABEL[mode]}</span>
                              </label>
                            {/* {selectedOption === "paylater-1" &&
                                mode === "paylater" && (
                                <div className="paylater">
                                    <p className="note-text">
                                      Please note:
                                      <br />
                                      You will have to clear your Pay Later
                                      balance at the end of the stage.
                                    </p>
                                  </div>
                              )} */}
                              {selectedOption === "emi" && mode === "emi" && (
                                <div className="emivalue">
                                    <div className="form-group select-group">
                                    <i className="arrow-select down" />
                                    <select
                                        className="form-control"
                                        id="exampleSelect2"
                                        value={selectedEmi}
                                        onChange={(e) =>
                                        setSelectedEmi(e.target.value)
                                        }
                                    >
                                        {data.items[selectedCountry].emiYears
                                        .split("|")
                                        .map((yearValue) => (
                                            <option value={yearValue} key={yearValue}>
                                            {yearValue} years
                                            </option>
                                        ))}
                                    </select>
                                    </div>
                                    <p className="text-monthly-emi">
                                    Yearly EYI:{" "}
                                    {
                                      formatAmount(getEmiValues(
                                        data.items[selectedCountry].emiValue
                                      )[selectedEmi])
                                    }
                                    </p>
                                </div>
                                )}
                              {/* {selectedOption === "ccbank-1" &&
                                mode === "ccbank" && (
                                  <div className="creditcard">
                                    <div className="form-group select-group">
                                      <i className="arrow-select down" />
                                      <select
                                        className="form-control"
                                        id="exampleSelect1"
                                        value={selectedCcBank}
                                        onChange={(e) =>
                                          setselectedCcBank(e.target.value)
                                        }
                                      >
                                        {data.ccBank.split("|").map((perc) => (
                                          <option value={perc} key={perc}>
                                            Bank {perc.split(":")[0]}% + C.Card{" "}
                                            {perc.split(":")[1]}%
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <p className="text-monthly-emi">
                                      Bank:{" "}
                                      {
                                        getCcbankValues(
                                          data.ccBank,
                                          data.price,
                                          "bank"
                                        )[selectedCcBank]
                                      }{" "}
                                      pa <br />
                                      Credit card:{" "}
                                      {
                                        getCcbankValues(
                                          data.ccBank,
                                          data.price,
                                          "cc"
                                        )[selectedCcBank]
                                      }{" "}
                                      pa
                                    </p>
                                  </div>
                                )} //need_to_check */}
                            </div>
                          ))}
                          {validationError && (
                            <em className="error-text">
                              Please select a Payment Mode.
                            </em>
                          )}
                        </div>
                        <div className="button-footer">
                          <button
                            type="button"
                            className={`btn btn-primary yes-redeem ${selectedCountry === 'none' ? 'op-0': ''}`}
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#redeem-confirm"
                            onClick={handleSubmission}
                          >
                            <span style={{ textTransform: "capitalize" }}>
                              {confirmBtn}
                            </span>
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={closeAll}
                          >
                            {cancelBtn}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            {!data && <h5 className="title-redeem">Something Went Wrong!!</h5>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyFormModal;
