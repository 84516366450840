/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const InvestThankYou = ({
  isOpen,
  data,
  apiResponse,
  toggleModal,
  setApiData,
}) => {
  return (
    <div
      className={`modal fade make-aninvest-tab-content ${isOpen ? "show" : ""}`}
      id="invest-thankyou"
      tabIndex="{-1}"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setApiData(null);
              toggleModal("none");
            }}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            <div className="redeem-success">
              <div className="redeem-wrapper align-middle">
                <div className="left-side-image float-left">
                  {data && data.sucImg && (
                    <img src={`/images/${data.sucImg}`} alt="make-inv" />
                  )}
                </div>
                <div className="right-side-redeem float-right">
                  <div className="redeem-form">
                    <h6 className="subtitle-redeem border-bottom">
                      {apiResponse && apiResponse.msgTitle}
                    </h6>
                    <p>{apiResponse && apiResponse.message.replace(".00", "")}</p>
                    <em className="error-text">
                      {apiResponse && apiResponse.sucMsgTxt}
                    </em>                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestThankYou;
