/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import {
  formatAmount,
  getEmiValues,
  getCcbankValues,
} from "../../../helpers/common";
import { MODES_LABEL } from "../../../constants";
import get from "lodash/get";

const EventsMain = ({
  data,
  buttonText,
  isOpen,
  toggleModal,
  handleBuyClick,
}) => {
  const [selectedOption, setselectedOption] = useState(null);
  const [selectedEmi, setselectedEmi] = useState(5);
  const [selectedCcBank, setselectedCcBank] = useState("25:75");
  const [validationError, setValidationError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("none");
  const initCountry = data && data.items && data.items.length===1 ? 0 : "none"
  const initEmi = data && data.items && data.items.length===1 ? Number(data.items[0].emiYears.split("|")[0]) : 5
  const isModes = get(data, "items[0].modes");
  const modes = isModes && isModes.split("|");

  const handleSubmission = () => {
    if (!selectedOption) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
    }

    const selectedProduct = get(data, `items[${selectedCountry}]`);
    handleBuyClick(selectedOption, selectedProduct, selectedEmi, selectedCcBank);
    resetForm();
  };

  const resetForm = () => {
    // reset to initial stage at time of close
    setselectedOption(null);
    setSelectedCountry(initCountry);
    setselectedEmi(initEmi)
    setValidationError(false);
  }

  const handleClose = () => {
    toggleModal("none");
    resetForm();
  };

  const colorMapping = {
    "PERSONAL-POPUP": "#bc8cbf",
    "PURCHASE-POPUP": "#f1666b",
  };

  useEffect(() => {
    if (data && data.items && data.items.length===1) {
      setSelectedCountry(0);
      setselectedEmi(Number(data.items[0].emiYears.split("|")[0]));
    }
    else {
      setSelectedCountry("none");
      setselectedEmi(5);
    }
}, [data]);

  return (
    isOpen && (
      <div
        className={`modal fade purchase-tab-content ${isOpen ? "show" : ""}`}
        id="purchase-modal"
        tabIndex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className="modal-content"
            style={{ background: colorMapping[get(data, "popupType")] }}
          >
            <a
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </a>
            <div className="modal-body">
              {data && (
                <>
                  <h5 className="title-redeem">{data.confTitle}</h5>
                  <div className="redeem-wrapper">
                    <div className="left-side-image float-left">
                      <img src={`/images/${data.confImg}`} alt="buy-img" />
                    </div>
                    <div className="right-side-redeem float-right">
                      <h3 className="subheading-name name-obj">
                        {data.confMsg}
                      </h3>
                      <div className="form-group select-group">
                      {data && data.items && data.items.length===1 && (
                        <h5>{data.items[0].displayName}</h5>
                      )}
                      {data && data.items && data.items.length>1 && (
                        <>
                        <i className="arrow-select down" />
                        <select
                          className="form-control"
                          id="exampleSelect1"
                          value={selectedCountry}
                          onChange={(e) => {
                            setSelectedCountry(e.target.value);
                          }}
                        >
                            <option hidden value="none" name="none">Choose Any</option>
                          {data.items &&
                            data.items.map((each, id) => {
                              return (
                                <option key={each.productId} value={id}>
                                  {each.displayName} at ({formatAmount(each.price)})
                                </option>
                              );
                            })}
                        </select>
                        </>
                      )}
                      </div>
                      <h3 className="subheading-name name-value-obj">
                        Cost:{" "}
                        {selectedCountry==='none' ? '0' : formatAmount(
                          get(data, `items[${selectedCountry}].price`),
                          " "
                        )}
                      </h3>
                      <div className="redeem-form">
                        <h6 className="subtitle-redeem">PAY USING</h6>
                        <form>
                          <div className="redeem-radio form-content buy-using">
                            {modes &&
                              Object.keys(modes).map((key) => (
                                <div
                                  className="custom-control custom-radio custom-control-inline part-of-inv"
                                  key={key}
                                >
                                  <input
                                    type="radio"
                                    id={modes[key]}
                                    name="buyuse"
                                    value={modes[key]}
                                    className="custom-control-input"
                                    checked={selectedOption === modes[key]}
                                    onChange={(e) => {
                                      setselectedOption(e.target.value);
                                      setValidationError(false);
                                    }}
                                    disabled={selectedCountry==="none" ? true : false}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={modes[key]}
                                  >
                                    <span>{MODES_LABEL[modes[key]]}</span>
                                  </label>
                                  {selectedOption === "paylater" &&
                                    modes[key] === "paylater" && (
                                      <div className="paylater">
                                        <p className="note-text">
                                          Please note:
                                          <br />
                                          You will have to clear your Pay Later
                                          balance at the end of the stage.
                                        </p>
                                      </div>
                                    )}
                                  {selectedOption === "ccbank" &&
                                modes[key] === "ccbank" && (
                                  <div className="creditcard">
                                    <div className="form-group select-group">
                                      <i className="arrow-select down" />
                                      <select
                                        className="form-control"
                                        id="exampleSelect1"
                                        value={selectedCcBank}
                                        onChange={(e) =>
                                          setselectedCcBank(e.target.value)
                                        }
                                      >
                                        {data.items[selectedCountry].ccBank.split("|").map((perc) => (
                                          <option value={perc} key={perc}>
                                            Bank {perc.split(":")[0]}% + C.Card{" "}
                                            {perc.split(":")[1]}%
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <p className="text-monthly-emi">
                                      Bank:{" "}
                                      {
                                        formatAmount(getCcbankValues(
                                          data.items[selectedCountry].ccBank,
                                          data.items[selectedCountry].price,
                                          "bank"
                                        )[selectedCcBank])
                                      }{" "}
                                      pa <br />
                                      Credit card:{" "}
                                      {
                                        formatAmount(getCcbankValues(
                                          data.items[selectedCountry].ccBank,
                                          data.items[selectedCountry].price,
                                          "cc"
                                        )[selectedCcBank])
                                      }{" "}
                                      pa
                                    </p>
                                  </div>
                                )}
                                  {selectedOption === "emi" &&
                                    modes[key] === "emi" && (
                                      <div className="emivalue">
                                        <div className="form-group select-group">
                                          <i className="arrow-select down" />
                                          <select
                                            className="form-control"
                                            id="exampleSelect2"
                                            value={selectedEmi}
                                            onChange={(e) =>
                                              setselectedEmi(e.target.value)
                                            }
                                          >
                                            {data.items[
                                              selectedCountry
                                            ].emiYears
                                              .split("|")
                                              .map((yearValue) => (
                                                <option value={yearValue} key={yearValue}>
                                                  {yearValue} years
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                        <p className="text-monthly-emi">
                                          Yearly EYI:{" "}
                                          {
                                            formatAmount(getEmiValues(
                                              data.items[selectedCountry]
                                                .emiValue
                                            )[selectedEmi])
                                          }
                                        </p>
                                      </div>
                                    )}
                                </div>
                              ))}
                            {validationError && (
                              <em className="error-text">
                                Please select a Payment Mode.
                              </em>
                            )}
                          </div>
                          <div className="button-footer">
                            <button
                              type="button"
                              className={`btn btn-primary yes-redeem ${selectedCountry === 'none' ? 'op-0': ''}`}
                              data-dismiss="modal"
                              data-toggle="modal"
                              data-target="#redeem-confirm"
                              onClick={handleSubmission}
                            >
                              <span style={{ textTransform: "capitalize" }}>
                                {data.buttonTxt}
                              </span>
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={handleClose}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!data && (
                <h5 className="title-redeem">Something Went Wrong!!</h5>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default EventsMain;
