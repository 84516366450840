import React, { useState, useEffect } from "react";
import { CURR_INVEST_API, INVEST_LIST_API, INVEST_DETAIL_API } from "../constants";
import { getData } from '../helpers/api';

export const InvestContext = React.createContext();

const InvestProvider = ({ children }) => {
  const [currInvestments, setcurrInvestments] = useState(null);
  const [currInvloading, setcurrInvloading] = useState(true);
  const [investmentOptions, setinvestmentOptions] = useState(null);
  const [financialDetails, setFinancialDetails] = useState(null);
  const [invOptsloading, setinvOptsloading] = useState(true);
  const [refreshCount, setRefreshCount] = useState(0);
  const [financeRefreshCount, setFinanceRefreshCount] = useState(0);

  useEffect(() => {
    getData(CURR_INVEST_API)
      .then((data) => {
        setcurrInvestments(data);
        setcurrInvloading(false);
      });
    
    getData(INVEST_LIST_API)
      .then((data) => {
        setinvestmentOptions(data);
        setinvOptsloading(false);
      });
  }, [refreshCount]);
    
  useEffect(() => {
    getData(INVEST_DETAIL_API)
      .then((data) => {
        setFinancialDetails(data);
        setinvOptsloading(false);
      });

  }, [financeRefreshCount]);

  return (
    <InvestContext.Provider
      value={{
        currInvloading,
        currInvestments,
        invOptsloading,
        investmentOptions,
        setinvestmentOptions,
        financialDetails,
        refreshInvestment: () => setRefreshCount(refreshCount + 1),
        refreshFinance: () => setFinanceRefreshCount(financeRefreshCount + 1),
      }}
    >
      {children}
    </InvestContext.Provider>
  );
};

export default InvestProvider;
