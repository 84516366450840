/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { postData, getData, redirectToErrorPage } from "../helpers/api";
import get from "lodash/get";

import { Accordion, Card } from "react-bootstrap";
import { InsuranceContext } from "../providers/InsuranceProvider";
import { HomeContext } from "../providers/HomeProvider";
import InsuranceBuyModal from "../components/modals/InsuranceBuyModal";
import InsuranceConfirmModal from "../components/modals/InsuranceConfirmModal";
import InsuranceThankYou from "../components/modals/InsuranceThankYou";
import {
  INSURANCE_BUY_API,
  DASHBOARD_DETAILS_API,
  INSURANCE_LIST_API,
} from "../constants";
import fetchData from "../helpers/fetchData";

const InsuranceBuy = () => {
  const { buyData, loading } = useContext(InsuranceContext);
  const { setData, setLoading } = useContext(HomeContext);
  const [activeModal, setActiveModal] = useState("none");
  const [modalData, setModalData] = useState(null);
  const [apiResponse, setApiData] = useState(null);
  const { setInsData, setInsLoading } = useContext(InsuranceContext);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const lifeInsurance = get(buyData, '["Life Insurance"]["Life Insurance"]');
  const endowment = get(buyData, '["Life Insurance"]["Endowment"]');
  const generalInsurance = get(
    buyData,
    '["General Insurance"]["General Insurance"]'
  );

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const onInsuranceBtnClick = (key) => {
    setModalData(key);
    setActiveModal("buy");
  };

  const onGeneralBtnClick = (item) => {
    if (generalInsurance) {
      const key = generalInsurance.find((each) => each.productId === item);
      setModalData(key);
      setActiveModal("buy");
    }
  };

  const handleModalBuyClick = (item) => {
    setModalData(item);
    setActiveModal("confirm");
  };

  const handleModalConfirmClick = () => {
    setConfirmLoader(true);
    const { productId, emiYears, maturityAmt, emiVal } = modalData;
    const finalData = {
      productId,
      maturityAmt,
      emiVal,
    };


    postData(INSURANCE_BUY_API, finalData)
      .then((res) => {
        setApiData(res);
        if (get(res, "status") === "success") {
          setConfirmLoader(false);
          setActiveModal("thank_you");
          fetchData(DASHBOARD_DETAILS_API, setData, setLoading);

          getData(INSURANCE_LIST_API, "data.items").then((data) => {
              setInsData(data);
              setInsLoading(false);
            });
        }
      })
      .catch((err) => {
        setApiData(err);
        setConfirmLoader(false);
        // If error type is not object or message key is not there
        // we assume that the error is not generate from the backend
        if (typeof err !== 'object' || !('message' in err)) {
          redirectToErrorPage("Something went wrong while buying an insurance!", "TRY AGAIN");
        }
      });
  };

  return (
    <>
      <Accordion>
        <div className="accordion-wrapper">
          <div className="panel-group">
            <div className="panel panel-default">
              <Accordion.Toggle as={Card.Header} eventKey="life" onClick={() => toggleActive('life')}>
                <div
                  className={`panel-heading accordion-toggle ${activeId === 'life' ? '' : 'collapsed'}`}
                  data-toggle="collapse"
                >
                  <div className="p-title wide-full">
                    <span className="icon-s life-ins-i"></span>
                    <h4 className="panel-title">Life Insurance</h4>
                  </div>
                </div>
              </Accordion.Toggle>
              <div>
                <Accordion.Collapse eventKey="life">
                  <div className="panel-body">
                    <div className="table-block">
                      {buyData && (
                        <ul className="style-none">
                          {endowment && (
                            <li>
                              <div className="wide-full head-label">
                                ENDOWMENT
                              </div>
                            </li>
                          )}
                          {endowment &&
                            Object.keys(endowment).map((key, index) => (
                              <li key={index}>
                                <div className="left-col li-label left-padding-space">
                                  {endowment[key]["product"]}
                                </div>
                                <div className="invest-right">
                                  <span
                                    data-toggle="modal"
                                    data-target="#insurance-modal"
                                  >
                                    <label className="invest-round">
                                      <span className="invest-i-right"></span>
                                      <span
                                        className="text-in invest-text"
                                        onClick={() =>
                                          onInsuranceBtnClick(endowment[key])
                                        }
                                      >
                                        Buy
                                      </span>
                                    </label>
                                  </span>
                                </div>
                              </li>
                            ))}
                          {lifeInsurance &&
                            Object.keys(lifeInsurance).map((key, index) => (
                              <li key={index}>
                                <div className="left-col li-label">
                                  {lifeInsurance[key]["product"]}
                                </div>
                                <div className="invest-right">
                                  <span
                                    data-toggle="modal"
                                    data-target="#insurance-modal"
                                  >
                                    <label className="invest-round">
                                      <span className="invest-i-right"></span>
                                      <span
                                        className="text-in invest-text"
                                        onClick={() =>
                                          onInsuranceBtnClick(
                                            lifeInsurance[key]
                                          )
                                        }
                                      >
                                        Buy
                                      </span>
                                    </label>
                                  </span>
                                </div>
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
            </div>
            <div className="panel panel-default">
              <Accordion.Toggle as={Card.Header} eventKey="general" onClick={() => toggleActive('general')}>
                <div
                  className={`panel-heading accordion-toggle ${activeId === 'general' ? '' : 'collapsed'}`}
                  data-toggle="collapse"
                >
                  <div className="p-title wide-full">
                    <span className="icon-s gen-ins-i"></span>
                    <h4 className="panel-title">GENERAL INSURANCE</h4>
                  </div>
                </div>
              </Accordion.Toggle>
              <div>
                <Accordion.Collapse eventKey="general">
                  <div className="panel-body">
                    <div className="table-block">
                      <ul className="style-none">
                        <li className="wide-full head-label">
                          <div className="wide-full head-label">
                            Health insurance
                          </div>
                          <div className="invest-right">
                            <span
                              data-toggle="modal"
                              data-target="#insurance-modal"
                            >
                              <label className="invest-round">
                                <span className="invest-i-right"></span>
                                <span
                                  className="text-in invest-text"
                                  onClick={() =>
                                    onGeneralBtnClick("ins_health")
                                  }
                                >
                                  Buy
                                </span>
                              </label>
                            </span>
                          </div>
                        </li>
                        <li className="wide-full head-label">
                          <div className="wide-full head-label">
                            Home [Fire / Theft]
                          </div>
                          <div className="invest-right">
                            <span
                              data-toggle="modal"
                              data-target="#insurance-modal"
                            >
                              <label className="invest-round">
                                <span className="invest-i-right"></span>
                                <span
                                  className="text-in invest-text"
                                  onClick={() => onGeneralBtnClick("ins_home")}
                                >
                                  Buy
                                </span>
                              </label>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
            </div>
            {loading && <img src={`/images/loader.svg`} alt="Loading..." />}
          </div>
        </div>
      </Accordion>
      <div
        className={activeModal !== "none" ? "modal-open" : ""}
        style={{ opacity: activeModal !== "none" ? 1 : 0 }}
      >
        <InsuranceBuyModal
          isOpen={activeModal === "buy"}
          data={modalData}
          toggleModal={setActiveModal}
          handleBuyClick={handleModalBuyClick}
        />
        <InsuranceConfirmModal
          isOpen={activeModal === "confirm"}
          data={modalData}
          toggleModal={setActiveModal}
          handleConfirmClick={handleModalConfirmClick}
          setApiData={setApiData}
          apiResponse={apiResponse}
          confirmLoader={confirmLoader}
          setConfirmLoader={setConfirmLoader}
        />
        <InsuranceThankYou
          isOpen={activeModal === "thank_you"}
          data={modalData}
          toggleModal={setActiveModal}
          setApiData={setApiData}
        />
      </div>
    </>
  );
};

export default InsuranceBuy;
