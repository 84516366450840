import { getData, config } from "./api";
import axios from "axios";
import get from "lodash/get";

const openApiCall = (api) => {
  return axios
    .get(api, config)
    .then((result) => {
      const data = get(result, "data.data");
      return data;
    })
    .catch(() => null);
};

export const fetchOpenApi = async (api, setDataFn, setLoadingFn) => {
  typeof setLoadingFn === "function" && setLoadingFn(true);
  const data = await openApiCall(api);
  setDataFn(data);
  typeof setLoadingFn === "function" && setLoadingFn(false);
};

const awaitCall = async (
  api,
  setDataFn,
  setLoadingFn,
  responseIndex = "data.data"
) => {
  typeof setLoadingFn === "function" && setLoadingFn(true);
  const data = await getData(api, responseIndex);
  setDataFn(data);
  typeof setLoadingFn === "function" && setLoadingFn(false);
};

export default awaitCall;
