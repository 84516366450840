import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import PurchaseTabContent from '../components/PurchaseTabContent';
import AlreadyPurchasedTabContent from '../components/AlreadyPurchasedTabContent';

const PurchaseTab = () => (
  <Tabs defaultActiveKey="shop">
    <Tab eventKey="shop" id="shop-tab" title="Shop Now">
      <PurchaseTabContent />
    </Tab>
    <Tab eventKey="shopped" id="shopped-tab" title="Already Bought">
      <AlreadyPurchasedTabContent />
    </Tab>
  </Tabs>
);

export default PurchaseTab;
