import React, { useState, useEffect } from "react";
import { fetchOpenApi } from "../helpers/fetchData";
import { PROFILE_FORM_API, PROFILE_FORM_MAPPING_API } from "../constants";

export const ProfileContext = React.createContext();

const ProfileProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mappingData, setMappingData] = useState(null);
  const [userSelection, setuserSelection] = useState(null);
  const [step, setStep] = useState(0);

  useEffect(() => {
    fetchOpenApi(PROFILE_FORM_API, setData, setLoading);
    fetchOpenApi(PROFILE_FORM_MAPPING_API, setMappingData);
  }, []);

  useEffect(() => {}, [userSelection]);

  useEffect(() => {}, [step]);

  return (
    <ProfileContext.Provider
      value={{
        data,
        loading,
        mappingData,
        userSelection,
        step,
        setuserSelection,
        setStep,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
