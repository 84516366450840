/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

const ProfileModal = ({ data, isOpen, toggleModal }) => {

  const handleClose = () => {
    toggleModal("none");
  };

  return (
    <div
      className={`modal fade profile-popup ${
        isOpen ? "show" : ""
      }`}
      id="profile-popup"
      tabIndex="{-1}"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
      <a
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={handleClose}
      >
          <span aria-hidden="true">&times;</span>
        </a>
        {data && (
          <>
          <div className="modal-body d-flex">
            <div className="pp-left-man for-desktop">
              <img src={`images/assets/${data.profImg}`} alt=""/>
            </div>
            <div className="pp-right-main">
              <div className="profile-name-block d-flex">
                  <div className="pp-left-man-mobile for-mobile">
                  <img src={`images/assets/${data.profImg}`} alt=""/>
                </div>
                <div className="profile-nick-name">
                  <p>Hello {data.nickName}</p>
                  <p>At this stage in the IDFC Game of Life, you are {data.age} years of age. </p>
                </div>
              </div>
              <div className="pp-content d-flex">
                <div className="pp-left">
                  {data.familyTxt !== "" &&
                    <>
                      <p>{data.familyTxt}</p>
                      <img src={`images/assets/${data.familyImg}`} alt=""/>
                    </>
                  }
                  {data.familyTxt === "" &&
                    <p>Family Details Not Available</p>
                  }
                </div>
                <div className="pp-right">
                  {data.assets.length>0 &&
                    <>
                    <p>Lifestyle Assets you own </p>
                    <em>View details in the <i>Shop</i> tab</em>
                    <div className="pp-assets-list">
                      <ul>
                        {data.assets.map(asset => 
                          <li key={asset.parentCat}>
                            <div className="img-number"><img src={`../images/assets/${asset.assetImg}`} alt=""/><span className="pp-count">{asset.count}</span></div>
                            <p className="assets-text">{asset.parentCat}</p>
                          </li>
                        )}
                        {/* <li className="m0">
                          <div className="img-number"><img src="images/pp-blank-img.png" alt=""/></div>
                          <p className="assets-text">Name of the asset goes here</p>
                        </li> */}
                      </ul>
                    </div>
                    </>
                  }
                  {data.assets.length===0 &&
                    <p>Currently you do not own any assets</p>
                  }
                </div>
              </div>
            </div>
        </div>
          </>
        )}
        {!data &&
          <div className="modal-body d-flex">
            <p>Data Not Available</p>
          </div>
        }
      </div>
      </div>
    </div>
  );
};

export default ProfileModal;
