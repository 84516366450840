import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import RepayLoan from "./RepayLoan";
import TakeLoan from "./TakeLoan";

const LoansTab = () => (
  <Tabs defaultActiveKey="repay">
    <Tab eventKey="repay" id="repay-loans-tab" title="Repay Your Loans">
      <RepayLoan />
    </Tab>
    <Tab eventKey="loan" id="take-loan-tab" title="Take Loan">
      <TakeLoan />
    </Tab>
  </Tabs>
);

export default LoansTab;
