import React, { useState, useEffect } from "react";
import { USER_LOANS_API, LOAN_REQUEST_API } from "../constants";
import { getData } from "../helpers/api";

export const LoansContext = React.createContext();

const LoansProvider = ({ children }) => {
  const [loanOptions, setLoanOptions] = useState(null);
  const [loanFormData, setLoanFormData] = useState(null);
  const [isloanOptionsLoading, setloanOptionsLoading] = useState(true);
  const [isLoanFormDataLoading, setLoanFormDataLoading] = useState(true);

  useEffect(() => {
    getData(USER_LOANS_API, "data.items").then((data) => {
      setLoanOptions(data);
      setloanOptionsLoading(false);
    });

    getData(LOAN_REQUEST_API, "data").then((data) => {
      setLoanFormData(data);
      setLoanFormDataLoading(false);
    });
  }, []);

  return (
    <LoansContext.Provider
      value={{
        loanOptions,
        isloanOptionsLoading,
        loanFormData,
        isLoanFormDataLoading,
        setLoanOptions,
        setloanOptionsLoading,
      }}
    >
      {children}
    </LoansContext.Provider>
  );
};

export default LoansProvider;
