/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { formatAmount } from '../../helpers/common';
import get from 'lodash/get';

const InvestConfirm = ({
  data,
  isOpen,
  handleInvestConfirm,
  toggleModal,
  apiResponse,
  setApiData,
  confirmLoader,
  setConfirmLoader,
}) => {
  const handleClose = () => {
    toggleModal('none');
    setApiData(null);
    setConfirmLoader(false);
  };

  return (
    <div
      className={`modal fade make-aninvest-tab-content ${isOpen ? 'show' : ''}`}
      id='invest-confirm'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      aria-hidden='true'
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <a
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={handleClose}
          >
            <span aria-hidden='true'>×</span>
          </a>
          <div className='modal-body'>
            <div className='invest-confirmation'>
              <div className='redeem-wrapper align-middle'>
                <div className='left-side-image float-left'>
                  {data && data.lockinYears > 0 && (
                    <div className='confirmation-tooltip'>
                      Don’t forget! The lock-in period is for {data.lockinYears}{' '}
                      years
                    </div>
                  )}
                  {/* <!-- confirmation-tooltip --> */}
                  <img
                    src={`/images/${get(data, 'confImg2')}`}
                    alt='make-inv'
                  />
                </div>
                <div className='right-side-redeem float-right'>
                  <div className='redeem-form'>
                    <h6 className='subtitle-redeem border-bottom'>
                      CONFIRM INVESTMENT
                    </h6>
                    <p>
                      You are investing{' '}
                      {data && formatAmount(data.invAmount, ' ')} in{' '}
                      {data && data.displayName}
                    </p>
                    {get(apiResponse, 'status') === 'failed' && (
                        <em className='error-text'>
                      {apiResponse && apiResponse.message}
                    </em>
                    )}
                    <div className='button-footer'>
                      <button
                        type='button'
                        className={`btn btn-primary ${
                          apiResponse && apiResponse.status === 'failed'
                            ? 'op-0'
                            : ''
                        }`}
                        data-dismiss='modal'
                        data-toggle='modal'
                        data-target='#invest-thankyou'
                        onClick={handleInvestConfirm}
                      >
                        CONFIRM
                      </button>
                      <button
                        type='button'
                        className='btn btn-secondary'
                        data-dismiss='modal'
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align='middle'>
            <img              
              className={confirmLoader ? '' : 'hide'}
              src={`/images/loaderbg.gif`}
              alt='Loading...'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestConfirm;
