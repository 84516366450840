/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { Accordion, Card } from 'react-bootstrap';
import Header from '../components/Header';
import { fetchSessionData } from '../helpers/api';
import { STAGE_TRANSITION_API } from '../constants';
import CubeLoader from '../components/CubeLoader';
import fetchData from '../helpers/fetchData';
import { formatAmount } from '../helpers/common';

const StageTransition = () => {
  const [view, setView] = useState('stage-1');
  const [data, setData] = useState(null);
  const [activeId, setActiveId] = useState();
  // const [loading, setLoading] = useState(true);

  // If user is not logged in, send the user to '/profile'
  const renderRedirect = () => {
    if (!fetchSessionData().isLoggedIn) {
      return <Redirect to='/start' />
    }
  }

  useEffect(() => {
    fetchData(STAGE_TRANSITION_API, setData);
  }, []);

  const handleClickHere = (view) => {
    setView(view);
  };

  const toggleActive = (id) => {
    if (activeId === id) setActiveId(null);
    else setActiveId(id);
    }

  const handleRefresh = () => {
    window.location.replace("/stage-transition")
  }

  return data ? (
    <div className='stage-movement-page'>
      <Header />
      {renderRedirect()}
      <section className='section-wrapper'>
        <div className='container'>
          <div className='row mobile-row'>
            
            <div id='stage-1'
                    style={{
              display: view === 'stage-1' ? 'block' : 'none',
                    }}
              className='col-lg-8 main smm-main smm1-bkg-img smm1'
                  >
              <div className='main-wrapper trans d-flex justify-content-center align-items-center'>
                <div className='smm-img-left'>
                  <img className='smm-bubble' src='images/bubble-box.png' />
                  <div className='smm-content-1'>
                    <h1>{data.title}</h1>
                    <p>You are moving to the next stage as you turn {data && data.age}.</p>
                    <p>Let's see what new challenges lie in store for you!</p>
                    <p>But before that a quick review of the last stage!</p>
                    <a className='smm-btn' style={{ cursor: 'pointer' }} onClick={() => handleClickHere('stage-2')}>Ok, Tell Me<img src='images/arrow-next2.png' className='arrow-next2' /></a>
                    </div>
                </div>
                <div className='stand-man smm-right'><img src='images/smm-man.png' /><img className='pro-icon-img' src={`images/diary/${data && data.profImg}`} /></div>
              </div>
            </div>
            {/* page1 - main */}


            <div id='stage-2'
                  style={{
              display: view === 'stage-2' ? 'block' : 'none',
                  }}
              className="col-lg-8 main smm-main align-center smm-calc-diary smm2"
                >
              <div className="main-wrapper trans d-flex justify-content-center align-items-center">
                <div className="smm-img-left">
                  <img className="smm-bubble" src="images/smm-note2.png" />
                    <div className="smm-content-2 al-left">
                      <p>{data && data.tips === "" ? "No tips for you!" : data.tips}</p>
                  </div>
                </div>
                <div className="right-calc-img2 smm-right for-deskotp"><img className="calc-img" src="images/calc-pencil.png" />
                  <a className="smm-btn for-deskotp" style={{ cursor: 'pointer' }} onClick={() => handleClickHere('stage-3')}>GOT IT, UNDERSTOOD!<img src="images/arrow-next2.png" className="arrow-next2" /></a>
                </div>
              </div>
              <a className="smm-btn goitund for-mobile" style={{ cursor: 'pointer' }} onClick={() => handleClickHere('stage-3')}>GOT IT, UNDERSTOOD!<img src="images/arrow-next2.png" className="arrow-next2" /></a>
            </div>
            {/* page2 - main */}

            <div id='stage-3'
                  style={{
              display: view === 'stage-3' ? 'block' : 'none',
                  }}
              className="col-lg-8 main smm-main align-center smm3 smm-calc-diary"
                >
            <div className="main-wrapper trans d-flex justify-content-center align-items-center">
                <div className="smm-img-left smm-diary-block">
                  <img className="smm-bubble" src="images/smm-diary-bkg.png" />
                  <div className="smm-content smm-sc sc-3">
                    <h1 className="headingname">HERE'S WHAT CHANGES IN <br />THE NEXT STAGE</h1>
                    <div className="contain-wrapper smm-list-stage">
                      <div className="panel-group">
                        <div className="panel panel-default thead">
                          <div className="panel-heading">
                          <div className="left-col">LAST STAGE</div>
                          <div className="mid-col"></div>
                          <div className="right-col">NEXT STAGE</div>
                          </div>
                        </div>
                        
                        <div className="panel panel-default">
                          <div className="panel-heading">
                          <div className="left-col">{data.stageDetails.lastStage.age} years</div>
                          <div className="mid-col right-arrow">Age</div>
                          <div className="right-col">{data.stageDetails.nextStage.age} years</div>
                          </div>
                        </div>
                        
                        <div className="panel panel-default">
                          <div className="panel-heading">
                          <div className="left-col">Rs. {formatAmount(data.stageDetails.lastStage.salary)}</div>
                          <div className="mid-col right-arrow">Income</div>
                          <div className="right-col">Rs. {formatAmount(data.stageDetails.nextStage.salary)}</div>
                          </div>
                        </div>

                        <div className="panel panel-default">
                          <div className="panel-heading">
                          <div className="left-col">Rs. {formatAmount(data.stageDetails.lastStage.rentExp+data.stageDetails.lastStage.otherExp)}</div>
                          <div className="mid-col right-arrow">Expenses</div>
                          <div className="right-col">Rs. {formatAmount(data.stageDetails.nextStage.rentExp+data.stageDetails.nextStage.otherExp)}</div>
                          </div>
                        </div>

                        <div className="panel panel-default">
                          <div className="panel-heading">
                          <div className="left-col">Rs. {formatAmount(data.stageDetails.lastStage.tax)}</div>
                          <div className="mid-col right-arrow">Taxes</div>
                          <div className="right-col">Rs. {formatAmount(data.stageDetails.nextStage.tax)}</div>
                          </div>
                        </div>

                      </div>
                    </div> {/* smm-list-stage */}
                    
                    <div className="sticky-note-block">
                      <img className="w100" src="images/sticky-note.png" />
                      <div className="stickynote-content">
                        {data && data.personalTxtVal === "" ? "No personal information from the last stage!": data.personalTxtVal}
                          </div>
                        </div>
                      </div>
                  </div>
                
                <div className="right-calc-img2 smm-right for-deskotp"><img className="calc-img" src="images/calc-pencil.png" /><a className="smm-btn for-deskotp" style={{ cursor: 'pointer' }} onClick={() => handleClickHere(data.invDetails && Object.keys(data.invDetails).length>0 ? 'stage-4': 'stage-5')}>ALRIGHT, WHAT ELSE<img src="images/arrow-next2.png" className="arrow-next2" /></a></div>
              </div>
              <a className="smm-btn smm-center for-mobile" style={{ cursor: 'pointer' }} onClick={() => handleClickHere(data.invDetails && Object.keys(data.invDetails).length>0 ? 'stage-4': 'stage-5')}>ALRIGHT, WHAT ELSE<img src="images/arrow-next2.png" className="arrow-next2" /></a>
            </div>
            {/* page3 - main */}      

            <div id='stage-4'
                      style={{
              display: view === 'stage-4' ? 'block' : 'none',
                      }}
              className="col-lg-8 main smm-main align-center smm4 smm-calc-diary"
                    >
              <div className="main-wrapper trans d-flex justify-content-center align-items-center">
                <div className="smm-diary-block">
                  <img className="smm-bubble" src="images/smm-diary-bkg.png" />
                  <div className="smm-content smm-sc sc-4">
                    <h1 className="headingname color-blue">QUICK LOOK AT HOW YOUR<br /> INVESTMENTS PERFORMED!</h1>
                    <div className="contain-wrapper smm-list-stage">
                        <div className="panel-group">

                          <div className="panel panel-default thead">
                            <div className="panel-heading">
                              <div className="left-col">INVESTMENT</div>
                              <div className="mid-col">INVESTED VALUE</div>
                              <div className="right-col">CURRENT VALUE</div>
                          </div>
                        </div>

                          <div className="panel panel-default total-heading-block">
                            <div className="panel-heading">
                              <div className="left-col">TOTAL</div>
                              <div className="mid-col">{formatAmount(data.invDetails.invVal)}<span className="sm-rt-arrow"></span></div>
                              <div className="right-col">{formatAmount(data.invDetails.curAvai)}</div>
                      </div>
                          </div>
                          <Accordion>
                            <div className="accordion-wrapper smm-accordion">
                              <div className="panel-group">
                              
                              {data.invDetails && Object.keys(data.invDetails).length>0 && (["0","1","2"]).map(k => (
                                <div className="panel panel-default" key={`stageinvest-div-${k}`}>
                                  <Accordion.Toggle as={Card.Header} eventKey={k} onClick={() => toggleActive(k)}>
                                    <div className={`panel-heading accordion-toggle ${activeId === k ? '' : 'collapsed'}`} data-toggle="collapse">
                                      <div className="left-col">{data.invDetails[k].title}</div>
                                      <div className="mid-col">{formatAmount(data.invDetails[k].invVal)}<span className="sm-rt-arrow"></span></div>
                                      <div className="right-col">{formatAmount(data.invDetails[k].curAvai)}</div>
                                    </div>
                                  </Accordion.Toggle>

                      <div>
                                  <Accordion.Collapse eventKey={k}>
                                    <div className="panel-body scrollbar">
                                      <div className="table-smm">
                                        <ul className="style-none">
                                        {data.invDetails[k] && (data.invDetails[k].items).map((item, index) => (
                                          <li key={`stageinvest-${index}`}>
                                            <div className="left-col">{item.displayName}</div>
                                            <div className="mid-col">{formatAmount(item.invValue)}<span className="sm-rt-arrow"></span></div>
                                            <div className="right-col">{formatAmount(item.curAvaiInvValue)}</div>	
                                              <div
                                                className="redeemed"
                                                style={{ visibility: item.redeemedAmt <= 0  ? 'hidden' : 'visible' }}
                                              >
                                                ( Redeemed: {formatAmount(item.redeemedAmt)})
                                              </div>
                                               <div
                                                className="redeemed"
                                                style={{ visibility: item.curAvaiLockedInvValue <=0? 'hidden' : 'visible' }}
                                              >
                                                ( Locked: {formatAmount(item.curAvaiLockedInvValue)})
                                                 <span className="paylater-tooltip"></span>
                                              </div>
                                </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                                  </Accordion.Collapse>
                        </div>
                      </div>
                                ))}
                              </div> {/* accordian-wrapper */}
                            </div>
                          </Accordion>
                        </div>
                      </div>{/* smm-list-stage */}
                    </div>
                  </div>
                <div className="right-calc-img2 smm-right for-deskotp"><img className="calc-img" src="images/calc-pencil.png" /><a className="smm-btn for-deskotp" style={{ cursor: 'pointer' }} onClick={() => handleClickHere('stage-5')}>NOTED. LET'S GO AHEAD<img src="images/arrow-next2.png" className="arrow-next2" /></a></div>
              </div>
              <a className="smm-btn smm-center for-mobile" style={{ cursor: 'pointer' }} onClick={() => handleClickHere('stage-5')}>NOTED. LET'S GO AHEAD<img src="images/arrow-next2.png" className="arrow-next2" /></a>
            </div>
            {/* page4 - main */}


            <div id='stage-5'
              style={{
              display: view === 'stage-5' ? 'block' : 'none',
              }}
              className="col-lg-8 main smm-main align-center smm4 smm-calc-diary"
            >
              <div className="main-wrapper trans d-flex justify-content-center align-items-center">
                <div className="smm-diary-block">
                  <img className="smm-bubble" src="images/smm-diary-bkg.png" />
                  <div className="smm-content smm-sc sc-5">
                    <h1 className="headingname color-blue">HERE IS YOUR FINANCIAL<br /> BALANCE SHEET</h1>
                    <div className="contain-wrapper smm-list-stage">
                      <div className="panel-group">
                        
                        <div className="panel-body">
                          <div className="table-smm fbs-table-list">
                            <ul className="style-none">
                              <li className="fbs-heading start-balance">
                                <div className="left-col">STARTING BALANCE</div>
                                <div className="right-col">Rs. {formatAmount(data.incExpDetails[0].items[0].amount)}</div>	
                              </li>
                              
                              <li className="fbs-heading">
                                <div className="left-col">CREDITS<span className="face-smile"></span></div>
                              </li>
                              {data.incExpDetails && (data.incExpDetails[1].items).map((each, i) => (
                              <li key={`credits-${i}`} className={data.incExpDetails && data.incExpDetails[1].items.length-1 === i ? "nonborder" : ""}>
                                <div className="left-col">{each.txtVal}</div>
                                <div className="right-col">Rs. {formatAmount(each.amount)}</div>	
                              </li>
                              ))}
                              
                              <li className="fbs-heading mt-2">
                                <div className="left-col">DEBITS<span className="face-sad"></span></div>
                              </li>
                              {data.incExpDetails && (data.incExpDetails[2].items).map((each, i) => (
                              <li key={`debits-${i}`} className={data.incExpDetails && data.incExpDetails[2].items.length-1 === i ? "nonborder" : ""}>
                                <div className="left-col">{each.txtVal}</div>
                                <div className="right-col">Rs. {formatAmount(each.amount)}</div>	
                                </li>
                                  ))}   
                             {(data.incExpDetails[3].items.length>0)?(
                              <li className="fbs-heading mt-2">
                                <div className="left-col">{data.incExpDetails[3].title}<span className="face-sad"></span></div>
                              </li>
                            ):""}
                              {data.incExpDetails && (data.incExpDetails[3].items).map((each, i) => (

                              <li class={data.incExpDetails && data.incExpDetails[2].items.length-1 == i ? "nonborder" : ""}>
                                <div className="left-col">{each.txtVal}</div>
                                <div className="right-col">Rs. {formatAmount(each.amount)}</div>  
                                </li>
                                  ))} 

                              </ul>
                            </div>
                              
                          <div className={`new-balance-block d-flex ${data.incExpDetails && data.incExpDetails.footerColor.trim() === "" ? "": "nb-red" }`}>
                            <div className="left-col">New Balance</div>
                            <div className="right-col">Rs. {formatAmount(data.incExpDetails[0].items[1].amount)}</div>	
                          </div>
                              
                          <div className={`note-ohno ${data.incExpDetails && data.incExpDetails.alertTxt.trim() === "" ? "d-none" : "d-flex" }`}>
                            <div className="no-img"><img src="images/icon-ohno.png" className="icon-ohno" /></div>
                            <p>{data.incExpDetails && data.incExpDetails.alertTxt}</p>
                        </div>
                      </div>
                    </div>
                    </div> {/* smm-list-stage */}
                  </div>
                </div>
                
                <div className="right-calc-img2 smm-right for-deskotp">
                  <img className="calc-img" src="images/calc-pencil.png" />

                  <div className="view-agian-wrap">
                    {data && data.payLaterTxt !== "" ? 
                      <a className="smm-btn for-deskotp" style={{ cursor: 'pointer' }} onClick={() => handleClickHere('stage-6')}>OK. WHAT'S NEXT?<img src="images/arrow-next2.png" className="arrow-next2" /></a>
                    :
                      <a href="/" className="smm-btn for-deskotp">OK. LET'S PLAY!<img src="images/arrow-next2.png" className="arrow-next2" /></a>
                    }
                    {data && data.payLaterTxt === "" ? 
                    <button className="view-agian" onClick={handleRefresh}><img src="images/view-again.png" alt="view-agian"/>VIEW AGAIN</button>:""
                    }
                  </div>
                </div>
              </div>
              
              <div className="view-agian-wrap-mob">
                {data && data.payLaterTxt === "" ? 
                  <button className="view-agian" onClick={handleRefresh}><img src="images/view-again.png" alt="view-agian"/>VIEW AGAIN</button>:""
                }
                {data && data.payLaterTxt !== "" ? 
                  <a className="smm-btn smm-center for-mobile" style={{ cursor: 'pointer' }} onClick={() => handleClickHere('stage-6')}>OK. WHAT'S NEXT?<img src="images/arrow-next2.png" className="arrow-next2" /></a>
                :
                  <a href="/" className="smm-btn smm-center for-mobile">OK. LET'S PLAY!<img src="images/arrow-next2.png" className="arrow-next2" /></a>
                }
              </div>
            </div>
            {/* page5 - main */}

            <div id='stage-6'
              style={{
              display: view === 'stage-6' ? 'block' : 'none',
              }}
              className="col-lg-8 main smm-main align-center smm-calc-diary smm2"
            >
              <div className="main-wrapper trans d-flex justify-content-center align-items-center">
                <div className="smm-img-left">
                  <img className="smm-bubble" src="images/smm-note3.png" />
                    <div className="smm-content-2 al-left">
                      <p>{data && data.payLaterTxt === "" ? "You do not have any pay later amount!" : data.payLaterTxt}</p>
                    </div>
                </div>
                <div className="right-calc-img2 smm-right for-deskotp">
                  <img className="calc-img" src="images/calc-pencil.png" />
                  <div className="view-agian-wrap">
                    <a href="/" className="smm-btn for-deskotp">GOT IT, UNDERSTOOD!<img src="images/arrow-next2.png" className="arrow-next2" /></a>
                    <button className="view-agian" onClick={handleRefresh}><img src="images/view-again.png" alt="view-agian"/>VIEW AGAIN</button>
                  </div>
                </div>
              </div>
              <div className="view-agian-wrap-mob">
              <button className="view-agian" onClick={handleRefresh}><img src="images/view-again.png" alt="view-agian"/>VIEW AGAIN</button>
              <a href="/" className="smm-btn goitund for-mobile">GOT IT, UNDERSTOOD!<img src="images/arrow-next2.png" className="arrow-next2" /></a>
              </div>
            </div>
            {/* page6 - main */}

          </div>
        </div>
      </section>
    </div>
  ) : (
    <div>
      {renderRedirect()}
    <CubeLoader />
    </div>
  );
};

export default StageTransition;
