import React, {useState} from "react";
import InvestBtn from "./InvestBtn";
import { Accordion, Card } from "react-bootstrap";
import { formatAmount } from "../helpers/common";

const MakeInvestment = ({
  investmentOptions,
  invOptsloading,
  onInvestBtnClick,
}) => {
  const [activeId, setActiveId] = useState(null);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <div id="makeaninvestment" className="tab-pane">
      <Accordion>
        <div className="accordion-wrapper">
          <div
            className="panel-group"
            style={{ textAlign: "center" }}
          >
            {investmentOptions &&
              Object.keys(investmentOptions).map(
                (key) =>
                  Number.isInteger(parseInt(key)) && (
                    <div className="panel panel-default" key={`makeinvest-div-${key}`}>
                      <Accordion.Toggle as={Card.Header} eventKey={key} onClick={() => toggleActive(key)}>
                        <div
                          className={`panel-heading accordion-toggle ${activeId === key ? '' : 'collapsed'}`}
                          data-toggle="collapse"
                        >
                          <div className="p-title">
                            <span className="icon-s mf-i"></span>
                            <h4 className="panel-title">
                              {investmentOptions[key]["title"]}
                            </h4>
                          </div>
                          <div className="price inv-price text-medium">
                            {formatAmount(
                              investmentOptions[key]["totInv"],
                              " "
                            )}
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <div>
                        <Accordion.Collapse eventKey={key}>
                          <div className="panel-body">
                            <div className="table-invesment">
                              <ul className="style-none">
                                {investmentOptions[key].items &&
                                  investmentOptions[key].items.map((each, index) => (
                                    <li key={`invest-li-${index}`}>
                                      <div
                                        className="inv-title"
                                        style={{ textAlign: "left" }}
                                      >
                                        {each.displayName}
                                      </div>
                                      <div className="invest-right">
                                        <InvestBtn
                                          productId={each.productId}
                                          onClick={() =>
                                            onInvestBtnClick(
                                              each,
                                              investmentOptions[key]["title"]
                                            )
                                          }
                                        />
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    </div>
                  )
              )}
            {invOptsloading && (
              <img src={`/images/loader.svg`} alt="Loading..." />
            )}
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default MakeInvestment;
