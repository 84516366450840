/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { formatAmount } from '../../helpers/common';
import { MODES_LABEL } from '../../constants';
import get from 'lodash/get';

const RedeemConfirmModal = ({
  data,
  isOpen,
  toggleModal,
  handleConfirm,
  apiResponse,
  setApiData,
  confirmLoader,
  setConfirmLoader,
}) => {
  const handleClose = () => {
    toggleModal('none');
    setApiData(null);
    setConfirmLoader(false);
  };

  return (
    <div
      className={`modal fade current-investments-tab-content ${
        isOpen ? 'show' : ''
      }`}
      id='redeem-confirm'
      tabIndex='{-1}'
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      aria-hidden='true'
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <a
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={handleClose}
          >
            <span aria-hidden='true'>×</span>
          </a>
          <div className='modal-body'>
            {data && (
              <>
                <div className='redeem-confirmation buy-bg-block'>
                  <div className='redeem-wrapper align-middle'>
                    <div className='left-side-image float-left buy-bag'>
                      <img src='/images/bag.png' alt='bag' />
                    </div>
                    <div className='right-side-redeem float-right'>
                      <div className='redeem-form'>
                        <h6 className='subtitle-redeem'>CONFIRM PURCHASE</h6>
                        <h3 className='subheading-name'>
                          {`${data.parentCat} - ${data.displayName}`}
                        </h3>
                        <h3 className='subheading-name'>
                          Value: {formatAmount(data.price, ' ')}
                        </h3>
                        <p>
                          {MODES_LABEL[data.paymentMode]}{' '}
                          {data.paymentMode === 'emi' &&
                            `@ ${formatAmount(get(data, 'emiAmount'))} per year`}
                        </p>
                        <div className='button-footer'>
                          <button
                            type='button'
                            className={`btn btn-primary ${
                              apiResponse && apiResponse.status === 'failed'
                                ? 'op-0'
                                : ''
                            }`}
                            data-dismiss='modal'
                            data-toggle='modal'
                            data-target='#redeem-thankyou'
                            onClick={handleConfirm}
                          >
                            CONFIRM
                          </button>
                          <button
                            type='button'
                            className='btn btn-secondary'
                            data-dismiss='modal'
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                        {get(apiResponse, 'status') === 'failed' && (
                          <em className='error-text' style={{ width: '290px' }}>
                            {apiResponse.message}
                          </em>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!data && <h5 className='title-redeem'>Something Went Wrong!!</h5>}
          </div>
          <div align='middle'>
            <img             
              className={confirmLoader ? '' : 'hide'}
              src={`/images/loaderbg.gif`}
              alt='Loading...'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemConfirmModal;
