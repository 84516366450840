/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MODES_LABEL } from '../../../constants';
import { formatAmount } from '../../../helpers/common';

const ConfirmModal = ({
  isOpen,
  title,
  setClose,
  data,
  handleSubmit,
  image,
  confirmLoader,
  setConfirmLoader,
}) => {
  return (
    <div
      className={`modal fade personal-tab-confirm ${isOpen ? 'show' : ''}`}
      id='family-confirm'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      aria-hidden='true'
      style={{ display: isOpen ? 'block' : 'none', textAlign: 'left' }}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content light-purple'>
          <a
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={() => {
              setClose();
              setConfirmLoader(false);
            }}
          >
            <span aria-hidden='true'>×</span>
          </a>
          <div className='modal-body'>
            {data && (
              <div className='invest-confirmation'>
                <div className='redeem-wrapper align-middle'>
                  <div className='left-side-image float-left'>
                    <img src={`/images/${image}`} alt={image} />
                  </div>
                  <div className='right-side-redeem float-right bt-padding'>
                    <div className='redeem-form'>
                      <h6 className='subtitle-redeem'>{title}</h6>
                      <p>{data.cat==='Vacation' ? 'Vacation to ':'You are about to '}{data.displayName}</p>
                      <hr style={{ borderColor: '#ffffff' }} />
                      {data.price > 0 && (
                        <p>Value: {formatAmount(data.price)}</p>
                      )}
                      {data.hasOwnProperty('mode') && data.mode === 'emi' && (
                        <hr style={{ borderColor: '#ffffff' }} />
                      )}
                      {data.hasOwnProperty('mode') && data.mode === 'emi' && (
                        <p>
                          {MODES_LABEL[data.mode]}
                          {data.mode === 'emi' &&
                            ` @ ${formatAmount(data.emiAmount)} per year`}
                        </p>
                      )}
                      <div className='button-footer'>
                        <button
                          type='button'
                          className='btn btn-primary'
                          data-dismiss='modal'
                          data-toggle='modal'
                          data-target='#family-success'
                          onClick={handleSubmit}
                        >
                          CONFIRM
                        </button>
                        <button
                          type='button'
                          className='btn btn-secondary'
                          data-dismiss='modal'
                          onClick={() => {
                            setClose();
                            setConfirmLoader(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div align='middle'>
            <img             
              className={confirmLoader ? '' : 'hide'}
              src={`/images/loaderbg.gif`}
              alt='Loading...'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
