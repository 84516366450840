import React from "react";
import { NavLink } from "react-router-dom";
import { isMobile } from "../helpers/common";

const BuyBlock = ({ goto, src, item }) => (
  <div className="block-half" src={src}>
    <NavLink to={`${goto}`}>
      <img
        src={`/images/shop-buy_${item.toLowerCase()}.png`}
        alt="Buy TV"
        style={{ width: isMobile() ? "100%" : "60%" }}
      />
      <span className="buy-title">Buy a {item}</span>
    </NavLink>
  </div>
);

export default BuyBlock;
