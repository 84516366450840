import React, { useState, useEffect } from "react";
import { INSURANCE_LIST_API, INSURANCE_BUY_LIST_API } from "../constants";
import { getData } from '../helpers/api';

export const InsuranceContext = React.createContext();

const InsuranceProvider = ({ children }) => {
  const [data, setInsData] = useState(null);
  const [loading, setInsLoading] = useState(true);
  const [buyData, setBuyData] = useState(null);
  const [buyLoading, setBuyLoading] = useState(true);

  useEffect(() => {
    getData(INSURANCE_LIST_API, 'data.items')
      .then((data) => {
        setInsData(data);
        setInsLoading(false);
      });

    getData(INSURANCE_BUY_LIST_API, 'data.items')
      .then((data) => {
        setBuyData(data);
        setBuyLoading(false);
      });
  }, []);

  return (
    <InsuranceContext.Provider
      value={{ data, loading, buyData, buyLoading, setInsData, setInsLoading }}
    >
      {children}
    </InsuranceContext.Provider>
  );
};

export default InsuranceProvider;
