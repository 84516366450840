/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from "react";
import { formatAmount } from "../../helpers/common";

const RedeemModal = ({ data, isOpen, toggleModal, handleRedeemClick }) => {
  const [selectedOption, setselectedOption] = useState("entire");
  const [validationError, setValidationError] = useState(false);
  const [partialAmount, setpartialAmount] = useState(null);
  const [displayGraph, setDisplayGraph] = useState(false);

  const options = ["entire", "partial"];
  const LABELS = {
    partial: "PART OF INVESTMENT",
    entire: "ENTIRE AMOUNT",
  };

  const handleSubmission = () => {
    if (!selectedOption) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
    }

    if (
      selectedOption === "partial" &&
      (!partialAmount || partialAmount > data.curInvValue || partialAmount <= 0)
    ) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
    }

    handleRedeemClick(selectedOption, partialAmount);
    resetForm();
  };

  const resetForm = () => {
    // reset to initial stage at time of close
    setselectedOption(options[0]);
    setpartialAmount("");
    setValidationError(false);
  }

  const handleGraph = () => {
    setDisplayGraph(false);
  }

  const handleClose = () => {
    toggleModal("none");
    resetForm();
  };

  return (
    <div
      className={`modal fade current-investments-tab-content ${
        isOpen ? "show" : ""
      }`}
      id="redeem-modal"
      tabIndex="{-1}"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={displayGraph === true ?  handleGraph : handleClose}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <Fragment>
                {!displayGraph && (
                  <div className="invest-now-better-part1">
                <h5 className="title-redeem">REDEEM INVESTMENT</h5>
                <div className="redeem-wrapper">
                  <div className="left-side-image float-left">
                    <img src="/images/redeem-img.png" alt="redeem" />
                  </div>
                  <div className="right-side-redeem float-right">
                    <h3 className="subheading-name">{data.displayName}</h3>
                        {data.graphImg && (
                          <button
                            type="button"
                            className="btn btn-primary view-history"
                            onClick={() => setDisplayGraph(true)}
                          >
                            View History
                          </button>
                        )}
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">AMOUNT</h6>
                      <form>
                        <div className="redeem-radio form-content">
                          {Object.keys(options).map((key) => (
                            <div
                              className={`custom-control custom-radio custom-control-inline ${
                                options[key] === "partial" ? "part-of-inv" : ""
                              }`}
                              key={key}
                            >
                              <input
                                type="radio"
                                id={options[key]}
                                name="buyuse"
                                value={options[key]}
                                className="custom-control-input"
                                checked={selectedOption === options[key]}
                                onChange={(e) => {
                                  setselectedOption(e.target.value);
                                  setpartialAmount("");
                                  setValidationError(false);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={options[key]}
                              >
                                {LABELS[options[key]]}
                                {options[key] === "entire" ? (
                                      <em>{formatAmount(data.curInvValue)}</em>
                                ) : (
                                  <span />
                                )}
                              </label>
                              {options[key] === "partial" && (
                                <div className="input-field-block">
                                  <input
                                    placeholder="Enter amount here"
                                    className="input-redeem"
                                        value={partialAmount?partialAmount:""}
                                    onChange={(e) =>
                                          setpartialAmount(isNaN(Number(e.target.value)) ? ((partialAmount === null) ? "" : partialAmount) : e.target.value)
                                    }
                                        disabled = {selectedOption==='entire'? true: false}
                                  />
                                  {validationError && (
                                    <em className="error-text">
                                      Please entire amount between Zero and your
                                      current Investment.
                                    </em>
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="button-footer">
                          <button
                            type="button"
                            className="btn btn-primary yes-redeem"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#redeem-confirm"
                            onClick={handleSubmission}
                          >
                            Yes, Redeem
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                </div>
              )}
              {/* invest-now-better-part1 */}
              {displayGraph && (
                <div className="invest-now-better-part2">
                  <h4 className="title-heading-name">
                    NAV Fluctuations & Volatility : {data.risk}
                  </h4>
                  <div className="graph img-width">
                    <img
                      src={`/images/graphs/${data.graphImg}`}
                      alt="Investment_image"
                    />
                  </div>
                  <div className="button-footer">
                    <button
                      type="button"
                      className="btn btn-primary back-button"
                      onClick={handleGraph}
                    >
                      Back
                    </button>
                  </div>
                </div>
              )}
              {/* invest-now-better-part2 */}
              </Fragment>
            )}
            {!data && <h5 className="title-redeem">Something Went Wrong!!</h5>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;
