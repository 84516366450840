import React from 'react';
import LoansTab from '../components/LoansTab';
import LoansProvider from '../providers/LoansProvider';
import CustomNextBtn from '../components/CustomNextBtn';

const Loans = () => (
  <>
    <LoansProvider>
      <div className='col-lg-8 float-left main d-flex'>
        <div className='main-wrapper tab-block custom-style-height loans-has-tabs'>
          <LoansTab />
        </div>
        <CustomNextBtn />
      </div>
    </LoansProvider>
  </>
);

export default Loans;
