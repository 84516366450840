import React from "react";
import get from "lodash/get";

import { formatAmount } from "../../helpers/common";

const RepayConfirm = ({
  data,
  isOpen,
  toggleModal,
  handleConfirm,
  apiResponse,
  setApiData,
  confirmLoader,
  setConfirmLoader,
}) => {
  const getRepayAmt = () => {
    return formatAmount(
      data.entireAmount === true ? data.balance : data.emiAmount,
      " "
    );
  };

  const handleClose = () => {
    toggleModal();
    setApiData(null);
    setConfirmLoader(false);
  };
  return (
    <div
      className={`modal fade repay-your-loans-modal-content ${
        isOpen ? "show" : ""
      }`}
      id="redeem-confirm"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <div className="confirmation">
                <div className="redeem-wrapper align-middle">
                  <div className="left-side-image float-left">
                    <img src={`/images/${data.confImg2}`} alt=""/>
                  </div>
                  <div className="right-side-redeem float-right">
                    <div className="redeem-form">
                      <h6 className="subtitle-redeem">CONFIRM Repayment</h6>
                      <h3 className="subheading-name">
                        {data && data.displayName} Loans
                      </h3>
                      <p>Value: {data && getRepayAmt()}</p>
                      <div className="button-footer">
                        <button
                          type="button"
                          className={`btn btn-primary ${apiResponse && apiResponse.status === 'failed' ? "op-0" : ""}`}
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#redeem-thankyou"
                          onClick={handleConfirm}
                        >
                          CONFIRM
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                      {get(apiResponse, "status") === "failed" && (
                        <em className="error-text" style={{ width: "290px" }}>
                          {apiResponse.message}
                        </em>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!data && <h5 className="title-redeem">Something Went Wrong!!</h5>}
          </div>
          <div align='middle'>
            <img              
              className={confirmLoader ? '' : 'hide'}
              src={`/images/loaderbg.gif`}
              alt='Loading...'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepayConfirm;
