/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { InsuranceContext } from '../providers/InsuranceProvider';
import { formatAmount } from '../helpers/common';
import get from 'lodash/get';

const InsuranceList = () => {
  const { data, loading } = useContext(InsuranceContext);
  const [activeId, setActiveId] = useState(null);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const lifeInsurance = get(data, '["Life Insurance"]["Life Insurance"]');
  const endowment = get(data, '["Life Insurance"]["Endowment"]');
  const generalInsurance = get(
    data,
    '["General Insurance"]["General Insurance"]'
  );

  return (
    <Accordion>
      <div className='accordion-wrapper'>
        <div className='panel-group'>
          <div className='panel panel-default'>
            <Accordion.Toggle as={Card.Header} eventKey='life' onClick={() => toggleActive('life')}>
              <div
                className={`panel-heading accordion-toggle ${activeId === 'life' ? '' : 'collapsed'}`}
                data-toggle='collapse'
              >
                <div className='p-title wide-full'>
                  <span className='icon-s life-ins-i' />
                  <h4 className='panel-title'>Life Insurance</h4>
                </div>
              </div>
            </Accordion.Toggle>
            <div>
              <Accordion.Collapse eventKey='life'>
                <div className='panel-body'>
                  <div className='table-block'>
                    {data && (
                      <ul className='style-none'>
                        {endowment && (
                          <li>
                            <div className='wide-full head-label'>
                              ENDOWMENT
                            </div>
                          </li>
                        )}
                        {endowment &&
                          Object.keys(endowment).map((key, index) => (
                            <li
                              className='left-padding-space border-no'
                              key={index}
                            >
                              <div className='left-col li-label left-padding-space'>
                                {endowment[key]['product']}
                              </div>
                              <div className='mid-col li-price'>
                                {endowment[key]['maturityAmt']
                                  ? formatAmount(
                                      endowment[key]['maturityAmt'],
                                      ' '
                                    )
                                  : '---'}
                              </div>
                              <div className='right-col li-r-text small-text'>
                                {endowment[key]['payTill']
                                  ? `Paying till : ${endowment[key]['payTill']} yrs, Matures : ${endowment[key]['maturesAt']} yrs`
                                  : `---`}
                              </div>
                            </li>
                          ))}
                        {lifeInsurance &&
                          Object.keys(lifeInsurance).map((key, index) => (
                            <li key={index}>
                              <div className='left-col li-label'>
                                {lifeInsurance[key]['product']}
                              </div>
                              <div className='mid-col li-price'>
                                {lifeInsurance[key]['maturityAmt']
                                  ? formatAmount(
                                      lifeInsurance[key]['maturityAmt'],
                                      ' '
                                    )
                                  : '---'}
                              </div>
                              <div className='right-col li-r-text small-text'>
                                {lifeInsurance[key]['payTill']
                                  ? `Paying till : ${lifeInsurance[key]['payTill']} yrs, Matures : ${lifeInsurance[key]['maturesAt']} yrs`
                                  : `---`}
                              </div>
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div className='panel panel-default'>
            <Accordion.Toggle as={Card.Header} eventKey='general' onClick={() => toggleActive('general')}>
              <div
                className={`panel-heading accordion-toggle ${activeId === 'general' ? '' : 'collapsed'}`}
                data-toggle='collapse'
              >
                <div className='p-title wide-full'>
                  <span className='icon-s gen-ins-i' />
                  <h4 className='panel-title'>GENERAL INSURANCE</h4>
                </div>
              </div>
            </Accordion.Toggle>
            <div>
              <Accordion.Collapse eventKey='general'>
                <div className='panel-body'>
                  <div className='table-block'>
                    <ul className='style-none'>
                      <li className='wide-full head-label'>
                        <div className='wide-full head-label'>
                          Health insurance
                        </div>
                        <span
                          className={
                            data &&
                            generalInsurance.some(
                              each =>
                                each['productId'] === 'ins_health' &&
                                each['emiVal']
                            )
                              ? `icon-s right-i`
                              : `icon-s remove-i`
                          }
                          title={
                            data &&
                            generalInsurance.some(
                              (each) =>
                                each["productId"] === "ins_health" &&
                                each["emiVal"]
                            )
                              ? ``
                              : `You do not have a Health Insurance`
                          }
                        />
                      </li>
                      <li className='wide-full head-label'>
                        <div className='wide-full head-label'>
                          Home [Fire / Theft]
                        </div>
                        <span
                          className={
                            data &&
                            generalInsurance.some(
                              each =>
                                each['productId'] === 'ins_home' &&
                                each['emiVal']
                            )
                              ? `icon-s right-i`
                              : `icon-s remove-i`
                          }
                          title={
                            data &&
                            generalInsurance.some(
                              (each) =>
                                each["productId"] === "ins_home" &&
                                each["emiVal"]
                            )
                              ? ``
                              : `You do not have a Home Insurance`
                          }
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          {loading && (
            <img
              style={{ display: 'block', margin: 'auto' }}
              src={`/images/loader.svg`}
              alt='Loading...'
            />
          )}
        </div>
      </div>
    </Accordion>
  );
};

export default InsuranceList;
