import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import StageTransition from "../pages/StageTransition";
import GameLost from "../pages/GameLost";
import GameWon from "../pages/GameWon";
import Onboard from "../pages/Onboard";
import Welcome from "../pages/Welcome";
import Start from "../pages/Start";
import GameTour from "../pages/GameTour";
import ErrorPage from "../pages/ErrorPage";
import Social from "../pages/Social";

export default () => (
  <Switch>
    <Route path="/welcome" exact component={Welcome} />
    <Route path="/start" component={Start} />
    <Route path="/profile" component={Profile} />
    <Route path="/onboard" component={Onboard} />
    <Route path="/stage-transition" component={StageTransition} />
    <Route path="/game-lost" component={GameLost} />
    <Route path="/game-won" component={GameWon} />
    <Route path="/game-tour" component={GameTour} />
    <Route path="/social/:type" component={Social} />
    <Route path="/fb" component={Social} />
    <Route path="/google" component={Social} />
    <Route path="/facebook" component={Social} />
    <Route path="/goog" component={Social} />
    <Route path="/oops" component={ErrorPage} />
    <Route path="/" component={Home} />
  </Switch>
);
