/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//import { Link, Redirect } from "react-router-dom";
import { finishGame } from '../../../helpers/api';
import { useHistory } from 'react-router-dom';

const EventOops = ({ isOpen, toggleModal, stageData,data }) => {
  const history = useHistory();
  
  let imgoops='';
  if(stageData) imgoops = stageData.oopsImg;

  const clickOk = () => {
    if (data.page === "LOANS") history.push("/loans")
    if (data.page === "LIFEEVENTS") history.push("/")
    toggleModal("none");
  }

  return(
    <div
    className={`modal fade oops-popup ${isOpen ? "show" : ""}`}
    id="redeem-thankyou"
    tabIndex="{-1}"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    style={{ display: isOpen ? "block" : "none" }}
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <a
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => toggleModal("none")}
        >
          <span aria-hidden="true">×</span>
        </a>
        <div className="modal-body">
          <div className="redeem-wrapper buy-ins-block ">
            <div className="left-side-image float-left">
              <img src={`/images/oops/${imgoops}`} />
              
            </div>
            <div className="right-side-redeem float-right">
              <div className="redeem-form">
                <h3 className="subtitle-name">OOPS!</h3>
                <p>{data && data.message}</p>
                {/* {stageData && (stageData.currentStage === stageData.totStage) ? ( */}
                {stageData && (
                  <>
                    <button
                      style={{ background: data && data.status === "failed" ? "red" : "black", display: "block" }}
                      type="button"
                      className="btn btn-primary"
                      onClick={clickOk}
                    >
                      {data && data.buttonTxt && (data.buttonTxt).trim() !== ""? data.buttonTxt : 'Ok'}
                    </button>
                    <div style={{ display: "block", marginTop: "20px" }}>
                     
<u style={{ color: "white", cursor: "pointer" }} onClick={() => finishGame('/game-lost')}>Forfeit Game?</u>

                    </div>
                  </>
                ) 
                // : (
                //   <>
                //     <button
                //       style={{ background: "black", display: "block" }}
                //       type="button"
                //       className="btn btn-primary"
                //       onClick={clickOk}
                //     >
                //       Ok
                //     </button>
                //     <div style={{ display: "block", marginTop: "20px" }}>
                //       <Link to="/game-lost" style={{ color: "white" }}>
                //         <u>Forfeit Game?</u>
                //       </Link>
                //   </div>
                //   </>
                // )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default EventOops;
