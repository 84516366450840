import React, { useContext, useState, useEffect } from "react";
import DesktopPersonContainer from "./DesktopPersonContainer";
import { HomeContext } from "../providers/HomeProvider";
import { InvestContext } from '../providers/InvestProvider';
import { formatAmount, isMobile } from "../helpers/common";
import MascotTipModal from "./modals/MascotTipModal";
import { MASCOT_TIP_API } from "../constants";
import fetchData from "../helpers/fetchData";
import { NavLink } from "react-router-dom";
import { postData, getData } from "../helpers/api";
import FinanceModal from "./modals/FinanceModal";
import DetailedFinanceModal from "./modals/DetailedFinanceModal";
import ProfileModal from "./modals/ProfileModal";

const Profile = () => {
  const { data: data, tipData: tipData, profileData: profileData } = useContext(HomeContext);
  const { financialDetails, refreshFinance } = useContext(InvestContext);
  const [activeModal, setActiveModal] = useState("none");
  const [modalData, setModalData] = useState(null);
  const [mascotTip, setMascotTip] = useState(null);
  const [currentSideImg, setCurrentSideImg] = useState(0);

  // useEffect(() => {
  //   setMascotTip(tipData);
  // }, [tipData])

  const onFinanceClick = () => {
    setActiveModal("finance");
    refreshFinance();
  };

//const [loading, setLoading] = useState(null);
 const onMascotClk = async() => {
 // const onMascotClk = () => {
    getData(MASCOT_TIP_API, "data.data").then((op) => {
               //setMascotTip(op);
               setModalData(op[0]);
               setActiveModal("mascot");
              //  console.log(op);
             // setInsLoading(false);
            });

 
 //   setMascotTip(mascotTip[0]);
  };
    
  const onProfileClk = () => {
    setActiveModal("profile");
  };

  const getSideImg = () => {   
    const imgs = data && data.itemImg.split("|");
    
    setTimeout(() => {
      setCurrentSideImg(
        currentSideImg < imgs.length - 1 ? currentSideImg + 1 : 0
      );
    
    }, 1500);
   
    return imgs[currentSideImg];
  };

  // const handleMascotCloseClk = () => {
  //   if (mascotTip.length == 1) {
  //     setMascotTip([
  //       {
  //         title: "No Tip Available",
  //         tips: "You do not have any Tips",
  //       },
  //     ]);
  //   } else {
  //     mascotTip.shift();
  //   }
  // };

  let pic ='';
  if(data && data.itemImg){
    pic = `/images/${getSideImg()}`;
    pic =<img src={pic}/>
    }

  return (
    <>
      {isMobile() && data && (
        <div className="for-mobile left-side ">
          <div className="ic-right-wrap" onClick={onFinanceClick}>
            <ul className="style-none">
              <li className="ic-list cash-block">
                <div className="ic-left-icon cash-i" />
                <div className="ic-right-text">
                  <div className="ic-text">
                    Cash <br />
                    in Bank
                  </div>
                  <div className="ic-price">
                    {formatAmount(data.bankBalance)}
                  </div>
                </div>
              </li>
              <li className="ic-list card-block">
                <div className="ic-left-icon card-i" />
                <div className="ic-right-text">
                  <div className="ic-text">
                    Credit <br />
                    Card
                  </div>
                  <div className="ic-price">{formatAmount(data.ccBalance)}</div>
                </div>
              </li>
              <li className="ic-list invest-block">
                <div className="ic-left-icon investment-i" />
                <div className="ic-right-text">
                  <div className="ic-text">
                    Paylater <br />
                    Loans
                  </div>
                  <div className="ic-price">
                   {formatAmount(data.payLaterBalance)}
                  </div>
                </div>
                <span className="ic-right-icon"></span>
              </li>
            </ul>
          </div>

          <div className="profile-car" style={{ cursor: "pointer" }} onClick={onProfileClk}>
            <span className="profile-img">
              <img src={`/images/top/${data.profImg}`} alt="profile" />
            </span>
            <span className="number-count">
              <b>
                {data.currentStage < 10
                  ? "0" + data.currentStage
                  : data.currentStage}
              </b>{" "}
              / {data.totStage}
            </span>
          </div>
        </div>
      )}
      {!isMobile() && data && (
        <div className="col-lg-3 float-right right-side for-desktop">
          <div className="profile-car" style={{ cursor: "pointer" }} onClick={onProfileClk}>
            <span className="number-count">
              <b>
                {data.currentStage < 10
                  ? "0" + data.currentStage
                  : data.currentStage}
              </b>{" "}
              / {data.totStage}
            </span>
            <span className="profile-img">
              <img src={`/images/top/${data.profImg}`} alt="profile" />
            </span>
            <span className="car-icon">
            {pic}
            </span>
          </div>

          <div className="in-exp">
            <div className="incomme">
              <span>INCOME</span>
              <span className="text-bigger">
                {formatAmount(data.income, " ")}
              </span>
            </div>
            <div className="exp">
              <span>EXPENSES</span>
              <span className="text-bigger">
                {formatAmount(data.expenses, " ")}
              </span>
            </div>
          </div>
          <div className="ic-right-wrap" onClick={onFinanceClick}>
            <ul className="style-none">
              <li className="ic-list cash-block">
                <div className="ic-left-icon cash-i" />
                <div className="ic-right-text">
                  <div className="ic-text">Cash in Bank</div>
                  <div className="ic-price">
                    {formatAmount(data.bankBalance)}
                  </div>
                </div>
              </li>
              <li className="ic-list card-block">
                <div className="ic-left-icon card-i" />
                <div className="ic-right-text">
                  <div className="ic-text">Credit Card</div>
                  <div className="ic-price">{formatAmount(data.ccBalance)}</div>
                </div>
              </li>
              <li className="ic-list invest-block">
                <div className="icon-s deposits-i"/>
                <div className="ic-right-text">                
                  <div className="ic-text">PAYLATER LOANS</div>
                  <div className="ic-price"> 
                    {formatAmount(data.payLaterBalance)}
                  </div>                  
                </div>
                <span className="ic-right-icon"></span>
              </li>
            </ul>
          </div>
          <MascotTipModal
            isOpen={activeModal === "mascot"}
            closeMascotModal={setActiveModal}
            data={modalData}
            // handleMascotClose={handleMascotCloseClk}
          />
          <DesktopPersonContainer clickHandlr={() => onMascotClk()} />
        </div>
      )}
      <ProfileModal
        isOpen={activeModal === "profile"}
        toggleModal={setActiveModal}
        data={profileData}
      />
      <FinanceModal
        isOpen={activeModal === "finance"}
        toggleModal={setActiveModal}
        data={financialDetails}
      />
      <DetailedFinanceModal
        isOpen={activeModal === "finance-details"}
        toggleModal={setActiveModal}
        data={financialDetails}
      />
    </>
  );
};

export default Profile;
