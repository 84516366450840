/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from "react";
import { MODES_LABEL } from "../../../constants";
import {
  formatAmount,
  getEmiValues,
  // getCcbankValues,
} from "../../../helpers/common";
import get from "lodash/get";

const CareerFormModal = ({ isOpen, setClose, data, handleConfirm }) => {
  const {
    title,
    dispTxt: content,
    confImg: image,
    buttonTxt,
    items: [itemToDisplay],
  } = data;
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedEmi, setSelectedEmi] = useState(3);
  const [validationError, setValidationError] = useState(false);
  const [confirmBtn, cancelBtn] = buttonTxt.split("|");

  const isModes = get(data, "modes");
  const modes = isModes && isModes.split("|");

  const closeAll = () =>{
    setClose();
    setSelectedOption(null);
    setSelectedEmi(3);
    setValidationError(false);
  }

  const handleSubmission = () => {
    if (!selectedOption) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
      handleConfirm({
        ...itemToDisplay,
        mode: selectedOption,
        emiAmount:
          selectedOption === "emi"
            ? getEmiValues(data.emiValue)[selectedEmi]
            : 0,
        emiYears:selectedEmi
      });
    }
    closeAll();
    // handleBuyClick(selectedOption, selectedEmi, selectedCcBank); //need_to_check
  };

  return (
    <div
      className={`modal fade career-form-tab-content ${isOpen ? "show" : ""}`}
      id="child-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none", textAlign: "left" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content light-purple">
          <a
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAll}
          >
            <span aria-hidden="true">×</span>
          </a>
          <div className="modal-body">
            {data && (
              <Fragment>
                <h5 className="head-title-name">{title}</h5>
                <div className="redeem-wrapper">
                  <div className="left-side-image float-left">
                    <img src={`/images/${image}`} alt={image} />
                  </div>
                  <div className="right-side-redeem m-right-side-content float-right">
                    <div className="redeem-form">
                      <form>
                        <p>{content}</p>
                        <p>Amount: Rs. {formatAmount(itemToDisplay.price)}</p>
                        <hr style={{ borderColor: "#ffffff" }} />
                        <div className="redeem-form">
                          <h6 className="subtitle-redeem">PAY USING</h6>
                          <div className="redeem-radio form-content buy-using">
                            {modes.map((mode) => (
                              <div
                                className="custom-control custom-radio custom-control-inline part-of-inv"
                                key={mode}
                              >
                                <input
                                  type="radio"
                                  id={mode}
                                  name="buyuse"
                                  value={mode}
                                  className="custom-control-input"
                                  checked={selectedOption === mode}
                                  onChange={(e) => {
                                    setSelectedOption(e.target.value);
                                    setValidationError(false);
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={mode}
                                >
                                  <span>{MODES_LABEL[mode]}</span>
                                </label>
                                {selectedOption === "paylater" &&
                                  mode === "paylater" && (
                                    <div className="paylater">
                                      <p className="note-text">
                                        Please note:
                                        <br />
                                        You will have to clear your Pay Later
                                        balance at the end of the stage.
                                      </p>
                                    </div>
                                  )}
                                {selectedOption === "emi" && mode === "emi" && (
                                  <div className="emivalue">
                                    <div className="form-group select-group">
                                      <i className="arrow-select down" />
                                      <select
                                        className="form-control"
                                        id="exampleSelect2"
                                        value={selectedEmi}
                                        onChange={(e) =>
                                          setSelectedEmi(e.target.value)
                                        }
                                      >
                                        {data.emiYears
                                          .split("|")
                                          .map((yearValue) => (
                                            <option value={yearValue}>
                                              {yearValue} years
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <p className="text-monthly-emi">
                                      Yearly EYI:{" "}
                                      {formatAmount(getEmiValues(data.emiValue)[selectedEmi])}
                                    </p>
                                  </div>
                                )}
                              </div>
                            ))}
                            {validationError && (
                              <em className="error-text">
                                Please select a Payment Mode.
                              </em>
                            )}
                          </div>
                          <div className="button-footer">
                            <button
                              type="button"
                              className="btn btn-primary mobile-wide"
                              data-dismiss="modal"
                              data-toggle="modal"
                              data-target="#career-confirm"
                              onClick={handleSubmission}
                            >
                              {confirmBtn}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={closeAll}
                            >
                              {cancelBtn}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
            {!data && <h5 className="title-redeem">Something Went Wrong!!</h5>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerFormModal;
