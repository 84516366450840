/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import get from "lodash/get";

const colorMapping = {
  "PERSONAL-POPUP": "#bc8cbf",
  "PURCHASE-POPUP": "#f1666b",
};

const EventSuccess = ({ data, isOpen, toggleModal }) => (
  <div
    className={`modal fade current-investments-tab-content wooho ${
      isOpen ? "show" : ""
    }`}
    id="redeem-thankyou"
    tabIndex="{-1}"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    style={{ display: isOpen ? "block" : "none" }}
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div
        className="modal-content"
        style={{ background: colorMapping[get(data, "popupType")] }}
      >
        <a
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => toggleModal("none")}
        >
          <span aria-hidden="true">×</span>
        </a>
        <div className="modal-body">
          {data && (
            <div className="redeem-success">
              <div className="redeem-wrapper align-middle buy-bg-block">
                <div className="left-side-image float-left buy-bag">
                  <img src={`/images/${data.sucImg}`} alt="bag approved" />
                </div>
                <div className="right-side-redeem float-right">
                  <div className="redeem-form">
                    <h6 className="subtitle-redeem">{data.sucTitle}</h6>
                    <h3 className="subheading-name border-none">
                      {data.sucTxt}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!data && <h5 className="title-redeem">Something Went Wrong!!</h5>}
        </div>
      </div>
    </div>
  </div>
);

export default EventSuccess;
