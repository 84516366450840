import React, { useState, useContext } from "react";
import BuyList from "../components/BuyList";
import RedeemConfirmModal from "../components/modals/PurchaseConfirmModal";
import PurchaseModal from "../components/modals/PurchaseModal";
import RedeemThankYouModal from "../components/modals/RedeemThankYouModal";
import { BUY_PRODUCT_API, ASSETS_LIST_API, PURCHASED_LIST_API } from "../constants";
import get from "lodash/get";
import { getEmiValues, getCcbankValues } from "../helpers/common";
import fetchData from "../helpers/fetchData";
import { postData, getData, redirectToErrorPage } from "../helpers/api";
import { DASHBOARD_DETAILS_API } from "../constants";
import { HomeContext } from "../providers/HomeProvider";
import { PurchaseContext } from "../providers/PurchaseProvider";

const BuyOptions = ({ props }) => {
  const item = props.match.params.item.toLowerCase();
  const [activeModal, setActiveModal] = useState("none");
  const [modalData, setModalData] = useState(null);
  const [btnText, setbtnText] = useState("Buy");
  const [apiResponse, setApiData] = useState(null);
  const { setData, setLoading, setProfileData } = useContext(HomeContext);
  const { setPurchasedData } = useContext(PurchaseContext);
  const [confirmLoader, setConfirmLoader] = useState(false);

  const onBuyBtnClick = (data, btnText) => {
    setModalData(data);
    setActiveModal("purchase");
    setbtnText(btnText);
  };

  const handleModalBuyClick = (selected, selectedEmi, selectedCcBank) => {
    setActiveModal("confirm");
    setModalData({
      ...modalData,
      paymentMode: selected,
      emiYears: selectedEmi,
      emiAmount: getEmiAmount(selectedEmi),
      ccAmount: getCcBankAmount(selectedCcBank, "cc"),
      bankAmount: getCcBankAmount(selectedCcBank, "bank"),
    });
  };

  const getEmiAmount = (selectedEmi) => {
    return modalData.emiValue && getEmiValues(modalData.emiValue)[selectedEmi];
  };

  const getCcBankAmount = (selectedCcBank, type) => {
    return (
      modalData.ccBank &&
      getCcbankValues(modalData.ccBank, modalData.price, type)[selectedCcBank]
    );
  };

  const handleModalConfirmClick = () => {
    setConfirmLoader(true)
    const finalData = getApiData();
    postData(BUY_PRODUCT_API, finalData)
      .then((res) => {
        setApiData(res);
        setConfirmLoader(false);
        if (get(res, "status") === "success") {
          setActiveModal("thank_you");
          fetchData(
            DASHBOARD_DETAILS_API,
            setData,
            setLoading
          );
          getData(ASSETS_LIST_API, "data").then((data) => {
            setProfileData(data);
          });
          getData(PURCHASED_LIST_API, "data.assets").then((data) => {
            setPurchasedData(data);
          });
        }
      })
      .catch((err) => {
        setApiData(err);
        // If error type is not object or message key is not there
        // we assume that the error is not generate from the backend
        if (typeof err !== 'object' || !('message' in err)) {
          redirectToErrorPage("Something went wrong while making a purchase!", "TRY AGAIN");
        }
      });
  };

  const getApiData = () => {
    const { paymentMode, productId, price } = modalData;

    const finalData = {
      mode: paymentMode,
      productId: productId,
    };

    if (paymentMode === "ccbank") {
      finalData["bankAmount"] = modalData.bankAmount;
      finalData["ccAmount"] = modalData.ccAmount;
    } else if (paymentMode === "emi") {
      finalData["emiAmount"] = modalData.emiAmount;
      finalData["emiYears"] = modalData.emiYears;
    } else {
      finalData[`${paymentMode}Amount`] = price;
    }
    return finalData;
  };

  return (
    <>
        <BuyList item={item} onBuyClick={onBuyBtnClick} />
      <div
        className={activeModal !== "none" ? "modal-open" : ""}
        style={{ opacity: activeModal !== "none" ? 1 : 0 }}
      >
        <RedeemConfirmModal
          isOpen={activeModal === "confirm"}
          data={modalData}
          toggleModal={setActiveModal}
          handleConfirm={handleModalConfirmClick}
          apiResponse={apiResponse}
          setApiData={setApiData}
          confirmLoader={confirmLoader}
          setConfirmLoader={setConfirmLoader}
        />
        <PurchaseModal
          isOpen={activeModal === "purchase"}
          data={modalData}
          buttonText={btnText}
          toggleModal={setActiveModal}
          handleBuyClick={handleModalBuyClick}
        />
        <RedeemThankYouModal
          isOpen={activeModal === "thank_you"}
          data={modalData}
          toggleModal={setActiveModal}
          apiResponse={apiResponse}
          setApiData={setApiData}
        />
      </div>
    </>
  );
};

export default BuyOptions;
