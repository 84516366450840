import React from "react";

const RepayBtn = ({ clickHandlr }) => (
  <div onClick={clickHandlr}>
    <span data-toggle="modal" data-target="#repay-modal">
      <label className="invest-round">
        <span className="repay-icon"></span>
        <span className="text-in text-medium">Repay</span>
      </label>
    </span>
  </div>
);

export default RepayBtn;
